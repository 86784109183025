import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty, map } from 'lodash';

import { CarQuoteCoverage } from '@models/CarQuote';

type Props = Pick<CarQuoteCoverage,
| 'endorsements'
>;

const CarQuoteEndorsementCardBody = ({ endorsements }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceQuote.learnMore')}</Card.Title>

      {!isEmpty(endorsements) && (
        <ListGroup variant="flush" className="mb-3">
          {map(endorsements, (endorsement) => (
            <ListGroup.Item key={endorsement}>{endorsement}</ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Card.Body>
  );
};

export { CarQuoteEndorsementCardBody };
