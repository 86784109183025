import { createActions } from 'redux-actions';

import { GeneralOrder } from '@models/GeneralOrder';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { CreatePaymentTransactionParams } from '@apis/PaymentTransactionApi';
import { GetGeneralOrderParams } from '@apis/GeneralOrderApi';
import { GetBillingParams } from '@apis/BillingApi';
import { Billing } from '@models/Billing';

export type State = {
  generalOrder?: GeneralOrder | null;
  paymentTransaction?: PaymentTransaction | null;
  billing?: Billing | null;
}

export type GeneralOrderFetchPayload = GetGeneralOrderParams;
export type PaymentTransactionCreatePayload = CreatePaymentTransactionParams;
export type BillingFetchPayload = GetBillingParams;
export type StateUpdatePayload = Partial<State>;

export const {
  generalOrderFetch,
  paymentTransactionCreate,
  billingFetch,
  stateUpdate,
} = createActions(
  'GENERAL_ORDER_FETCH',
  'PAYMENT_TRANSACTION_CREATE',
  'BILLING_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'GENERAL_ORDER_PAYMENT_PAGE',
  },
);
