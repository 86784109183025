import React from 'react';
import { Card } from 'react-bootstrap';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { amountToUsd, amountToKhr } from '@utils/currency';
import { GeneralQuote } from '@models/GeneralQuote';

import { InsurerLogoWrapper } from '@components/Feature/Insurer';
import { AmountWithDiscount } from '@components/Base';

type Props = Pick<GeneralQuote, 'insurer' | 'adminFeeFinalAmount' | 'finalPremium' | 'totalPremium' | 'totalPremiumKhr'>;

const GeneralQuotePremiumCardBody = ({
  insurer,
  adminFeeFinalAmount,
  finalPremium,
  totalPremium,
  totalPremiumKhr,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <InsurerLogoWrapper insurer={insurer} />

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:policyTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premiumPaymentTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premium')}:</span>
          <AmountWithDiscount finalAmount={finalPremium} />
        </li>

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:adminFee')}:</span>
          <AmountWithDiscount finalAmount={adminFeeFinalAmount} />
        </li>
      </ul>

      <ul className="list-unstyled pb-2 mb-0">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:youWillPay')}:</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </li>
        <li className="d-flex justify-content-between">
          <span>&nbsp;</span>
          <span>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </span>
        </li>
      </ul>
    </Card.Body >
  );
};

export { GeneralQuotePremiumCardBody };
