import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { isEmpty, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useInsurersFetch } from '@components/Feature/Insurer';
import { Title } from '../title';

const InsurerPartner = () => {
  const { t } = useTranslation();
  const insurers = useInsurersFetch();

  return (
    <section className="py-5 bg-primary text-white">
      <Title title={t('partner.header')} />
      <Container>
        {!isEmpty(insurers) && (
          <Row className="gy-4 justify-content-center align-items-center">
            {map(insurers, ({ id, logo, name }) => (
              <Col key={id} xs={4} md={3} lg={2}>
                <Image src={logo} alt={`${name} official logo`} fluid />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  );
};

export { InsurerPartner };
