import React from 'react';
import { Badge } from 'react-bootstrap';
import { tUserDocumentStatus, getUserDocumentStatusVariant, UserDocumentStatuses } from '@models/UserDocument';

const DocumentBadge = ({ status }: { status: UserDocumentStatuses }) => {
  const variant = getUserDocumentStatusVariant(status);
  const text = tUserDocumentStatus(status);

  return (
    <Badge data-testid={`document-badge ${variant}`} bg={variant}>
      {text}
    </Badge>
  );
};

export { DocumentBadge };
