import { NoData } from '@components/Base';
import { OrderableTypes } from '@models/Order';
import React from 'react';
import { useParams } from 'react-router-dom';
import CarPolicyRenewal from './CarPolicyRenewal';

const PolicyRenewal = () => {
  const { orderableType } = useParams();

  switch (orderableType) {
    case OrderableTypes.Car:
      return <CarPolicyRenewal />;
    default:
      return <NoData />;
  }
};

export { PolicyRenewal };
