import { ClaimableTypes } from "@models/Claim";
import { OrderableTypes } from "@models/Order";
import { getRoute } from "@utils/route";

export const USER_DASHBOARD_ROUTE_URL = '/user-dashboard/*';

export const USER_DASHBOARD_POLICY_URL = '/user-dashboard/policy';
export const USER_DASHBOARD_POLICY_DETAILS_URL = '/user-dashboard/policy/:orderableType/:orderableId';
export const USER_DASHBOARD_POLICY_CLAIM_URL = '/user-dashboard/policy/:orderableType/:orderableId/claim';
export const USER_DASHBOARD_POLICY_RENEWAL_URL = '/user-dashboard/policy/:orderableType/:orderableId/renewal';

type PolicyRouteParams = {
  orderableType: OrderableTypes;
  orderableId: number;
};

export const routeUserDashboardDetails = () => getRoute(USER_DASHBOARD_POLICY_URL);
export const routeUserDashboardPolicyDetails = (routeParams: PolicyRouteParams) => getRoute(USER_DASHBOARD_POLICY_DETAILS_URL, { routeParams });
export const routeUserDashboardPolicyClaim = (routeParams: PolicyRouteParams) => getRoute(USER_DASHBOARD_POLICY_CLAIM_URL, { routeParams });
export const routeUserDashboardPolicyRenewal = (routeParams: PolicyRouteParams) => getRoute(USER_DASHBOARD_POLICY_RENEWAL_URL, { routeParams });

export const USER_DASHBOARD_CLAIM_DETAILS_URL = '/user-dashboard/claim/:claimableType/:claimableId';

type ClaimRouteParams = {
  claimableType: ClaimableTypes;
  claimableId: number;
};

export const routeUserDashboardClaimDetails = (routeParams: ClaimRouteParams) => getRoute(USER_DASHBOARD_CLAIM_DETAILS_URL, { routeParams });
