import { useLocation, matchPath } from 'react-router-dom';

import { InsuranceTypes, tInsuranceTypeName } from '@models/InsuranceType';
import { useParseParams, useParseSearchParams } from '@hooks/params';
import { routePersonalAccidentQuoteResultPage } from '@components/PersonalAccidentQuoteResultPage';
import { routeCancerQuoteResultPage } from '@components/CancerQuoteResultPage';

import { routeHealthQuoteResultPage, HealthQuoteResultPageParams } from './route';
import { healthQuoteResultPageParamsSchema } from './schema';

export const useHealthQuoteResultPageParams = () =>
  useParseParams<Required<HealthQuoteResultPageParams>>(healthQuoteResultPageParamsSchema);

type InsurancePage = {
  current: { name: string; to: string; homePath: string };
  others?: { name: string; to: string; homePath: string }[];
};

export const useInsurancePage = (): InsurancePage => {
  const { pathname } = useLocation();
  const search = useParseSearchParams<HealthQuoteResultPageParams>();

  const toPath = (path: { pathname: string; search: string }) => `${path.pathname}?${path.search}`;

  const healthPage = {
    name: tInsuranceTypeName(InsuranceTypes.Health),
    to: toPath(routeHealthQuoteResultPage(search)),
    homePath: '/health-insurance-quote/members',
  };
  const personalAccidentPage = {
    name: tInsuranceTypeName(InsuranceTypes.PersonalAccident),
    to: toPath(routePersonalAccidentQuoteResultPage(search)),
    homePath: '/personal-accident-insurance-quote/members',
  };
  const cancerPage = {
    name: tInsuranceTypeName(InsuranceTypes.Cancer),
    to: toPath(routeCancerQuoteResultPage(search)),
    homePath: '/cancer-insurance-quote/members',
  };

  if (matchPath('/health-insurance-quote-results/*', pathname)) {
    return { current: healthPage, others: [personalAccidentPage, cancerPage] };
  } else if (matchPath('/personal-accident-insurance-quote-results/*', pathname)) {
    return { current: personalAccidentPage, others: [healthPage, cancerPage] };
  } else if (matchPath('/cancer-insurance-quote-results/*', pathname)) {
    return { current: cancerPage, others: [healthPage, personalAccidentPage] };
  } else {
    return { current: { name: '', to: '', homePath: '' }, others: [] };
  }
};
