import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';

import { insurersFetch } from './action';
import { selectInsurer } from './selector';

export const useInsurersFetch = () => {
  const dispatch = useDispatch();
  const { insurers } = useSelector(selectInsurer);

  useEffectOnce(() => {
    if (!isEmpty(insurers)) return;
    dispatch(insurersFetch());
  });

  return insurers;
};
