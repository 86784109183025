import { object, string, boolean } from 'yup';
import { Genders } from '@models/User';

export const userFormValuesSchema = object({
  firstName: string().default(''),
  firstNameKm: string().default(''),
  lastName: string().default(''),
  lastNameKm: string().default(''),
  dob: string().default(''),
  gender: string().default(Genders.Male),
  personalCode: string().default(''),
  occupation: string().default(''),
  email: string().default(''),
  noMobileNumber: boolean().default(false),
  mobileNumber: string().default(''),
  address: string().default(''),
});
