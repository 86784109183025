import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '@images/logo.png';

import paymentOptions from './payment-options.png';
import ircLogo from './irc-official-logo.png';

export const LicenseSection = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-5">
      <Container>
        <Row>
          <Col xs={12} md={6} className="mb-5 mb-md-0">
            <div className="d-flex flex-row align-items-start">
              <Image alt="MGA Cover Official Logo" src={logo as string} width="80" />
              <div className="text-light ms-3">
                <h2>MGA Cover</h2>
                <p className="mb-0">{t('common:mgaCoverSummary')}</p>
              </div>
            </div>
          </Col>

          <Col xs={6} md={3}>
            <h5>{t('common:licensedBy')}</h5>
            <Image alt="IRC Official Logo" src={ircLogo as string} width="100" />
          </Col>

          <Col xs={6} md={3}>
            <h5>{t('common:weAccept')}</h5>
            <Image src={paymentOptions as string} fluid={true} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
