import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import parse from 'html-react-parser';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

const BenefitCardBody = ({ benefitScheduleHtml }: PersonalAccidentQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:benefit'), 2)}</Card.Title>
      {parse(benefitScheduleHtml)}
    </Card.Body>
  );
};

export { BenefitCardBody };
