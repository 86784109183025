import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { HealthQuote } from '@models/HealthQuote';
import { RowItem, RowItemProps } from '@components/Base';
import { amountToUsd } from '@utils/currency';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
  labelProps: { lg: 4 },
};

type Props = Pick<HealthQuote,
  | 'geographicCoverage'
  | 'hospitals'
  | 'annualLimitAmount'
>;

const FeatureCardBody = ({ geographicCoverage, hospitals, annualLimitAmount }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
    </Card.Body>
  );
};

export { FeatureCardBody };
