import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam, useParseParams } from '@hooks/params';
import { findCarQuoteParamsSchema } from '@apis/CarQuoteApi';

import {
  carQuoteFetch,
  CarQuoteFetchPayload,
  carQuoteFind,
  CarQuoteFindPayload,
} from './action';
import { selectCarQuoteBuy } from './selector';
import { CarQuoteBuyNewPageParams } from './route';

export const useCarQuoteBuyNewPageParams = () => useParseParams<CarQuoteBuyNewPageParams>(findCarQuoteParamsSchema);

export const useCarQuoteFind = (payload: CarQuoteFindPayload) => {
  const dispatch = useDispatch();
  const { carQuote } = useSelector(selectCarQuoteBuy);
  useEffectOnce(() => {
    dispatch(carQuoteFind(payload));
  });
  return carQuote;
};

export const useCarQuoteFetch = () => {
  const dispatch = useDispatch();
  const carOrderId = Number(useRouteParam('carOrderId'));
  const { carQuote } = useSelector(selectCarQuoteBuy);

  useEffectOnce(() => {
    if (carQuote && carQuote.carOrderId == carOrderId) return;
    const payload: CarQuoteFetchPayload = { carOrderId };
    dispatch(carQuoteFetch(payload));
  });

  return carQuote;
};
