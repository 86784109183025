import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useParseParams, useRouteParam } from '@hooks/params';

import { personalAccidentQuoteFind, personalAccidentQuoteFetch, PersonalAccidentQuoteFetchPayload, PersonalAccidentQuoteFindPayload } from './action';
import { selectPersonalAccidentQuoteBuyRoute } from './selector';
import { PersonalAccidentQuoteBuyNewPageParams } from './route';
import { personalAccidentQuoteBuyNewPageParamsSchema } from './schema';

export const usePersonalAccidentQuoteBuyNewPageParams = () =>
  useParseParams<PersonalAccidentQuoteBuyNewPageParams>(personalAccidentQuoteBuyNewPageParamsSchema);

export const usePersonalAccidentQuoteFind = (payload: PersonalAccidentQuoteFindPayload) => {
  const dispatch = useDispatch();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);

  useEffectOnce(() => {
    dispatch(personalAccidentQuoteFind(payload));
  });

  return personalAccidentQuote;
};

export const usePersonalAccidentQuoteFetch = () => {
  const personalAccidentOrderId = Number(useRouteParam('personalAccidentOrderId'));
  const dispatch = useDispatch();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);

  useEffect(() => {
    if (personalAccidentQuote && personalAccidentQuote.personalAccidentOrderId == personalAccidentOrderId) return;
    const payload: PersonalAccidentQuoteFetchPayload = { personalAccidentOrderId: personalAccidentOrderId };
    dispatch(personalAccidentQuoteFetch(payload));
  }, []);

  return personalAccidentQuote;
};
