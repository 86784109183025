import { CancerQuoteResultPageParams } from '@components/CancerQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type CancerQuoteDetailsPageParams = Required<Pick<CancerQuoteResultPageParams, 'me'>> &
  Pick<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    cancerPlanId: number;
  };
export const CANCER_QUOTE_DETAILS_ROUTE_URL = '/cancer-insurance-quote-details/*';
export const CANCER_QUOTE_DETAILS_PAGE_URL = '/cancer-insurance-quote-details';
export const CANCER_QUOTE_SEND_MAIL_PAGE = '/cancer-insurance-quote-details/send-email';

export const routeCancerQuoteDetailsPage = (searchParams: CancerQuoteDetailsPageParams) => getRoute(CANCER_QUOTE_DETAILS_PAGE_URL, { searchParams });
export const routeCancerQuoteSendEmailPage = (searchParams: CancerQuoteDetailsPageParams) => getRoute(CANCER_QUOTE_SEND_MAIL_PAGE, { searchParams });
