import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';

import { Loading, LoadingError } from '@components/Base';

import { cancerQuoteUpdate, CancerQuoteUpdatePayload } from '../action';
import { useCancerQuoteFetch } from '../hook';
import CancerQuoteBuyForm from '../CancerQuoteBuyForm';
import { FormValues } from '../CancerQuoteBuyForm/schema';
import { useInitialValues } from './hook';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';

const CancerQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const cancerQuote = useCancerQuoteFetch();
  const initialValues = useInitialValues();

  if (isUndefined(cancerQuote)) return <Loading />;
  if (isNull(cancerQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { cancerOrderId } = cancerQuote;

  const handleSubmit = (values: FormValues) => {
    // FIXME: type mismatch, need to filter out useless params
    const payload: CancerQuoteUpdatePayload = { ...values, cancerOrderId };
    dispatch(cancerQuoteUpdate(payload));
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <CancerQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>
        <RightCol {...cancerQuote} planName={cancerQuote.cancerPlanName} />
      </Row>
    </Container>
  );
};

export { CancerQuoteBuyEditPage };
