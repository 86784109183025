import React from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import UserFormControl from '@components/Feature/User/UserFormControl';

import { FormDisabled, FormRequired, FormValues } from './type';
import { VehicleCardBody } from './vehicle-card-body';
import { OrderCardBody } from './order-card-body';

type Props = {
  disabled?: FormDisabled,
  required: FormRequired,
};

const CarQuoteBuyForm = ({ required, disabled }: Props) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Alert variant="warning">{t('personalInfo.changeabilityWarningMessage')}</Alert>

      <Card>
        <VehicleCardBody required={required} />
        <OrderCardBody disabled={disabled} required={required} />

        <Card.Body>
          <Card.Title>{t('common:fillOwnerDetails')}</Card.Title>
          <UserFormControl disabled={disabled} required={required} />
        </Card.Body>

        <Card.Footer className="bg-white border-0">
          <div className="text-center my-4">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              <span className="me-2">{t('common:summary')} & {t('common:review')}</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export { CarQuoteBuyForm };
