import { createActions } from 'redux-actions';
import { GetArticleParams } from '@apis/ArticleApi';
import { Article } from '@models/Article';

export type State = {
  article?: Article | null;
};

export type ArticleFetchPayload = GetArticleParams;
export type StateUpdatePayload = Partial<State>;

export const {
  articleFetch,
  stateUpdate,
} = createActions(
  'ARTICLE_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'ARTICLE_DETAILS_PAGE',
  },
);
