import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';
import parse from 'html-react-parser';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { Loading, LoadingError } from '@components/Base';
import { formatDateTime } from '@utils/datetime';
import { ArticleBreadcrumb } from '@components/Feature/Article/article-breadcrumb';

import { useArticleFetch } from './hook';

const ArticleDetailsPage = () => {
  const article = useArticleFetch();

  if (isUndefined(article)) return <Loading />;
  if (isNull(article)) return <LoadingError />;

  const { contentHtml, title, shortDescription, coverPhoto, publishedAt } = article;

  return (
    <>
      <MetaTag
        pageName="articleDetailsPage"
        title={title}
        description={shortDescription}
        image={coverPhoto}
      />
      <HeaderPrimary />
      <Container className="mt-5">
        <>
          <ArticleBreadcrumb article={article} />

          <Row>
            <Col lg={8}>
              <article className="my-5">
                <h1>{title}</h1>
                <div>{formatDateTime(publishedAt)}</div>
                <div className="my-5 text-center">
                  <Image src={coverPhoto} fluid />
                </div>
                {parse(contentHtml)}
              </article>
            </Col>
          </Row>
        </>
      </Container>
      <Footer />
    </>
  );
};

export { ArticleDetailsPage };
