import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { Loading, LoadingError } from '@components/Base';
import { ActivationDateControl } from '@components/Feature/Quote';

import { useGeneralQuoteFetch } from '../hook';
import { generalQuoteLock, GeneralQuoteLockPayload } from '../action';
import { RightCol } from '../right-col';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';

const GeneralQuoteBuyLockPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const generalQuote = useGeneralQuoteFetch();
  const initialValues = useInitialValues(generalQuote);
  const validationSchema = useValidationSchema();

  if (isUndefined(generalQuote)) return <Loading />;
  if (isNull(generalQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { generalOrderId } = generalQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: GeneralQuoteLockPayload = { generalOrderId, ...values };
    dispatch(generalQuoteLock(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form>
                <Card>
                  <Card.Body>
                    <ActivationDateControl required noMin noMax />
                  </Card.Body>

                  <Card.Footer className="bg-white border-0 mx-auto my-4">
                    <Stack direction="horizontal" gap={3}>
                      <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                        {t('common:cancel')}
                      </Button>

                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        {t('common:confirm')}
                      </Button>
                    </Stack>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Col>

        <RightCol generalQuote={generalQuote} />
      </Row>
    </Container>
  );
};

export { GeneralQuoteBuyLockPage };
