import { array, object, string } from 'yup';

export const peopleParamsSchema = object({
  me: object({
    gender: string(),
    dob: string(),
  }),
  spouse: object({
    dob: string(),
  }).default(undefined),
  sons: array().of(
    object({
      dob: string(),
    }),
  ),
  daughters: array().of(
    object({
      dob: string(),
    }),
  ),
});

export const cancerQuoteResultPageParamsSchema = peopleParamsSchema.concat(
  object({
    activationDate: string()
  })
);
