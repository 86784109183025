import React from 'react';

import { NoData } from '@components/Base';
import { OrderableTypes } from '@models/Order';
import { useRouteParam } from '@hooks/params';

import CarPolicyDetails from './CarPolicyDetails';
import HealthPolicyDetails from './HealthPolicyDetails';
import PersonalAccidentPolicyDetails from './PersonalAccidentPolicyDetails';
import CancerPolicyDetails from './CancerPolicyDetails';
import GeneralPolicyDetails from './GeneralPolicyDetails';

const PolicyDetails = () => {
  const orderableType = useRouteParam('orderableType');

  switch (orderableType) {
    case OrderableTypes.Car:
      return <CarPolicyDetails />;
    case OrderableTypes.Health:
      return <HealthPolicyDetails />;
    case OrderableTypes.PersonalAccident:
      return <PersonalAccidentPolicyDetails />;
    case OrderableTypes.Cancer:
      return <CancerPolicyDetails />;
    case OrderableTypes.General:
      return <GeneralPolicyDetails />;
    default:
      return <NoData />;
  }
};

export { PolicyDetails };
