import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mergeInitialValues } from '@utils/formik';
import { Genders } from '@models/User';

import { FormValues } from '../HealthQuoteBuyForm';
import { selectHealthQuoteBuyRoute } from '../selector';

export const useInitialValues = () => {
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);
  const initialValues: FormValues = {
    me: {
      firstName: '',
      lastName: '',
      firstNameKm: '',
      lastNameKm: '',
      gender: Genders.Male,
      dob: '',
      weight: '',
      height: '',
      bmi: '',
      personalCode: '',
      occupation: '',
      email: '',
      noMobileNumber: false,
      mobileNumber: '',
      address: '',
      medicalQuestionnaire: [],
    },
    spouse: undefined,
    daughters: [],
    sons: [],
    activationDate: '',
    healthPlanId: '',
    isConfirmedDeclaration: false,
  }

  return useMemo(() => {
    if (!healthQuote) return;
    return mergeInitialValues(initialValues, { ...healthQuote, ...healthQuote.people });
  }, [healthQuote]);
};
