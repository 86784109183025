import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useEffectOnce } from 'usehooks-ts';

import HeaderSecondary from '@components/HeaderSecondary';
import { QuoteSupportCard } from '@components/Feature/Support';
import { useCarFetch } from '@components/Feature/Car';
import { selectIsFrontline } from '@components/Auth';

import ComparableCarQuote from './ComparableCarQuote';
import { SubHeader } from './sub-header';
import { CarQuoteList } from './car-quote-list';
import FilterSection from './FilterSection';
import FilterProvider from './FilterProvider';
import { stateReset } from './action';

const CarQuoteResultPage = () => {
  const dispatch = useDispatch();
  const isFrontline = useSelector(selectIsFrontline);
  useCarFetch();

  useEffectOnce(() => {
    dispatch(stateReset());
  });

  return (
    <>
      <HeaderSecondary />
      <section className="py-3">
        <SubHeader />
      </section>
      <FilterProvider>
        <section className="py-3">
          <FilterSection />
        </section>
        {!isFrontline && (
          <section className="py-3">
            <Container>
              <QuoteSupportCard />
            </Container>
          </section>
        )}
        <CarQuoteList />
        <ComparableCarQuote />
      </FilterProvider>
    </>
  );
};

export { CarQuoteResultPage };
