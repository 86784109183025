import { call, put, takeLatest } from 'redux-saga/effects';

import { CancerQuote } from '@models/CancerQuote';
import { CancerOrder } from '@models/CancerOrder';
import { CancerQuoteApi } from '@apis/CancerQuoteApi';
import { CancerOrderApi } from '@apis/CancerOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routeCancerOrderPaymentPage } from '@components/CancerOrderPaymentPage';

import {
  cancerQuoteFind,
  CancerQuoteFindPayload,
  cancerQuoteCreate,
  CancerQuoteCreatePayload,
  cancerQuoteFetch,
  CancerQuoteFetchPayload,
  cancerQuoteUpdate,
  CancerQuoteUpdatePayload,
  cancerQuoteCustom,
  CancerQuoteCustomPayload,
  cancerOrderPay,
  CancerOrderPayPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { routeCancerQuoteBuyReviewPage } from './route';

function* handleCancerQuoteFind({ payload }: SagaPayload<CancerQuoteFindPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.find, payload)) as CancerQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteCreate({ payload }: SagaPayload<CancerQuoteCreatePayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.create, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCancerQuoteBuyReviewPage({ cancerOrderId: state.cancerQuote.cancerOrderId }));
  } catch { }
}

function* handleCancerQuoteFetch({ payload }: SagaPayload<CancerQuoteFetchPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.get, payload)) as CancerQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteUpdate({ payload }: SagaPayload<CancerQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.update, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCancerQuoteBuyReviewPage({ cancerOrderId: state.cancerQuote.cancerOrderId }));
  } catch { }
}

function* handleCancerQuoteCustom({ payload }: SagaPayload<CancerQuoteCustomPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.custom, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCancerQuoteBuyReviewPage({ cancerOrderId: state.cancerQuote.cancerOrderId }));
  } catch { }
}

function* handleCancerOrderPay({ payload }: SagaPayload<CancerOrderPayPayload>) {
  try {
    const { id, billingId } = (yield call(CancerOrderApi.pay, payload)) as CancerOrder;
    history.navigate(routeCancerOrderPaymentPage({ cancerOrderId: id, billingId }));
  } catch { }
}

export default function* () {
  yield takeLatest(cancerQuoteFind, handleCancerQuoteFind);
  yield takeLatest(cancerQuoteCreate, handleCancerQuoteCreate);
  yield takeLatest(cancerQuoteFetch, handleCancerQuoteFetch);
  yield takeLatest(cancerQuoteUpdate, handleCancerQuoteUpdate);
  yield takeLatest(cancerQuoteCustom, handleCancerQuoteCustom);
  yield takeLatest(cancerOrderPay, handleCancerOrderPay);
}
