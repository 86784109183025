import { createActions } from 'redux-actions';

import { HealthQuoteList } from '@models/HealthQuote';
import { GetAllHealthQuoteParams } from '@apis/HealthQuoteApi';
import { HealthQuoteGroup } from '@models/HealthQuoteGroup';

export type State = {
  totalQuotes?: number | null;
  healthQuoteGroups?: HealthQuoteGroup[] | null;
  comparableHealthQuotes: HealthQuoteList[];
};

export type HealthQuotesFetchPayload = Omit<GetAllHealthQuoteParams, 'healthPlanIds'>;
export type CompareAddPayload = { healthQuote: HealthQuoteList };
export type CompareRemovePayload = CompareAddPayload;
export type StateUpdatePayload = Partial<State>;

export const {
  healthQuotesFetch,
  compareAdd,
  compareRemove,
  compareRemoveAll,
  stateUpdate,
  stateReset,
} = createActions(
  'HEALTH_QUOTES_FETCH',
  'COMPARE_ADD',
  'COMPARE_REMOVE',
  'COMPARE_REMOVE_ALL',
  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'HEALTH_QUOTE_RESULT_PAGE',
  },
);
