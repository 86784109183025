import { call, put, takeLatest } from 'redux-saga/effects';

import { Insurer } from '@models/Insurer';
import { InsurerApi } from '@apis/InsurerApi';

import {
  insurersFetch,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleInsurersFetch() {
  const state: StateUpdatePayload = { insurers: [] };
  try {
    state.insurers = (yield call(InsurerApi.getAll)) as Insurer[];
    yield put(stateUpdate(state));
  } catch { }
}

export default function* () {
  yield takeLatest(insurersFetch, handleInsurersFetch);
}
