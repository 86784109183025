import { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';
import { compact } from 'lodash';

export enum UserRoles {
  Customer = 'customer',
  Frontline = 'frontline',
  Insurer = 'insurer',
  Company = 'company',
}

export enum UserStatuses {
  Pending = 'pending',
  Confirmed = 'confirmed',
}

export enum Genders {
  Male = 'male',
  Female = 'female',
}

export type User = CamelCaseKeys<{
  id: number;
  gender: Genders;
  height: number;
  weight: number;
  bmi: number;
  dob: string;
  occupation: string;
  personal_code: string;
  email: string;
  no_mobile_number: boolean;
  mobile_number: string;
  address: string;
  first_name: string;
  first_name_km: string;
  last_name: string;
  last_name_km: string;
  full_name: string;
  full_name_km: string;
}>;

export type UserProfile = User & CamelCaseKeys<{
  role: UserRoles;
  status: UserStatuses;
}>;

export const tGender = (gender: Genders) => tEnum('genders', gender);
export const dUserFullname = ({ fullName, fullNameKm }: Pick<User, 'fullName' | 'fullNameKm'>) => compact([fullName, fullNameKm]).join(' - ');
