import { put, call, takeLatest } from 'redux-saga/effects';

import { Car } from '@models/Car';
import { CarApi } from '@apis/CarApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routeCarQuotePage } from '@components/CarQuotePage';

import { carFetch, CarFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleCarFetch({ payload }: SagaPayload<CarFetchPayload>) {
  const state: StateUpdatePayload = { car: null };
  try {
    state.car = (yield call(CarApi.getIdv, payload)) as Car;
    yield put(stateUpdate(state));
  } catch {
    history.navigate(routeCarQuotePage(), { replace: true });
  }
}

export default function* () {
  yield takeLatest(carFetch, handleCarFetch);
}
