import { getRoute } from '@utils/route';

type RouteParams = {
  cancerOrderId: number;
  billingId: number;
};

export const CANCER_ORDER_PAYMENT_PAGE_URL = '/cancer-insurance-quote-payment/:cancerOrderId/:billingId';

export const routeCancerOrderPaymentPage = (routeParams: RouteParams) => getRoute(CANCER_ORDER_PAYMENT_PAGE_URL, { routeParams });
