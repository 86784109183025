import { WithParams, NumericString } from './typescript';

type MemberAmount = WithParams<{ chargeAmount: NumericString; }>;

export type CustomQuoteParams = WithParams<{
  note: string;
  proof?: File;
  me: MemberAmount;
  spouse?: MemberAmount;
  sons: MemberAmount[];
  daughters: MemberAmount[];
}>;

export const serializeCustomFormData = ({ note, proof, me, spouse, sons, daughters }: CustomQuoteParams): FormData => {
  const formData = new FormData();

  if (proof) {
    formData.append('proof', proof);
  }

  if (note) {
    formData.append('note', note);
  }

  if (me) {
    formData.append('me[charge_amount]', me.chargeAmount.toString());
  }

  if (spouse) {
    formData.append('spouse[charge_amount]', spouse.chargeAmount.toString());
  }

  if (sons) {
    sons.forEach((son) => {
      formData.append('sons[][charge_amount]', son.chargeAmount.toString());
    });
  }

  if (daughters) {
    daughters.forEach((daughter) => {
      formData.append('daughters[][charge_amount]', daughter.chargeAmount.toString());
    });
  }

  return formData;
};
