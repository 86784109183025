import { call, put, takeLatest } from 'redux-saga/effects';

import { CarOrder } from '@models/CarOrder';
import { CarOrderApi } from '@apis/CarOrderApi';
import { SagaPayload } from '@utils/typescript';

import {
  carOrderFetch,
  CarOrderFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCarOrderFetch({ payload }: SagaPayload<CarOrderFetchPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.get, payload)) as CarOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carOrderFetch, handleCarOrderFetch);
}
