import React from 'react';
import { Container, Row, Col, useAccordionButton, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { QuoteDocumentStack } from '@components/Feature/Quote';
import { QuotableDocument } from '@models/Quote';

import { Title } from './title';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <a className="c-pointer link-info" onClick={handleClick}>
      {children}
    </a>
  );
};

const DocumentSection = (props: QuotableDocument) => {
  const { t } = useTranslation();

  return (
    <section className="pb-5 bg-light">
      <Accordion flush alwaysOpen>
        <Title>
          <Toggle eventKey="0">{pluralize(t('common:document'), 2)}</Toggle>
        </Title>
        <Accordion.Collapse eventKey="0">
          <Container>
            <Row>
              <Col lg={10} className="mx-auto">
                <QuoteDocumentStack {...props} className="justify-content-center" />
              </Col>
            </Row>
          </Container>
        </Accordion.Collapse>
      </Accordion>
    </section>
  );
};

export { DocumentSection };
