import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useParseParams } from '@hooks/params';

import { selectHealthQuoteDetailsPage } from './selector';
import { healthQuoteFind, HealthQuoteFindPayload } from './action';
import { HealthQuoteDetailsPageParams } from './route';
import { healthQuoteDetailsPageParamsSchema } from './schema';

export const useHealthQuoteDetailsPageParams = () =>
  useParseParams<HealthQuoteDetailsPageParams>(healthQuoteDetailsPageParamsSchema);

export const useHealthQuoteFind = () => {
  const dispatch = useDispatch();
  const payload: HealthQuoteFindPayload = useHealthQuoteDetailsPageParams();
  const { healthQuote } = useSelector(selectHealthQuoteDetailsPage);

  useEffectOnce(() => {
    dispatch(healthQuoteFind(payload));
  });

  return healthQuote;
};
