import { call, put, takeLatest } from 'redux-saga/effects';

import { HealthQuote } from '@models/HealthQuote';
import { HealthOrder } from '@models/HealthOrder';
import { HealthQuoteApi } from '@apis/HealthQuoteApi';
import { HealthOrderApi } from '@apis/HealthOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routeHealthOrderPaymentPage } from '@components/HealthOrderPaymentPage';

import {
  healthQuoteFind,
  HealthQuoteFindPayload,
  healthQuoteCreate,
  HealthQuoteCreatePayload,
  healthQuoteFetch,
  HealthQuoteFetchPayload,
  healthQuoteUpdate,
  HealthQuoteUpdatePayload,
  healthQuoteCustom,
  HealthQuoteCustomPayload,
  healthOrderPay,
  HealthOrderPayPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { routeHealthQuoteBuyReviewPage } from './route';

function* handleHealthQuoteFind({ payload }: SagaPayload<HealthQuoteFindPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.find, payload)) as HealthQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteCreate({ payload }: SagaPayload<HealthQuoteCreatePayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.create, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(routeHealthQuoteBuyReviewPage({ healthOrderId: state.healthQuote.healthOrderId }));
  } catch { }
}

function* handleHealthQuoteFetch({ payload }: SagaPayload<HealthQuoteFetchPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.get, payload)) as HealthQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteUpdate({ payload }: SagaPayload<HealthQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.update, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(routeHealthQuoteBuyReviewPage({ healthOrderId: state.healthQuote.healthOrderId }));
  } catch { }
}

function* handleHealthOrderPay({ payload }: SagaPayload<HealthOrderPayPayload>) {
  try {
    const { id, billingId } = (yield call(HealthOrderApi.pay, payload)) as HealthOrder;
    history.navigate(routeHealthOrderPaymentPage({ healthOrderId: id, billingId: billingId }));
  } catch { }
}

function* handleHealthQuoteCustom({ payload }: SagaPayload<HealthQuoteCustomPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.custom, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(routeHealthQuoteBuyReviewPage({ healthOrderId: state.healthQuote.healthOrderId }));
  } catch { }
}

export default function* () {
  yield takeLatest(healthQuoteFind, handleHealthQuoteFind);
  yield takeLatest(healthQuoteCreate, handleHealthQuoteCreate);
  yield takeLatest(healthQuoteFetch, handleHealthQuoteFetch);
  yield takeLatest(healthQuoteUpdate, handleHealthQuoteUpdate);
  yield takeLatest(healthOrderPay, handleHealthOrderPay);
  yield takeLatest(healthQuoteCustom, handleHealthQuoteCustom);
}
