import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { HealthQuote } from '@models/HealthQuote';
import { HealthQuoteBenefitScheduleAccordion } from '@components/Feature/HealthQuote';

import { Title } from './title';

const BenefitSection = (healthQuote: HealthQuote) => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <Title>{pluralize(t('common:benefit'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <HealthQuoteBenefitScheduleAccordion {...healthQuote} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { BenefitSection };
