import { getRoute } from '@utils/route';

type RouteParams = {
  carOrderId: number;
  billingId: number;
};

export const CAR_ORDER_PAYMENT_PAGE_URL = '/car-insurance-quote-payment/:carOrderId/:billingId';

export const routeCarOrderPaymentPageRoute = (routeParams: RouteParams) => getRoute(CAR_ORDER_PAYMENT_PAGE_URL, { routeParams });
