import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import HealthQuoteBuyNewPage from './HealthQuoteBuyNewPage';
import HealthQuoteReviewPage from './HealthQuoteReviewPage';
import HealthQuoteBuyEditPage from './HealthQuoteBuyEditPage';
import HealthQuoteBuyCustomPage from './HealthQuoteBuyCustomPage';

const HealthQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route path="/*" element={<HealthQuoteBuyNewPage />} />
        <Route path="/:healthOrderId/edit/*" element={<HealthQuoteBuyEditPage />} />
        <Route path="/:healthOrderId/review" element={<HealthQuoteReviewPage />} />
        <Route path="/:healthOrderId/custom" element={<HealthQuoteBuyCustomPage />} />
      </Routes>
    </>
  );
};

export { HealthQuoteBuyRoute };
