import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { InsuranceTypes } from '@models/InsuranceType';
import { useParamsPage, useRouteParam } from '@hooks/params';

import { selectArticlePage } from './selector';
import { articlesFetch } from './action';
import { ArticlesFetchPayload } from './action';

export const useArticlesFetch = () => {
  const dispatch = useDispatch();
  const insuranceType = useRouteParam<InsuranceTypes>('insuranceType', true);
  const page = useParamsPage();
  const { articles, pagination } = useSelector(selectArticlePage);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const payload: ArticlesFetchPayload = { page, insuranceType };
    dispatch(articlesFetch(payload));
  }, [page, insuranceType]);

  useEffect(() => {
    if (isUndefined(articles)) return;
    setIsLoading(false);
  }, [articles]);

  return { articles, isLoading, pagination, insuranceType };
}
