import React from 'react';
import { isNil, map } from 'lodash';
import { useSelector } from 'react-redux';
import { Stack } from 'react-bootstrap';

import { NoData } from '@components/Base';
import { isEmptyArray } from '@utils/lodash';

import { selectVehicle } from './selector';
import { UserCarItem } from './user-car-item';

const UserCarList = () => {
  const { userCars } = useSelector(selectVehicle);

  if (isNil(userCars)) return null;
  if (isEmptyArray(userCars)) return <NoData />;

  return (
    <Stack gap={3}>
      {map(userCars, (userCar) => (
        <UserCarItem userCar={userCar} key={userCar.id} />
      ))}
    </Stack>
  );
};

export { UserCarList };
