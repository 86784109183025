import { useTranslation } from 'react-i18next';
import { useFormSchema, useFormValues } from '../GeneralQuoteBuyForm';

export const useInitialValues = useFormValues;

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const schema = useFormSchema();
  return schema.shape({
    proof: schema.fields.proof.required(t('validation:required')),
  });
};
