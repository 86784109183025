import React from 'react';
import { Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { BackButton, RowItem } from '@components/Base';
import { tInsuranceTypeName } from '@models/InsuranceType';
import { OrderBadge, OrderDocumentCardBody } from '@components/Feature/Order';
import { dUserFullname } from '@models/User';
import { dOrderPeriod, getOrderStatusVariant, Orderable } from '@models/Order';
import { getInsurerHotlines } from '@models/Insurer';

const ROW_ITEM_PROPS = {
  className: 'mb-2',
  labelProps: { xs: 7, sm: 7, md: 4, lg: 3 },
};

type Props = Orderable & {
  children: React.ReactNode;
}

const OrderableCard = ({ children, ...orderable }: Props) => {
  const { t } = useTranslation();
  const {
    insuranceType,
    status,
    orderNumber,
    policyNumber,
    user,
    frontlineUser,
    insurer,
    startedAt,
    endedAt,
    coverScopeTitle,
  } = orderable;
  const statusVariant = getOrderStatusVariant(status);
  const hotline = getInsurerHotlines(insurer, insuranceType);

  return (
    <>
      <BackButton className="mb-4" />
      <Card border={statusVariant}>
        <Card.Header as="h5" className="bg-transparent d-flex flex-row justify-content-between align-items-center">
          <span>{tInsuranceTypeName(insuranceType)}</span>
          <OrderBadge status={status} />
        </Card.Header>
        <Card.Body>
          <RowItem {...ROW_ITEM_PROPS} label={t('common:orderNumber')} value={orderNumber} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:theInsuredPolicyholder')} value={dUserFullname(user)} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:frontlineUser')} value={dUserFullname(frontlineUser)} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:policyNumber')} value={policyNumber} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:insurer')} value={insurer.name} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:periodOfInsurance')} value={dOrderPeriod(startedAt, endedAt)} />
          <RowItem {...ROW_ITEM_PROPS} label={t('common:planType')} value={coverScopeTitle} />
          <small><Trans i18nKey="common:emergencyCall" values={{ hotline }} /></small>
        </Card.Body>
        <OrderDocumentCardBody {...orderable} />
        {children}
      </Card>
    </>
  );
};

export { OrderableCard };
