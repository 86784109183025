import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useParseParams } from '@hooks/params';
import { mergeInitialValues } from '@utils/formik';

import { formValuesParamsSchema, FormValues } from '../PersonalAccidentQuoteBuyForm';
import { selectPersonalAccidentQuoteBuyRoute } from '../selector';

export const useInitialValues = () => {
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!personalAccidentQuote) return;

    const { people, activationDate, personalAccidentPlanId } = personalAccidentQuote;

    return mergeInitialValues({ spouse: undefined, ...initialValues }, { ...people, activationDate, personalAccidentPlanId });
  }, [personalAccidentQuote]);
};
