import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormGroupControl } from '@components/Base';

const ClaimInfo = () => {
  return (
    <Card.Body>
      <Card.Title>Submit Claim</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="patientInfo"
            label="Patient Information"
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl name="typeOfClaim" label="Type of Claim" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="claimPrice"
            label="How much is your claim for?"
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl name="dateTreatment" label="Date Treatment" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl name="description" label="Description" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="attachment"
            type="file"
            multiple
            label="Attachment"
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { ClaimInfo };
