import { createActions } from 'redux-actions';
import { BillingList } from '@models/Billing';
import { Pagination } from '@models/Pagination';
import { GetAllBillingParams } from '@apis/BillingApi';

export type State = {
  billings?: BillingList[] | null;
  pagination?: Pagination | undefined;
};

export type BillingsFetchPayload = GetAllBillingParams;
export type StateUpdatePayload = Partial<State>;

export const {
  billingsFetch,
  stateUpdate,
} = createActions(
  'BILLINGS_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_BILLING',
  },
);
