
import { createActions } from 'redux-actions';

import { GetHealthOrderParams } from '@apis/HealthOrderApi';
import { HealthOrder } from '@models/HealthOrder';

export type State = {
  healthOrder?: HealthOrder | null;
};

export type HealthOrderFetchPayload = GetHealthOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  healthOrderFetch,
  stateUpdate,
} = createActions(
  'HEALTH_ORDER_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_HEALTH_POLICY_DETAILS',
  },
);
