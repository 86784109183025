import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { object, string } from 'yup';
import { mergeInitialValues } from '@utils/formik';

import { FormValues } from './type';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const values: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    note: '',
  };

  return useMemo(() => {
    if (!isCustomer) return values;
    if (!currentUser) return values;
    return mergeInitialValues(values, currentUser);
  }, [currentUser, isCustomer]);
};


export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    firstName: string().required(t('validation:required')),
    lastName: string().required(t('validation:required')),
    email: string().email().required(t('validation:required')),
    note: string().optional(),
  });
};
