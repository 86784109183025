import React from 'react';
import { isUndefined, isNull } from 'lodash';

import { Loading, LoadingError } from '@components/Base';
import { CarQuoteCoverCardBody, CarQuoteDeductibleCardBody, CarQuoteEndorsementCardBody } from '@components/Feature/CarQuote';
import { QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';

import { useCarOrderFetch } from './hook';
import { OrderableCard } from '../orderable-card';

const CarPolicyDetails = () => {
  const carOrder = useCarOrderFetch();

  if (isUndefined(carOrder)) return <Loading />;
  if (isNull(carOrder)) return <LoadingError />;

  return (
    <OrderableCard {...carOrder}>
      <CarQuoteCoverCardBody {...carOrder} />
      <CarQuoteDeductibleCardBody {...carOrder} />
      <QuoteExclusionCardBody {...carOrder} />
      <QuoteDocumentCardBody {...carOrder} />
      <CarQuoteEndorsementCardBody {...carOrder} />
    </OrderableCard>
  );
};

export { CarPolicyDetails };
