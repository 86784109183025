import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { mergeInitialValues } from '@utils/formik';

import { useFormValues, useFormSchema } from '../CarQuoteBuyForm';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const values = useFormValues();
  return useMemo(() => {
    if (!currentUser) return;
    if (isCustomer) return mergeInitialValues(values, currentUser);
    return values;
  }, [currentUser, isCustomer]);
};

export const useValidationSchema = useFormSchema;
