import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';

import { useIsRequired } from '@hooks/yup';

import GeneralQuoteBuyForm, { FormRequired, FormValues } from '../GeneralQuoteBuyForm';
import { generalQuoteCreate, GeneralQuoteCreatePayload } from '../action';
import { useInitialValues, useValidationSchema } from './hook';

const GeneralQuoteBuyNewPage = () => {
  const dispatch = useDispatch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const required = useIsRequired<FormRequired>(validationSchema);

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: GeneralQuoteCreatePayload = values
    dispatch(generalQuoteCreate(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            <GeneralQuoteBuyForm required={required} />
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export { GeneralQuoteBuyNewPage };
