import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { isEmpty, map } from 'lodash';

import { HealthQuote, HealthQuoteAnnualLimitAmount, HealthQuoteBenefit } from '@models/HealthQuote';
import { HealthPlanBenefit, HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';
import { amountToUsd } from '@utils/currency';
import { NumericString } from '@utils/typescript';

import { Title } from './title';

export const StretchRowItem = ({ label, value }: { label: string; value?: React.ReactNode }) => {
  return (
    <Row className="mb-2">
      <Col xs={6}>
        <strong>{label}</strong>
      </Col>
      <Col className="d-flex justify-content-end">
        {value}
      </Col>
    </Row>
  );
};

const FeatureSection = ({
  geographicCoverage,
  hospitals,
  inpatientAnnualLimitAmount,
  inpatientBenefits,
  outpatientAnnualLimitAmount,
  outpatientBenefits,
  healthCheckupAnnualLimitAmount,
  healthCheckupBenefits,
  maternityAnnualLimitAmount,
  maternityBenefits,
  visionAnnualLimitAmount,
  visionBenefits,
  dentalAnnualLimitAmount,
  dentalBenefits,
  copayAnnualLimitAmount,
  copayBenefits,
  othersAnnualLimitAmount,
  othersBenefits,
}: HealthQuoteBenefit & HealthQuoteAnnualLimitAmount & Pick<HealthQuote, 'geographicCoverage' | 'hospitals'>) => {
  const { t } = useTranslation();
  const items: [HealthPlanBenefitTypes, NumericString, HealthPlanBenefit[]][] = [
    [HealthPlanBenefitTypes.Inpatient, inpatientAnnualLimitAmount, inpatientBenefits],
    [HealthPlanBenefitTypes.Outpatient, outpatientAnnualLimitAmount, outpatientBenefits],
    [HealthPlanBenefitTypes.HealthCheckup, healthCheckupAnnualLimitAmount, healthCheckupBenefits],
    [HealthPlanBenefitTypes.Maternity, maternityAnnualLimitAmount, maternityBenefits],
    [HealthPlanBenefitTypes.Vision, visionAnnualLimitAmount, visionBenefits],
    [HealthPlanBenefitTypes.Dental, dentalAnnualLimitAmount, dentalBenefits],
    [HealthPlanBenefitTypes.Copay, copayAnnualLimitAmount, copayBenefits],
    [HealthPlanBenefitTypes.Others, othersAnnualLimitAmount, othersBenefits],
  ];

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>

      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Row className="g-3">
              <Col sm={12} md={6}>
                <Card className="h-100">
                  <Card.Body>
                    <StretchRowItem label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
                    <StretchRowItem label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="h-100">
                  <Card.Body>
                    {map(items, ([type, amount, benefits]) => (
                      <Fragment key={type}>
                        {(isEmpty(benefits) || !Number(amount)) ? null : (
                          <StretchRowItem label={tHealthPlanBenefitType(type)} value={amountToUsd(amount)} />
                        )}
                      </Fragment>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FeatureSection };
