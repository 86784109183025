import { combineReducers } from 'redux';
import { insurerReducer } from './Insurer';
import { provinceReducer } from './Province';
import { orderTemplateReducer } from './OrderTemplate';
import { companyReducer } from './Company';

export default combineReducers({
  insurerReducer,
  provinceReducer,
  orderTemplateReducer,
  companyReducer,
});
