import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, ColProps } from 'react-bootstrap';

import { HealthQuotePremiumCardBody } from '@components/Feature/HealthQuote';
import { SupportCard } from '@components/Feature/Support';
import { selectIsFrontline } from '@components/Auth';

type Props = Pick<ColProps, 'children'> & ComponentProps<typeof HealthQuotePremiumCardBody>;

const RightCol = ({ children, ...rest }: Props) => {
  const isFrontline = useSelector(selectIsFrontline);

  return (
    <Col lg>
      <Card className="mb-4">
        <HealthQuotePremiumCardBody {...rest} />
        {children}
      </Card>
      {!isFrontline && <SupportCard />}
    </Col>
  );
};

export { RightCol };
