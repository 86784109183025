import { createActions } from 'redux-actions';

import { FindHealthQuoteParams, SendEmailHealthQuoteParams } from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';

export type State = {
  healthQuote?: HealthQuote | null;
};

export type HealthQuoteFindPayload = FindHealthQuoteParams;
export type HealthQuoteSendEmailPayload = SendEmailHealthQuoteParams;
export type StateUpdatePayload = Partial<State>;

export const {
  healthQuoteFind,
  healthQuoteSendEmail,
  stateUpdate,
} = createActions(
  'HEALTH_QUOTE_FIND',
  'HEALTH_QUOTE_SEND_EMAIL',
  'STATE_UPDATE',
  {
    prefix: 'HEALTH_QUOTE_DETAILS_ROUTE',
  },
);
