import { camelCase } from 'lodash';
import i18n, { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';

export enum CarPlanThirdPartyCoverageTypes {
  DeathBodilyInjury = 'death_bodily_injury',
  PropertyDamage = 'property_damage',
  CostOfDefense = 'cost_of_defense',
  AnnualAggregateLimit = 'annual_aggregate_limit',
}

export enum CarPlanComprehensiveCoverageTypes {
  OwnDamageTheft = 'own_damage_theft',
}

export enum CarPlanAddonCoverageTypes {
  AccidentToDriver = 'accident_to_driver',
  PassengerLiability = 'passenger_liability',
}

const CarPlanCoverType = {
  ...CarPlanThirdPartyCoverageTypes,
  ...CarPlanComprehensiveCoverageTypes,
};

export type CarPlanCover = CamelCaseKeys<{
  coverage_type: keyof typeof CarPlanCoverType;
  description: string;
}>;

export enum CarPlanTypes {
  Comprehensive = 'comprehensive',
  ThirdParty = 'third_party',
}

export enum CarPlanCategories {
  Individual = 'individual',
  Corporate = 'corporate',
  EletricVehicle = 'electric_vehicle',
}

export const tCarPlanType = (type: CarPlanTypes) => tEnum('carPlanTypes', type);
export const tCarPlanCategory = (type: CarPlanCategories) => tEnum('carPlanCategories', type);

export const getCarPlanTypeCover = (planType: CarPlanTypes) => {
  return i18n.t(camelCase(`${planType}Cover`), { ns: 'common' });
};
