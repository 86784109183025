import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HeaderPrimary from '@components/HeaderPrimary';

const ThankYouPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderPrimary />
      <Card className="text-center">
        <Card.Body>
          <Card.Title>{t('thankYou.title')}</Card.Title>
          <Card.Text>{t('thankYou.description')}</Card.Text>
          <Card.Link as={Link} to="/user-dashboard">{t('thankYou.goToDashboard')}</Card.Link>
        </Card.Body>
      </Card>
    </>
  );
};

export { ThankYouPage };
