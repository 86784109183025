import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/params';

import { selectCancerQuoteComparePage } from './selector';
import { cancerQuotesFetch } from './action';
import { CancerQuoteComparePageParams } from './route';
import { cancerQuoteComparePageParamsSchema } from './schema';

export const useCancerQuotesComparePageParams = () =>
  useParseParams<CancerQuoteComparePageParams>(cancerQuoteComparePageParamsSchema);

export const useCancerQuotesFetch = () => {
  const dispatch = useDispatch();
  const payload: CancerQuoteComparePageParams = useCancerQuotesComparePageParams();
  const cancerQuotes = useSelector(selectCancerQuoteComparePage);

  useEffect(() => {
    dispatch(cancerQuotesFetch(payload));
  }, []);

  return cancerQuotes;
};
