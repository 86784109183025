import { PersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type PersonalAccidentQuoteDetailsPageParams = Required<
  Omit<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
  Pick<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    personalAccidentPlanId: number;
  };

export type PersonalAccidentQuoteSendEmailPageParams = PersonalAccidentQuoteDetailsPageParams;

export const PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL = '/personal-accident-insurance-quote-details/*';
export const PERSONAL_ACCIDENT_QUOTE_DETAILS_PAGE_URL = '/personal-accident-insurance-quote-details';
export const PERSONAL_ACCIDENT_QUOTE_SEND_MAIL_PAGE = '/personal-accident-insurance-quote-details/send-email';

export const routePersonalAccidentQuoteDetailsPage = (searchParams: PersonalAccidentQuoteDetailsPageParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_DETAILS_PAGE_URL, { searchParams });
export const routePersonalAccidentQuoteSendEmailPage = (searchParams: PersonalAccidentQuoteSendEmailPageParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_SEND_MAIL_PAGE, { searchParams });
