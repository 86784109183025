import { boolean, date, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useParseParams } from '@hooks/params';
import { userFormValuesSchema } from './schema';
import { UserFormValues } from './type';

export const useUserFormValues = () => useParseParams<UserFormValues>(userFormValuesSchema);

export const useUserFormSchema = () => {
  const { t } = useTranslation();

  return object({
    firstName: string().required(t('validation:required')),
    lastName: string().required(t('validation:required')),
    firstNameKm: string().optional(),
    lastNameKm: string().optional(),
    gender: string().required(t('validation:required')),
    dob: date().optional(),
    personalCode: string().optional(),
    occupation: string().required(t('validation:required')),
    email: string().email().optional(),
    noMobileNumber: boolean().optional(),
    mobileNumber: string()
      .required(t('validation:required'))
      .when('noMobileNumber', {
        is: true,
        otherwise: (schema) => schema.phone('KH', t('validation:validPhone', { countryName: t('common:cambodia') })),
      }),
    address: string().required(t('validation:required')),
  });
};
