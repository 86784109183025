import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, ColProps } from 'react-bootstrap';

import { CarQuotePremiumCardBody } from '@components/Feature/CarQuote';
import { SupportCard } from '@components/Feature/Support';
import { selectIsFrontline } from '@components/Auth';
import { CarQuote } from '@models/CarQuote';

type Props = Pick<ColProps, 'children'> & {
  carQuote: CarQuote
};

const RightCol = ({ children, carQuote }: Props) => {
  const isFrontline = useSelector(selectIsFrontline);

  return (
    <Col lg>
      <Card className="mb-4">
        <CarQuotePremiumCardBody {...carQuote} />
        {children}
      </Card>
      {!isFrontline && <SupportCard />}
    </Col>
  );
};

export { RightCol };
