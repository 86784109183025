import { mergeInitialValues } from '@utils/formik';
import { useParseParams } from '@hooks/params';

import { formValuesParamsSchema, FormValues } from '../CancerQuoteBuyForm';
import { useCancerQuoteFetch } from '../hook';

export const useCancerQuoteReview = () => {
  const cancerQuote = useCancerQuoteFetch();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);
  const values = mergeInitialValues(
    initialValues,
    {
      ...cancerQuote?.people,
      activationDate: cancerQuote?.activationDate,
      cancerPlanId: cancerQuote?.cancerPlanId
    }
  );

  return { cancerQuote, values };
};
