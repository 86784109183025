import { createActions } from 'redux-actions';

import { FindPersonalAccidentQuoteParams, SendEmailPersonalAccidentQuoteParams } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

export type State = {
  personalAccidentQuote?: PersonalAccidentQuote | null;
};

export type PersonalAccidentQuoteFindPayload = FindPersonalAccidentQuoteParams;
export type PersonalAccidentQuoteSendEmailPayload = SendEmailPersonalAccidentQuoteParams;
export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentQuoteFind,
  personalAccidentQuoteSendEmail,
  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_QUOTE_FIND',
  'PERSONAL_ACCIDENT_QUOTE_SEND_EMAIL',
  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE',
  },
);
