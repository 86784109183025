import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';

import HealthQuoteBuyForm from '../HealthQuoteBuyForm';
import { FormValues } from '../HealthQuoteBuyForm/schema';
import { healthQuoteCreate, HealthQuoteCreatePayload } from '../action';
import { useHealthQuoteBuyNewPageParams, useHealthQuoteFind } from '../hook';
import { RightCol } from '../right-col';
import { useInitialValues } from './hook';

const HealthQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useHealthQuoteBuyNewPageParams();
  const healthQuote = useHealthQuoteFind(params);
  useCurrentUser();
  const initialValues = useInitialValues();

  if (isUndefined(healthQuote)) return <Loading />;
  if (isNull(healthQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const handleSubmit = (values: FormValues) => {
    const payload: HealthQuoteCreatePayload = values;
    dispatch(healthQuoteCreate(payload));
  };

  return (
    <Container className="my-5">
      <Row>
        <h2>{t('healthQuoteBuyPage.title')}</h2>
        <p>{t('healthQuoteBuyPage.subTitle')}</p>
        <Col lg={8}>
          <HealthQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>
        <RightCol {...healthQuote} planName={healthQuote.healthPlanName} />
      </Row>
    </Container>
  );
};

export { HealthQuoteBuyNewPage };
