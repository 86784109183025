import React from 'react';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocales } from './hook';

const LocaleButtonGroup = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const locales = useLocales();

  const handleChangeLocale = (locale: string) => {
    window.location.replace(`/${locale}${location.pathname}${location.search}`);
  };

  return (
    <ButtonGroup className="w-100">
      {map(locales, ({ locale, label }) => (
        <Button
          key={locale}
          value={locale}
          active={i18n.language === locale}
          onClick={() => handleChangeLocale(locale)}
          variant="outline-primary"
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export { LocaleButtonGroup };
