import React from 'react';
import { useFormikContext } from 'formik';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { FormGroupCheck, StepFormProps } from '@components/Base';
import { useHealthQuoteFind } from '@components/HealthQuoteDetailsRoute';

import { FormValues } from '../schema';
import { CardHeader } from './card-header';
import { MemberList } from './member-list';
import { MemberAnswerList } from './member-answer-list';

const QuestionForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const { isValid } = useFormikContext<FormValues>();
  const healthQuote = useHealthQuoteFind();

  if (!healthQuote) return null;

  const { healthPlanQuestions, occupationDeclarationDescription } = healthQuote;

  return (
    <Card>
      <CardHeader onClick={() => onPrev?.()} title="" />
      <Card.Body className="d-grid gap-3">
        {map(healthPlanQuestions, ({ question }, idx) => (
          <Card key={`${question}-${idx}`} data-testid="question-item">
            <Card.Body>
              <p>{question}</p>
              <MemberList questionIdx={idx} />
              <MemberAnswerList questionIdx={idx} />
            </Card.Body>
          </Card>
        ))}
      </Card.Body>

      <Card.Body>
        <Card.Title className="text-warning">{t('common:declaration')}</Card.Title>

        <Alert variant="warning" className="mb-0">
          <FormGroupCheck
            name="isConfirmedDeclaration"
            label={occupationDeclarationDescription}
          />
        </Alert>
      </Card.Body>

      <Button size="lg" disabled={!isValid} onClick={() => onNext()}>
        {t('common:summary')} & {t('common:review')}
      </Button>
    </Card>
  );
};

export { QuestionForm };
