import { call, put, takeLatest } from 'redux-saga/effects';

import { CarOrder } from '@models/CarOrder';
import { CarOrderApi } from '@apis/CarOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routeCarQuoteBuyCustomPage } from '@components/CarQuoteBuyRoute';

import {
  stateUpdate,
  StateUpdatePayload,
  carOrderRenewalPreview,
  CarOrderRenewalPreviewPayload,
  carOrderRenew,
  CarOrderRenewPayload,
} from './action';

function* handleCarOrderRenewalPreview({ payload }: SagaPayload<CarOrderRenewalPreviewPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.renewalPreview, payload)) as CarOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarOrderRenew({ payload }: SagaPayload<CarOrderRenewPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.renew, payload)) as CarOrder;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyCustomPage({ carOrderId: state.carOrder.id }));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carOrderRenewalPreview, handleCarOrderRenewalPreview);
  yield takeLatest(carOrderRenew, handleCarOrderRenew);
}
