import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormGroupControl } from '@components/Base';

const HealthInsurancePlan = () => {
  return (
    <Card.Body>
      <Card.Title>Health Insurance Plan</Card.Title>
      <Row>
        <Col>
          <FormGroupControl name="healthPlan" label="Health Plan" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl name="annualLimit" label="Annual limit" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="geographicCoverage"
            label="Geographic Coverage"
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { HealthInsurancePlan };
