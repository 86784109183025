import React, { Fragment } from 'react';
import { isNull, map } from 'lodash';
import { useSelector } from 'react-redux';

import { NoData } from '@components/Base';
import { isEmptyArray } from '@utils/lodash';

import { ArticleItem } from './article-item';
import { selectArticlePage } from './selector';

const ArticleList = () => {
  const { articles } = useSelector(selectArticlePage);

  if (isNull(articles)) return null;
  if (isEmptyArray(articles)) return <NoData />;

  return (
    <>
      {map(articles, (article) => (
        <Fragment key={article.id}>
          <ArticleItem article={article} />
          <hr />
        </Fragment>
      ))}
    </>
  );
};

export { ArticleList };
