import { createActions } from 'redux-actions';

import { RenewalPreviewCarOrderParams, RenewCarOrderParams } from '@apis/CarOrderApi';
import { CarOrder } from '@models/CarOrder';

export type State = {
  carOrder?: CarOrder | null;
};

export type CarOrderRenewalPreviewPayload = RenewalPreviewCarOrderParams;
export type CarOrderRenewPayload = RenewCarOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carOrderRenewalPreview,
  carOrderRenew,
  stateUpdate,
} = createActions(
  'CAR_ORDER_RENEWAL_PREVIEW',
  'CAR_ORDER_RENEW',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CAR_POLICY_RENEWAL',
  },
);
