import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useParseParams } from '@hooks/params';
import { compareCarQuoteParamsSchema } from '@apis/CarQuoteApi';

import { selectCarQuoteComparePage } from './selector';
import { carQuotesFetch, CarQuotesFetchPayload } from './action';
import { CarQuoteComparePageParams } from './route';

export const useCarQuotesComparePageParams = () =>
  useParseParams<CarQuoteComparePageParams>(compareCarQuoteParamsSchema);

export const useCarQuotesFetch = () => {
  const dispatch = useDispatch();
  const params = useCarQuotesComparePageParams();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  useEffectOnce(() => {
    const payload: CarQuotesFetchPayload = params;
    dispatch(carQuotesFetch(payload));
  });

  return carQuotes;
};
