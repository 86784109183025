import { useEffectOnce } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteParam } from '@hooks/params';

import { selectArticleDetailsPage } from './selector';
import { articleFetch } from './action';

export const useArticleFetch = () => {
  const dispatch = useDispatch();
  const articleId = Number(useRouteParam('articleId'));
  const { article } = useSelector(selectArticleDetailsPage);

  useEffectOnce(() => {
    dispatch(articleFetch({ id: articleId }));
  });

  return article;
}
