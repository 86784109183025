import { InsuranceTypes } from "@models/InsuranceType";
import { getRoute } from "@utils/route";

export const ARTICLE_PAGE_URL = '/articles';
export const ARTICLE_INSURANCE_TYPE_PAGE_URL = '/articles/:insuranceType';

type RouteParams = {
  insuranceType: InsuranceTypes
};

export const routeArticlePage = (routeParams?: RouteParams) => routeParams ? getRoute(ARTICLE_INSURANCE_TYPE_PAGE_URL, { routeParams }) : getRoute(ARTICLE_PAGE_URL);
