

import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const DownloadLinkButton = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button variant="link" target="_blank" rel="noreferrer noopener" {...rest}>
      <FontAwesomeIcon icon={faDownload} className="me-2" />
      {children}
    </Button>
  );
};

export { DownloadLinkButton };
