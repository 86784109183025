import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { forOwn } from 'lodash';

import { FormGroupControl, FormGroupCheck } from '@components/Base';
import { selectCurrentUser, selectIsCustomer, selectIsFrontline } from '@components/Auth';

import { GenderFormSelect } from '../gender-form-select';
import { UserFormValues } from './type';

const GROUP_PROPS = { className: 'mb-3' };

const isDisabled = <T,>(values: T, isCustomer: boolean) => {
  const result = {} as Record<keyof T, boolean>;
  forOwn(values, (v, k) => {
    result[k as keyof T] = isCustomer && !!v;
  });
  return result;
}

type Props = {
  disabled?: Partial<Record<keyof UserFormValues, boolean>>,
  required: Partial<Record<keyof UserFormValues, boolean>>,
}

const UserFormControl = ({ disabled: propDisabled, required }: Props) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const isFrontline = useSelector(selectIsFrontline);
  const { initialValues } = useFormikContext<UserFormValues>();
  const disabled = useMemo(() => ({
    ...isDisabled(initialValues, isCustomer),
    mobileNumber: !!initialValues.mobileNumber,
    ...propDisabled,
  }), [initialValues, isCustomer, propDisabled]);

  if (!currentUser) return null;

  return (
    <>
      <Row>
        <Col>
          <FormGroupControl
            name="lastName"
            label={t('user.lastName')}
            required={required.lastName}
            disabled={disabled.lastName}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstName"
            label={t('user.firstName')}
            required={required.firstName}
            disabled={disabled.firstName}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="lastNameKm"
            label={t('user.lastNameKm')}
            required={required.lastNameKm}
            disabled={disabled.lastNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstNameKm"
            label={t('user.firstNameKm')}
            required={required.firstNameKm}
            disabled={disabled.firstNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <GenderFormSelect
        name="gender"
        required={required.gender}
        disabled={disabled.gender}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="personalCode"
        label={t('user.personalCode')}
        required={required.personalCode}
        disabled={disabled.personalCode}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        type="date"
        name="dob"
        label={t('user.dob')}
        required={required.dob}
        disabled={disabled.dob}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="occupation"
        label={t('user.occupation')}
        required={required.occupation}
        disabled={disabled.occupation}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="email"
        label={t('user.email')}
        required={required.email}
        disabled={disabled.email}
        groupProps={GROUP_PROPS}
      />

      {isFrontline && (
        <FormGroupCheck
          id="noMobileNumber"
          name="noMobileNumber"
          label={t('user.noMobileNumber')}
          required={required.noMobileNumber}
          disabled={disabled.noMobileNumber}
          groupProps={GROUP_PROPS}
          checked={initialValues.noMobileNumber}
        />
      )}

      <FormGroupControl
        name="mobileNumber"
        label={isFrontline ? t('user.mobileNumberOrUsername') : t('user.mobileNumber')}
        required={required.mobileNumber}
        disabled={disabled.mobileNumber}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="address"
        label={t('user.address')}
        required={required.address}
        disabled={disabled.address}
        as="textarea"
        rows={2}
        groupProps={GROUP_PROPS}
      />
    </>
  );
};

export { UserFormControl };
