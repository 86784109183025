import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { HealthOrder } from '@models/HealthOrder';

export type GetHealthOrderParams = {
  id: number;
};

export type PayHealthOrderParams = GetHealthOrderParams;

export class HealthOrderApi {
  static get = async ({ id }: GetHealthOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/health_orders/${id}`)
      .then((response) => humps(response.data) as HealthOrder);
  };

  static pay = async ({ id }: PayHealthOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/health_orders/${id}/pay`)
      .then((response) => humps(response.data) as HealthOrder);
  };
}
