import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tGender, User } from '@models/User';
import { formatDate } from '@utils/datetime';
import { RowItem, RowItemProps } from '@components/Base';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
};

const OwnerCardBody = ({
  fullName,
  fullNameKm,
  email,
  gender,
  dob,
  personalCode,
  occupation,
  mobileNumber,
  address,
}: User) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carQuoteBuyPage.carQuoteReview.ownerTitle')}</Card.Title>
      <div className="mb-2"><b>{fullName}</b></div>
      <RowItem {...ROW_ITEM_PROPS} label={t('user.fullNameKm')} value={fullNameKm} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.email')} value={email} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.gender')} value={tGender(gender)} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.dob')} value={formatDate(dob)} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.personalCode')} value={personalCode} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.occupation')} value={occupation} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.mobileNumber')} value={mobileNumber} />
      <RowItem {...ROW_ITEM_PROPS} label={t('user.address')} value={address} />
    </Card.Body>
  );
};

export { OwnerCardBody };
