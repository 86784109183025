import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';
import { Formik } from 'formik';

import { Loading, LoadingError } from '@components/Base';
import { useIsRequired } from '@hooks/yup';

import { useCarQuoteFetch } from '../hook';
import { carQuoteUpdate, CarQuoteUpdatePayload } from '../action';
import CarQuoteBuyForm, { FormRequired, FormValues } from '../CarQuoteBuyForm';
import { RightCol } from '../right-col';
import { useInitialValues, useValidationSchema } from './hook';

const CarQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const carQuote = useCarQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const required = useIsRequired<FormRequired>(validationSchema);

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { carOrderId } = carQuote;

  const handleSubmit = (values: FormValues) => {
    const payload: CarQuoteUpdatePayload = { ...values, carOrderId };
    dispatch(carQuoteUpdate(payload));
  };

  return (
    <>
      <Container className="my-3">
        <Row className="gy-5">
          <Col lg={8}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <CarQuoteBuyForm required={required} disabled={{ noMobileNumber: true, activationDate: true }} />
            </Formik>
          </Col>
          <RightCol carQuote={carQuote} />
        </Row>
      </Container>
    </>
  );
};

export { CarQuoteBuyEditPage };
