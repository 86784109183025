import { call, put, takeLatest } from 'redux-saga/effects';

import { HealthOrder } from '@models/HealthOrder';
import { HealthOrderApi } from '@apis/HealthOrderApi';
import { SagaPayload } from '@utils/typescript';

import {
  healthOrderFetch,
  HealthOrderFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleHealthOrderFetch({ payload }: SagaPayload<HealthOrderFetchPayload>) {
  const state: StateUpdatePayload = { healthOrder: null };
  try {
    state.healthOrder = (yield call(HealthOrderApi.get, payload)) as HealthOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(healthOrderFetch, handleHealthOrderFetch);
}
