import { replace } from 'lodash';
import { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';
import { Billing } from '@models/Billing';
import { InsurerList } from '@models/Insurer';
import { InsuranceTypes } from '@models/InsuranceType';
import { User } from './User';
import { formatDateTime } from '@utils/datetime';
import { Quotable, QuotableList } from './Quote';

export enum OrderableTypes {
  Car = 'CarOrder',
  Health = 'HealthOrder',
  PersonalAccident = 'PersonalAccidentOrder',
  Cancer = 'CancerOrder',
  General = 'GeneralOrder',
}

export enum OrderStatuses {
  Pending = 'pending',
  Locked = 'locked',
  Active = 'active',
  Expired = 'expired',
  Ended = 'ended',
}

export type Orderable = QuotableList & Omit<Quotable, 'orderEncryptedId'> & CamelCaseKeys<{
  id: number;
  billing_id: number;
  insurance_type: InsuranceTypes;
  order_number: string;
  cover_scope_title: string;
  policy_schedule?: string;
  coverage_summary?: string;
  coverage_summary_km?: string;
  insurance_certificate?: string;
  insurance_certificate_km?: string;
  invoice?: string;
  invoice_km?: string;
  policy_wording_file?: string;
  policy_wording_file_km?: string;
  claim_procedure_file?: string;
  claim_procedure_file_km?: string;
}>;

export type OrderList = CamelCaseKeys<{
  id: number;
  insurer: InsurerList;
  user: User;
  orderable_id: number;
  orderable_type: OrderableTypes;
  status: OrderStatuses;
  insurance_type: InsuranceTypes;
  cover_scope_title: string;
  policy_number?: string;
  order_number: string;
  renewable: boolean;
  claimable: boolean;
  activation_date?: string;
  started_at?: number;
  ended_at?: number;
}>;

export type Order = OrderList & CamelCaseKeys<{
  policy_schedule?: string;
  coverage_summary?: string;
  coverage_summary_km?: string;
  expiry_date: string;
  created_at: string;
  billing?: Billing;
  insurance_certificate: string;
  insurance_certificate_km: string;
  invoice: string;
  invoice_km: string;
}>;

const ORDER_STATUSES_VARIANTS_MAPPING = {
  [OrderStatuses.Pending]: 'warning',
  [OrderStatuses.Expired]: 'secondary',
  [OrderStatuses.Active]: 'success',
  [OrderStatuses.Ended]: 'secondary',
  [OrderStatuses.Locked]: 'info',
};

export const getOrderStatusVariant = (status: OrderStatuses): string => ORDER_STATUSES_VARIANTS_MAPPING[status];
export const getOrderType = (orderorderableType: OrderableTypes) => replace(orderorderableType, 'Order', '');
export const tOrderStatus = (status: OrderStatuses) => tEnum('orderStatuses', status);
export const dOrderPeriod = (startedAt?: number, endedAt?: number) => startedAt && endedAt ? `${formatDateTime(startedAt)} - ${formatDateTime(endedAt)}` : '';
