import { CancerQuoteResultPageParams } from '@components/CancerQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type CancerQuoteComparePageParams = Required<
  Omit<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
  Pick<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    cancerPlanIds: number[];
  };

export const CANCER_QUOTE_COMPARE_PAGE_URL = '/cancer-insurance-quote-compare';

export const routeCancerQuoteComparePage = (searchParams: CancerQuoteComparePageParams) => getRoute(CANCER_QUOTE_COMPARE_PAGE_URL, { searchParams });
