import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { MGA_PHONE_NUMBER_LINE_1, MGA_PHONE_NUMBER_LINE_2, MGA_SUPPORT_EMAIL, MGA_INFO_EMAIL } from '@config';

const ContactUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTag pageName="contactUsPage" />
      <HeaderPrimary />
      <Container className="py-5">
        <h1 className="mb-5">{t('common:contactUs')}</h1>
        <Card className="mb-5">
          <Card.Body>
            <Card.Title>{t('common:customerService')}</Card.Title>
            <Card.Text>
              <span className="d-block">
                <FontAwesomeIcon icon={faPhoneAlt} className="fa-fw me-2" />
                {t('contactUsPage.callUs')}
              </span>
              <a href={`tel:${MGA_PHONE_NUMBER_LINE_1}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_PHONE_NUMBER_LINE_1}
              </a>
              <a href={`tel:${MGA_PHONE_NUMBER_LINE_2}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_PHONE_NUMBER_LINE_2}
              </a>
              <span className="d-block text-secondary ms-4">{t('contactUsPage.businessHours')}</span>
            </Card.Text>
            <Card.Text>
              <span className="d-block">
                <FontAwesomeIcon icon={faEnvelope} className="fa-fw me-2" />
                {t('contactUsPage.emailUs')}
              </span>
              <a href={`mailto:${MGA_SUPPORT_EMAIL}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_SUPPORT_EMAIL}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="mb-5">
          <Card.Body>
            <Card.Title>{t('contactUsPage.headOffice')}</Card.Title>
            <Card.Text>{t('mga.address')}</Card.Text>
            <Card.Title>{t('contactUsPage.generalInquiry')}</Card.Title>
            <Card.Text>
              <span className="d-block">
                <FontAwesomeIcon icon={faPhoneAlt} className="fa-fw me-2" />
                {t('contactUsPage.callUs')}
              </span>
              <a href={`tel:${MGA_PHONE_NUMBER_LINE_1}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_PHONE_NUMBER_LINE_1}
              </a>
              <a href={`tel:${MGA_PHONE_NUMBER_LINE_2}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_PHONE_NUMBER_LINE_2}
              </a>
              <span className="d-block text-secondary ms-4">{t('contactUsPage.businessHours')}</span>
            </Card.Text>
            <Card.Text>
              <span className="d-block">
                <FontAwesomeIcon icon={faEnvelope} className="fa-fw me-2" />
                {t('contactUsPage.emailUs')}
              </span>
              <a href={`mailto:${MGA_INFO_EMAIL}`} className="fs-2 text-body text-decoration-none ms-4">
                {MGA_INFO_EMAIL}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container >
      <Footer />
    </>
  );
};

export { ContactUsPage };
