import { FindCarQuoteParams } from '@apis/CarQuoteApi';
import { getRoute } from '@utils/route';

export const CAR_QUOTE_BUY_ROUTE_URL = '/car-insurance-quote-buy/*';
export const CAR_QUOTE_BUY_NEW_PAGE_URL = '/car-insurance-quote-buy';
export const CAR_QUOTE_BUY_EDIT_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/edit';
export const CAR_QUOTE_BUY_REVIEW_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/review';
export const CAR_QUOTE_BUY_RESET_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/reset';
export const CAR_QUOTE_BUY_CUSTOM_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/custom';
export const CAR_QUOTE_BUY_LOCK_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/lock';
export const CAR_QUOTE_BUY_PAY_BY_CASH_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/pay-by-cash';

export type CarQuoteBuyNewPageParams = FindCarQuoteParams;

type RouteParams = {
  carOrderId: number;
};

export const routeCarQuoteBuyNewPage = (searchParams: CarQuoteBuyNewPageParams) => getRoute(CAR_QUOTE_BUY_NEW_PAGE_URL, { searchParams });
export const routeCarQuoteBuyEditPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_EDIT_PAGE_URL, { routeParams });
export const routeCarQuoteBuyReviewPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_REVIEW_PAGE_URL, { routeParams });
export const routeCarQuoteBuyResetPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_RESET_PAGE_URL, { routeParams });
export const routeCarQuoteBuyCustomPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_CUSTOM_PAGE_URL, { routeParams });
export const routeCarQuoteBuyLockPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_LOCK_PAGE_URL, { routeParams });
export const routeCarQuoteBuyPayByCashPage = (routeParams: RouteParams) => getRoute(CAR_QUOTE_BUY_PAY_BY_CASH_PAGE_URL, { routeParams });
