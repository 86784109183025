import { call, put, takeLatest } from 'redux-saga/effects';

import { CancerOrder } from '@models/CancerOrder';
import { CancerOrderApi } from '@apis/CancerOrderApi';
import { SagaPayload } from '@utils/typescript';

import {
  cancerOrderFetch,
  CancerOrderFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCancerOrderFetch({ payload }: SagaPayload<CancerOrderFetchPayload>) {
  const state: StateUpdatePayload = { cancerOrder: null };
  try {
    state.cancerOrder = (yield call(CancerOrderApi.get, payload)) as CancerOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(cancerOrderFetch, handleCancerOrderFetch);
}
