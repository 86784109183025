import { object, number } from 'yup';

import { cancerQuoteResultPageParamsSchema } from '@components/CancerQuoteResultPage/schema';

export const cancerQuoteDetailsPageParamsSchema = cancerQuoteResultPageParamsSchema.concat(
  object({
    cancerPlanId: number(),
  }),
);

export const cancerQuoteSendEmailPageParamsSchema = cancerQuoteDetailsPageParamsSchema;
