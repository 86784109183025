import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';

import { companiesFetch } from './action';
import { selectCompany } from './selector';

export const useCompaniesFetch = () => {
  const dispatch = useDispatch();
  const { companies } = useSelector(selectCompany);

  useEffectOnce(() => {
    if (!isEmpty(companies)) return;
    dispatch(companiesFetch());
  });

  return companies;
};
