import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert, Container, Row, Col, Card } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { isUndefined, isNull } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FormGroupCheck, Loading, LoadingError } from '@components/Base';
import HeaderSecondary from '@components/HeaderSecondary';
import AbaPayway from '@components/AbaPayway';
import FakePayment from '@components/FakePayment';
import i18n from '@components/i18n';
import { CarQuotePremiumCardBody } from '@components/Feature/CarQuote';
import { PaymentMethodToggleControl } from '@components/Feature/PaymentMethod';
import { PaymentMethods } from '@models/PaymentMethod';
import { routePrivacyPolicyPage } from '@components/PrivacyPolicyPage';
import { routeTermsAndConditionsPage } from '@components/TermsAndConditionsPage';

import { paymentTransactionCreate, PaymentTransactionCreatePayload } from './action';
import { useBillingFetch, useCarOrderFetch } from './hook';
import { selectCarOrderPayment } from './selector';

type FormValues = Pick<PaymentTransactionCreatePayload, 'agreeToTermsAndConditions' | 'paymentMethod'>;

const validationSchema = Yup.object({
  agreeToTermsAndConditions: Yup.boolean().test(
    'required',
    i18n.t('validation:required'),
    (checked) => checked === true,
  ),
});

const initialValues: FormValues = {
  agreeToTermsAndConditions: false,
  paymentMethod: PaymentMethods.AbaPay,
};

const CarOrderPaymentPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const carOrder = useCarOrderFetch();
  const billing = useBillingFetch();
  const { paymentTransaction } = useSelector(selectCarOrderPayment);
  const [bypass, setBypass] = useState(false);
  const PaymentGateway = bypass ? FakePayment : AbaPayway;

  if (isUndefined(carOrder) || isUndefined(billing)) return <Loading />;
  if (isNull(carOrder) || isNull(billing)) return <LoadingError />;

  const { referredVehicleUsageHtml, insurer } = carOrder;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: PaymentTransactionCreatePayload = { billingId: billing.id, ...values };
    dispatch(paymentTransactionCreate(payload));
    setSubmitting(false);
  };

  return (
    <>
      <HeaderSecondary />
      <Container className="my-3">
        <Row>
          <Col lg={8} className="mx-auto">
            <Card>
              <CarQuotePremiumCardBody {...carOrder} />

              <Card.Body>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  validateOnMount
                >
                  {({ isValid }) => (
                    <Form>
                      <Card.Title>{t('paymentOption.title')}</Card.Title>
                      <Row xs={{ cols: 1 }} lg={{ cols: 2 }} className="gy-3 gy-lg-0 mb-5">
                        <Col>
                          <PaymentMethodToggleControl value={PaymentMethods.AbaPay} />
                        </Col>
                        <Col>
                          <PaymentMethodToggleControl value={PaymentMethods.Bakong} />
                        </Col>
                      </Row>

                      <FormGroupCheck
                        groupProps={{ className: 'mb-5' }}
                        name="agreeToTermsAndConditions"
                        label={
                          <Trans
                            i18nKey="iAgreeToTermAndPolicy"
                            t={t}
                            components={{
                              term: <Link to={routeTermsAndConditionsPage()} target="_blank" rel="noreferrer" />,
                              policy: <Link to={routePrivacyPolicyPage()} target="_blank" rel="noreferrer" />,
                            }}
                          />
                        }
                      />

                      {process.env.RAILS_ENV !== 'production' && (
                        <FormGroupCheck
                          groupProps={{ className: 'mb-5' }}
                          id="bypass"
                          name="bypass"
                          label="Bypass payment"
                          onChange={() => setBypass(true)}
                        />
                      )}

                      <Button type="submit" variant="primary" className="btn-lg w-100" disabled={!isValid}>
                        <span className="me-2">{t('common:paySecurely')}</span>
                        <FontAwesomeIcon icon={faArrowRight} className="fa-fw" />
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>

              <Card.Body>
                <Card.Title className="text-danger">{t('common:declaration')}</Card.Title>
                <Alert variant="danger" className="mb-0">
                  {parse(
                    referredVehicleUsageHtml ??
                    t('carQuotePaymentPage.referredVehicleUsageNotSet', {
                      insurerName: insurer.name,
                    }),
                  )}
                </Alert>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {paymentTransaction && <PaymentGateway paymentTransactionId={paymentTransaction.id} />}
    </>
  );
};

export { CarOrderPaymentPage };
