import { createActions } from 'redux-actions';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PayPersonalAccidentOrderParams } from '@apis/PersonalAccidentOrderApi';
import { CreatePersonalAccidentQuoteParams, CustomPersonalAccidentQuoteParams, FindPersonalAccidentQuoteParams, GetPersonalAccidentQuoteParams, UpdatePersonalAccidentQuoteParams } from '@apis/PersonalAccidentQuoteApi';

export type State = {
  personalAccidentQuote?: PersonalAccidentQuote | null;
};

export type PersonalAccidentQuoteFindPayload = FindPersonalAccidentQuoteParams;
export type PersonalAccidentQuoteCreatePayload = CreatePersonalAccidentQuoteParams;
export type PersonalAccidentQuoteFetchPayload = GetPersonalAccidentQuoteParams;
export type PersonalAccidentQuoteUpdatePayload = UpdatePersonalAccidentQuoteParams;
export type PersonalAccidentQuoteCustomPayload = CustomPersonalAccidentQuoteParams;
export type PersonalAccidentOrderPayPayload = PayPersonalAccidentOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentQuoteFind,
  personalAccidentQuoteCreate,
  personalAccidentQuoteFetch,
  personalAccidentQuoteUpdate,
  personalAccidentQuoteCustom,
  personalAccidentOrderPay,
  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_QUOTE_FIND',
  'PERSONAL_ACCIDENT_QUOTE_CREATE',
  'PERSONAL_ACCIDENT_QUOTE_FETCH',
  'PERSONAL_ACCIDENT_QUOTE_UPDATE',
  'PERSONAL_ACCIDENT_QUOTE_CUSTOM',
  'PERSONAL_ACCIDENT_ORDER_PAY',
  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE',
  },
);
