import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Loading, LoadingError } from '@components/Base';
import { GeneralQuoteDescriptionCardBody } from '@components/Feature/GeneralQuote';

import { usegeneralOrderFetch } from './hook';
import { OrderableCard } from '../orderable-card';

const GeneralPolicyDetails = () => {
  const generalOrder = usegeneralOrderFetch();

  if (isUndefined(generalOrder)) return <Loading />;
  if (isNull(generalOrder)) return <LoadingError />;

  return (
    <OrderableCard {...generalOrder}>
      <GeneralQuoteDescriptionCardBody {...generalOrder} />
    </OrderableCard>
  );
};

export { GeneralPolicyDetails };
