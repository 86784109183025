import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

import store from '@store';
import { history } from '@utils/history';

import './i18n';
import { currentUserFetch } from './Auth';
import MetaTag from './MetaTag';
import ProtectedRoute from './ProtectedRoute';
import ToastProvider from './ToastProvider';

import HomePage from './HomePage';
import ThankYouPage, { THANK_YOU_PAGE_URL } from './ThankYouPage';
import ThankYouBetaPage, { THANK_YOU_BETA_PAGE_URL } from './ThankYouBetaPage';
import UserRegistration from './UserRegistration';
import UserDashboardRoute, { USER_DASHBOARD_ROUTE_URL } from './UserDashboardRoute';
import CamDxPage, { CAM_DX_PAGE_URL } from './CamDxPage';
import AboutUsPage, { ABOUT_US_PAGE_URL } from './AboutUsPage';
import ContactUsPage, { CONTACT_US_PAGE_URL } from './ContactUsPage';
import PrivacyPolicyPage, { PRIVACY_POLICY_PAGE_URL } from './PrivacyPolicyPage';
import TermsAndConditionsPage, { TERMS_AND_CONDITIONS_PAGE_URL } from './TermsAndConditionsPage';
import CancellationAndRefundPage, { CANCELLATION_AND_REFUND_PAGE_URL } from './CancellationAndRefundPage';
import CorporateInsurancePage, { CORPORATE_INSURANCE_PAGE_URL } from './CorporateInsurancePage';
import ArticlePage, { ARTICLE_INSURANCE_TYPE_PAGE_URL, ARTICLE_PAGE_URL } from './ArticlePage';
import ArticleDetailsPage, { ARTICAL_DETAILS_PAGE_URL } from './ArticleDetailsPage';
import NotFoundPage from './NotFoundPage';

import CarInsurancePage, { CAR_INSURANCE_PAGE_URL } from './CarInsurancePage';
import CarQuotePage, { CAR_QUOTE_ROUTE_URL } from './CarQuotePage';
import CarQuoteResultPage, { CAR_QUOTE_RESULT_PAGE_URL } from './CarQuoteResultPage';
import CarQuoteComparePage, { CAR_QUOTE_COMPARE_PAGE_URL } from './CarQuoteComparePage';
import CarQuoteDetailsRoute, { CAR_QUOTE_DETAILS_ROUTE_URL } from './CarQuoteDetailsRoute';
import CarQuoteBuyRoute, { CAR_QUOTE_BUY_ROUTE_URL } from './CarQuoteBuyRoute';
import CarOrderPaymentPage, { CAR_ORDER_PAYMENT_PAGE_URL } from './CarOrderPaymentPage';

import HealthInsurancePage, { HEALTH_INSURANCE_PAGE_URL } from './HealthInsurancePage';
import HealthQuotePage, { CANCER_QUOTE_ROUTE_URL, HEALTH_QUOTE_ROUTE_URL, PERSONAL_ACCIDENT_QUOTE_ROUTE_URL } from './HealthQuotePage';
import HealthQuoteResultPage, { HEALTH_QUOTE_RESULT_PAGE_URL } from './HealthQuoteResultPage';
import HealthQuoteComparePage, { HEALTH_QUOTE_COMPARE_PAGE_URL } from './HealthQuoteComparePage';
import HealthQuoteDetailsRoute, { HEALTH_QUOTE_DETAILS_ROUTE_URL } from './HealthQuoteDetailsRoute';
import HealthQuoteBuyRoute, { HEALTH_QUOTE_BUY_ROUTE_URL } from './HealthQuoteBuyRoute';
import HealthOrderPaymentPage, { HEALTH_ORDER_PAYMENT_PAGE_URL } from './HealthOrderPaymentPage';

import PersonalAccidentInsurancePage, { PERSONAL_ACCIDENT_INSURANCE_PAGE_URL } from './PersonalAccidentInsurancePage';
import PersonalAccidentQuoteResultPage, { PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL } from './PersonalAccidentQuoteResultPage';
import PersonalAccidentQuoteComparePage, { PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL } from './PersonalAccidentQuoteComparePage';
import PersonalAccidentQuoteDetailsRoute, { PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL } from './PersonalAccidentQuoteDetailsRoute';
import PersonalAccidentQuoteBuyRoute, { PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL } from './PersonalAccidentQuoteBuyRoute';
import PersonalAccidentOrderPaymentPage, { PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL } from './PersonalAccidentOrderPaymentPage';

import CancerInsurancePage, { CANCER_INSURANCE_PAGE_URL } from './CancerInsurancePage';
import CancerQuoteResultPage, { CANCER_QUOTE_RESULT_PAGE_URL } from './CancerQuoteResultPage';
import CancerQuoteDetailsRoute, { CANCER_QUOTE_DETAILS_ROUTE_URL } from './CancerQuoteDetailsRoute';
import CancerQuoteBuyRoute, { CANCER_QUOTE_BUY_ROUTE_URL } from './CancerQuoteBuyRoute';
import CancerQuoteComparePage, { CANCER_QUOTE_COMPARE_PAGE_URL } from './CancerQuoteComparePage';
import CancerOrderPaymentPage, { CANCER_ORDER_PAYMENT_PAGE_URL } from './CancerOrderPaymentPage';

import GeneralQuoteBuyRoute, { GENERAL_QUOTE_BUY_ROUTE_URL } from './GeneralQuoteBuyRoute';
import GeneralOrderPaymentPage, { GENERAL_ORDER_PAYMENT_PAGE_URL } from './GeneralOrderPaymentPage';


const App = () => {
  const dispatch = useDispatch();
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  useEffectOnce(() => {
    if (isLoggedIn()) {
      dispatch(currentUserFetch());
    }
  });

  return (
    <Routes>
      <Route index element={<HomePage />} />

      <Route path={CAR_QUOTE_ROUTE_URL} element={<CarQuotePage />} />
      <Route path={CAR_QUOTE_RESULT_PAGE_URL} element={<CarQuoteResultPage />} />
      <Route path={CAR_QUOTE_COMPARE_PAGE_URL} element={<CarQuoteComparePage />} />
      <Route path={CAR_QUOTE_DETAILS_ROUTE_URL} element={<CarQuoteDetailsRoute />} />
      <Route path={CAR_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<CarQuoteBuyRoute />} />} />
      <Route path={CAR_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<CarOrderPaymentPage />} />} />

      <Route path={HEALTH_QUOTE_ROUTE_URL} element={<HealthQuotePage />} />
      <Route path={HEALTH_QUOTE_RESULT_PAGE_URL} element={<HealthQuoteResultPage />} />
      <Route path={HEALTH_QUOTE_COMPARE_PAGE_URL} element={<HealthQuoteComparePage />} />
      <Route path={HEALTH_QUOTE_DETAILS_ROUTE_URL} element={<HealthQuoteDetailsRoute />} />
      <Route path={HEALTH_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<HealthQuoteBuyRoute />} />} />
      <Route path={HEALTH_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<HealthOrderPaymentPage />} />} />

      <Route path={PERSONAL_ACCIDENT_QUOTE_ROUTE_URL} element={<HealthQuotePage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL} element={<PersonalAccidentQuoteResultPage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL} element={<PersonalAccidentQuoteComparePage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL} element={<PersonalAccidentQuoteDetailsRoute />} />
      <Route
        path={PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL}
        element={<ProtectedRoute element={<PersonalAccidentQuoteBuyRoute />} />}
      />
      <Route
        path={PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL}
        element={<ProtectedRoute element={<PersonalAccidentOrderPaymentPage />} />}
      />

      <Route path={CANCER_QUOTE_ROUTE_URL} element={<HealthQuotePage />} />
      <Route path={CANCER_QUOTE_RESULT_PAGE_URL} element={<CancerQuoteResultPage />} />
      <Route path={CANCER_QUOTE_DETAILS_ROUTE_URL} element={<CancerQuoteDetailsRoute />} />
      <Route path={CANCER_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<CancerQuoteBuyRoute />} />} />
      <Route path={CANCER_QUOTE_COMPARE_PAGE_URL} element={<CancerQuoteComparePage />} />
      <Route path={CANCER_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<CancerOrderPaymentPage />} />} />

      <Route path={GENERAL_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<GeneralQuoteBuyRoute />} />} />
      <Route path={GENERAL_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<GeneralOrderPaymentPage />} />} />

      <Route path={ARTICLE_PAGE_URL} element={<ArticlePage />} />
      <Route path={ARTICLE_INSURANCE_TYPE_PAGE_URL} element={<ArticlePage />} />
      <Route path={ARTICAL_DETAILS_PAGE_URL} element={<ArticleDetailsPage />} />
      <Route path={THANK_YOU_PAGE_URL} element={<ThankYouPage />} />
      <Route path={THANK_YOU_BETA_PAGE_URL} element={<ThankYouBetaPage />} />
      <Route path="/sign-in/*" element={<UserRegistration />} />
      <Route path={USER_DASHBOARD_ROUTE_URL} element={<ProtectedRoute element={<UserDashboardRoute />} />} />
      <Route path={CAM_DX_PAGE_URL} element={<CamDxPage />} />
      <Route path={CAR_INSURANCE_PAGE_URL} element={<CarInsurancePage />} />
      <Route path={HEALTH_INSURANCE_PAGE_URL} element={<HealthInsurancePage />} />
      <Route path={PERSONAL_ACCIDENT_INSURANCE_PAGE_URL} element={<PersonalAccidentInsurancePage />} />
      <Route path={CANCER_INSURANCE_PAGE_URL} element={<CancerInsurancePage />} />
      <Route path={CORPORATE_INSURANCE_PAGE_URL} element={<CorporateInsurancePage />} />
      <Route path={ABOUT_US_PAGE_URL} element={<AboutUsPage />} />
      <Route path={CONTACT_US_PAGE_URL} element={<ContactUsPage />} />
      <Route path={PRIVACY_POLICY_PAGE_URL} element={<PrivacyPolicyPage />} />
      <Route path={TERMS_AND_CONDITIONS_PAGE_URL} element={<TermsAndConditionsPage />} />
      <Route path={CANCELLATION_AND_REFUND_PAGE_URL} element={<CancellationAndRefundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

// Scroll-to-top component
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Trigger on route change

  return null;
};

const Container = () => {
  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <BrowserRouter basename={`/${i18n.language}`}>
        <ScrollToTop />
        <MetaTag pageName="home" />
        <App />
        <ToastProvider />
      </BrowserRouter>
    </Provider>
  );
};

export default Container;
