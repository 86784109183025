import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number, array, string } from 'yup';

import { mergeInitialValues } from '@utils/formik';
import { yupFile } from '@utils/yup';
import { selectPersonalAccidentQuoteBuyRoute } from '../selector';
import { FormValues } from './type';

export const useInitialValues = () => {
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);
  const initialValues: FormValues = {
    me: {
      chargeAmount: ''
    },
    spouse: undefined,
    daughters: [],
    sons: [],
    note: '',
    proof: undefined,
  };

  return useMemo(() => {
    if (!personalAccidentQuote) return null;
    return mergeInitialValues(initialValues, { ...personalAccidentQuote, ...personalAccidentQuote.people });
  }, [personalAccidentQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    proof: yupFile().optional(),
    note: string().required(t('validation:required')),
    me: object({ chargeAmount: number().required(t('validation:required')) }),
    spouse: object({ chargeAmount: number().optional().nullable(true) }),
    sons: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
    daughters: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
  });
};
