import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BackButton, CopyLinkButton } from '@components/Base';
import HeaderSecondary from '@components/HeaderSecondary';
import { useCancerQuoteResultPageParams, routeCancerQuoteResultPage } from '@components/CancerQuoteResultPage';

import { useCancerQuotesFetch } from './hook';
import { TableInsurer } from './table-insurer';
import { TableAnnualLimit } from './table-annual-limit';
import { TableGeographicCoverage } from './table-geographic-coverage';
import { TableBenefit } from './table-benefit';

const CancerQuoteComparePage = () => {
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const cancerQuotes = useCancerQuotesFetch();

  const handleBack = () => {
    navigate(routeCancerQuoteResultPage(params), { replace: true });
  };

  if (!cancerQuotes) return null;

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <BackButton onClick={handleBack} />
          <CopyLinkButton />
        </div>

        <Table>
          <tbody>
            <TableInsurer />
            <TableAnnualLimit />
            <TableBenefit />
            <TableGeographicCoverage />
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export { CancerQuoteComparePage };
