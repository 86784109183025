import { HealthQuoteResultPageParams } from '@components/HealthQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type HealthQuoteDetailsPageParams = Required<Omit<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>> &
  Pick<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    healthPlanId: number;
  };

export type HealthQuoteSendEmailPageParams = HealthQuoteDetailsPageParams;

export const HEALTH_QUOTE_DETAILS_ROUTE_URL = '/health-insurance-quote-details/*';
export const HEALTH_QUOTE_DETAILS_PAGE_URL = '/health-insurance-quote-details';
export const HEALTH_QUOTE_SEND_MAIL_PAGE = '/health-insurance-quote-details/send-email';

export const routeHealthQuoteDetailsPage = (searchParams: HealthQuoteDetailsPageParams) => getRoute(HEALTH_QUOTE_DETAILS_PAGE_URL, { searchParams });
export const routeHealthQuoteSendEmailPage = (searchParams: HealthQuoteSendEmailPageParams) => getRoute(HEALTH_QUOTE_SEND_MAIL_PAGE, { searchParams });
