import { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';
import { FuelTypes } from './Car';

export enum UserCarPhotos {
  Type = 'UserCar',
  Name = 'photos',
  Limit = 4,
}

export enum UserCarStatuses {
  Unverified = 'unverified',
  Reviewing = 'reviewing',
  Rejected = 'rejected',
  Verified = 'verified',
}

export type UserCar = CamelCaseKeys<{
  id: number;
  name: string;
  car_brand_name: string;
  car_model_name: string;
  car_sub_model_name: string;
  engine_capacity: number;
  manufactured_year: number;
  fuel_type: FuelTypes;
  status: UserCarStatuses;
  registration_place: string;
  registration_number: string;
  chassis_number: string;
  engine_number: string;
  identification_card?: string;
  photos: string[];
  rejected_reason: string | null;
}>;

const USER_CAR_STATUSES_VARIANTS_MAPPING = {
  [UserCarStatuses.Unverified]: 'warning',
  [UserCarStatuses.Reviewing]: 'info',
  [UserCarStatuses.Rejected]: 'danger',
  [UserCarStatuses.Verified]: 'success',
};

export const getUserCarStatusVariant = (status: UserCarStatuses) => USER_CAR_STATUSES_VARIANTS_MAPPING[status];
export const tUserCarStatus = (status: UserCarStatuses) => tEnum('userCarStatuses', status);
