import { createActions } from 'redux-actions';

import { GetGeneralOrderParams } from '@apis/GeneralOrderApi';
import { GeneralOrder } from '@models/GeneralOrder';

export type State = {
  generalOrder?: GeneralOrder | null;
};

export type GeneralOrderFetchPayload = GetGeneralOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  generalOrderFetch,
  stateUpdate,
} = createActions(
  'GENERAL_ORDER_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_GENERAL_POLICY_DETAILS',
  },
);
