import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Article } from '@models/Article';
import { InsuranceTypes, tInsuranceType } from '@models/InsuranceType';
import { routeArticlePage } from '@components/ArticlePage';
import { useRouteParam } from '@hooks/params';

const ArticleBreadcrumb = ({ article }: { article?: Article }) => {
  const { t } = useTranslation();
  const insuranceType = useRouteParam<InsuranceTypes>('insuranceType', true);

  return (
    <Breadcrumb className="mt-5">
      <Breadcrumb.Item to={'/'}>
        {t('common:home')}
      </Breadcrumb.Item>
      <Breadcrumb.Item as={Link} linkAs="span" to={routeArticlePage()} active={!insuranceType}>
        {t('common:articles')}
      </Breadcrumb.Item>
      {insuranceType && (
        <Breadcrumb.Item as={Link} linkAs="span" to={routeArticlePage({ insuranceType })} active={!article}>
          {tInsuranceType(insuranceType)}
        </Breadcrumb.Item>
      )}
      {article && <Breadcrumb.Item active>{article.title}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};

export { ArticleBreadcrumb };
