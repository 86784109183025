import { createActions } from 'redux-actions';

import {
  CreateCarQuoteParams,
  GetCarQuoteParams,
  FindCarQuoteParams,
  UpdateCarQuoteParams,
  ResetCarQuoteParams,
  CustomCarQuoteParams,
  LockCarQuoteParams,
  PayByCashCarQuoteParams,
} from '@apis/CarQuoteApi';
import { PayCarOrderParams } from '@apis/CarOrderApi';
import { CarQuote } from '@models/CarQuote';

export type State = {
  carQuote?: CarQuote | null;
};

export type CarQuoteFindPayload = FindCarQuoteParams;
export type CarQuoteFetchPayload = GetCarQuoteParams;
export type CarQuoteCreatePayload = CreateCarQuoteParams;
export type CarQuoteUpdatePayload = UpdateCarQuoteParams;
export type CarQuoteResetPayload = ResetCarQuoteParams;
export type CarQuoteCustomPayload = CustomCarQuoteParams;
export type CarQuoteLockPayload = LockCarQuoteParams;
export type CarQuotePayByCashPayload = PayByCashCarQuoteParams;
export type CarOrderPayPayload = PayCarOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carQuoteFind,
  carQuoteFetch,
  carQuoteCreate,
  carQuoteUpdate,
  carQuoteReset,
  carQuoteCustom,
  carQuoteLock,
  carQuotePayByCash,
  carOrderPay,
  stateUpdate,
} = createActions(
  'CAR_QUOTE_FIND',
  'CAR_QUOTE_FETCH',
  'CAR_QUOTE_CREATE',
  'CAR_QUOTE_UPDATE',
  'CAR_QUOTE_RESET',
  'CAR_QUOTE_CUSTOM',
  'CAR_QUOTE_LOCK',
  'CAR_QUOTE_PAY_BY_CASH',
  'CAR_ORDER_PAY',
  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_BUY_ROUTE',
  },
);
