import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from '@utils/history';
import { GeneralQuote } from '@models/GeneralQuote';
import { GeneralQuoteApi } from '@apis/GeneralQuoteApi';
import { SagaPayload } from '@utils/typescript';

import {
  generalQuoteCreate,
  GeneralQuoteCreatePayload,
  generalQuoteFetch,
  GeneralQuoteFetchPayload,
  generalQuoteUpdate,
  GeneralQuoteUpdatePayload,
  generalQuoteLock,
  GeneralQuoteLockPayload,
  generalOrderPay,
  GeneralOrderPayPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { routeGeneralQuoteBuyReviewPage } from './route';
import { GeneralOrderApi } from '@apis/GeneralOrderApi';
import { GeneralOrder } from '@models/GeneralOrder';
import { routeGeneralOrderPaymentPage } from '@components/GeneralOrderPaymentPage';

function* handleGeneralQuoteCreate({ payload }: SagaPayload<GeneralQuoteCreatePayload>) {
  const state: StateUpdatePayload = { generalQuote: null };
  try {
    state.generalQuote = (yield call(GeneralQuoteApi.create, payload)) as GeneralQuote;
    yield put(stateUpdate(state));
    history.navigate(routeGeneralQuoteBuyReviewPage({ generalOrderId: state.generalQuote.generalOrderId }));
  } catch { }
}

function* handleGeneralQuoteFetch({ payload }: SagaPayload<GeneralQuoteFetchPayload>) {
  const state: StateUpdatePayload = { generalQuote: null };
  try {
    state.generalQuote = (yield call(GeneralQuoteApi.get, payload)) as GeneralQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleGeneralQuoteUpdate({ payload }: SagaPayload<GeneralQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { generalQuote: null };
  try {
    state.generalQuote = (yield call(GeneralQuoteApi.update, payload)) as GeneralQuote;
    yield put(stateUpdate(state));
    history.navigate(routeGeneralQuoteBuyReviewPage({ generalOrderId: state.generalQuote.generalOrderId }));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleGeneralQuoteLock({ payload }: SagaPayload<GeneralQuoteLockPayload>) {
  const state: StateUpdatePayload = { generalQuote: null };
  try {
    state.generalQuote = (yield call(GeneralQuoteApi.lock, payload)) as GeneralQuote;
    yield put(stateUpdate(state));
    history.navigate(routeGeneralQuoteBuyReviewPage({ generalOrderId: state.generalQuote.generalOrderId }));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleGeneralOrderPay({ payload }: SagaPayload<GeneralOrderPayPayload>) {
  try {
    const { id, billingId } = (yield call(GeneralOrderApi.pay, payload)) as GeneralOrder;
    history.navigate(routeGeneralOrderPaymentPage({ generalOrderId: id, billingId }));
  } catch {
  }
}

export default function* () {
  yield takeLatest(generalQuoteCreate, handleGeneralQuoteCreate);
  yield takeLatest(generalQuoteFetch, handleGeneralQuoteFetch);
  yield takeLatest(generalQuoteUpdate, handleGeneralQuoteUpdate);
  yield takeLatest(generalQuoteLock, handleGeneralQuoteLock);
  yield takeLatest(generalOrderPay, handleGeneralOrderPay);
}
