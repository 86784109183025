import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';

import PersonalAccidentQuoteBuyForm from '../PersonalAccidentQuoteBuyForm';
import { FormValues } from '../PersonalAccidentQuoteBuyForm/schema';
import { usePersonalAccidentQuoteBuyNewPageParams, usePersonalAccidentQuoteFind } from '../hook';
import { useInitialValues } from './hook';
import { personalAccidentQuoteCreate, PersonalAccidentQuoteCreatePayload } from '../action';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';

const PersonalAccidentQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = usePersonalAccidentQuoteBuyNewPageParams()
  const personalAccidentQuote = usePersonalAccidentQuoteFind(params);
  useCurrentUser();
  const initialValues = useInitialValues();

  if (isUndefined(personalAccidentQuote)) return <Loading />;
  if (isNull(personalAccidentQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const handleSubmit = (values: FormValues) => {
    const payload: PersonalAccidentQuoteCreatePayload = {
      ...values,
      personalAccidentPlanId: personalAccidentQuote.personalAccidentPlanId,
    };

    dispatch(personalAccidentQuoteCreate(payload));
  };

  return (
    <Container className="my-5">
      <Row>
        <h2 className="mb-3">{t('personalAccidentQuoteBuyPage.title')}</h2>
        <p>{t('personalAccidentQuoteBuyPage.subTitle')}</p>
        <Col lg={8}>
          <PersonalAccidentQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>
        <RightCol {...personalAccidentQuote} planName={personalAccidentQuote.personalAccidentPlanName} />
      </Row>
    </Container>
  );
};

export { PersonalAccidentQuoteBuyNewPage };
