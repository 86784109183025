import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { selectIsFrontline } from '@components/Auth';
import { ActivationDateControl } from '@components/Feature/Quote';
import { FormGroupSelect } from '@components/Base';
import { useCompaniesFetch } from '@components/Feature/Company';
import { FormDisabled, FormRequired } from './type';

const GROUP_PROPS = { className: 'mb-3' };

type Props = {
  disabled?: FormDisabled,
  required: FormRequired,
};

const OrderCardBody = ({ disabled, required }: Props) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const companies = useCompaniesFetch();

  if (!isFrontline) return null;

  return (
    <Card.Body>
      <Card.Title>{t('common:fillPolicyDetails')}</Card.Title>

      <ActivationDateControl
        disabled={disabled?.activationDate}
        required={required.activationDate} />

      <FormGroupSelect
        name="companyId"
        label={t('common:company')}
        disabled={disabled?.companyId}
        required={required.companyId}
        groupProps={GROUP_PROPS}
      >
        <option></option>
        {map(companies, ({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </FormGroupSelect>
    </Card.Body>
  );
};

export { OrderCardBody };
