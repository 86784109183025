import { call, put, takeLatest } from 'redux-saga/effects';

import { ProvinceApi } from '@apis/ProvinceApi';

import {
  provincesFetch,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleProvincesFetch() {
  const state: StateUpdatePayload = { provinces: [] };
  try {
    state.provinces = (yield call(ProvinceApi.getAll)) as string[];
    yield put(stateUpdate(state));
  } catch { }
}

export default function* () {
  yield takeLatest(provincesFetch, handleProvincesFetch);
}
