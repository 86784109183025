import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Pagination, Loading, LoadingError } from '@components/Base';
import { isPaginated } from '@models/Pagination';
import { useParamsPage } from '@hooks/params';

import { billingsFetch, BillingsFetchPayload } from './action';
import { selectBilling } from './selector';
import { BillingList } from './billing-list';

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useParamsPage();
  const { billings, pagination } = useSelector(selectBilling);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const payload: BillingsFetchPayload = { page };
    dispatch(billingsFetch(payload));
  }, []);

  useEffect(() => {
    if (isUndefined(billings)) return;
    setIsLoading(false);
  }, [billings]);

  if (isNull(billings)) return <LoadingError />;

  return (
    <section>
      <h5 className="mb-4">{t('common:billing')}</h5>
      <Card>
        <Card.Body>
          <div className="mb-4">
            {isLoading ? <Loading /> : <BillingList />}
          </div>
          {isPaginated(pagination) && (
            <div className="d-flex justify-content-center">
              <br />
              <Pagination {...pagination} />
            </div>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export { Billing };
