import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { map } from 'lodash';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BackButton, ClaimBadge } from '@components/Base';
import { formatDateTime } from '@utils/datetime';
import { amountToUsd } from '@utils/currency';
import { getAttachmentType, isAttachmentImage, isAttachmentPdf } from '@utils/attachment';
import { tCarClaimType } from '@models/CarClaim';

import { selectCarClaim } from './selector';
import { carClaimFetch } from './action';
interface RowItemProps {
  label: string;
  value?: string | number | ReactElement | null;
  list?: string[];
  renderItem?: (item: string) => void;
}

const RowItem = ({ label, value, list, renderItem }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={4} sm={4} md={3}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>
        <>
          {value && value}
          {list && renderItem && map(list, renderItem)}
        </>
      </Col>
    </Row>
  );
};

interface ClaimAttachmentItemProps {
  name: string;
  url: string;
}

const ClaimAttachmentItem = ({ name, url }: ClaimAttachmentItemProps) => {
  const { t } = useTranslation();
  const attachmentType = getAttachmentType(url);

  return (
    <>
      {isAttachmentImage(attachmentType) && (
        <Image src={url} thumbnail className="mb-3" data-testid={`${name}-image`} />
      )}

      {isAttachmentPdf(attachmentType) && (
        <Link to={url} className="d-block mb-3" target="_blank" rel="noreferrer" data-testid={`${name}-pdf`}>
          {t('common:clickToViewDetails')}
        </Link>
      )}
    </>
  );
};

const CarClaimDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { claimableId } = useParams();
  const { carClaim } = useSelector(selectCarClaim);

  useEffect(() => {
    dispatch(carClaimFetch({ carClaimId: claimableId }));
  }, [claimableId]);

  return (
    <>
      <BackButton className="mb-4" />
      {carClaim && (
        <Card>
          <Card.Header>{t('details', { ns: 'common' })}</Card.Header>

          <Card.Body>
            <RowItem label={t('common:status')} value={<ClaimBadge status={carClaim.status} />} />
            <RowItem
              label={t('carInsuranceClaimForm.accidentHappenedAt.label')}
              value={formatDateTime(carClaim.accidentHappenedAt)}
            />
            <RowItem
              label={t('carInsuranceClaim.accidentPhotos')}
              list={carClaim.accidentPhotos}
              renderItem={(url) => <ClaimAttachmentItem key={url} name="accident-photos" url={url} />}
            />
            <RowItem
              label={pluralize(t('claimForm.claimType.label'), 2)}
              list={carClaim.claimTypes}
              renderItem={(claimType) => <span className="d-block">- {tCarClaimType(claimType)}</span>}
            />
            <RowItem
              label={t('carInsuranceClaim.policeReport')}
              list={carClaim.passengersAndThirdPartiesPoliceReports}
              renderItem={(url) => <ClaimAttachmentItem key={url} name="police-reports" url={url} />}
            />
            <RowItem
              label={t('carInsuranceClaim.completedClaimForm')}
              list={carClaim.completedClaimForms}
              renderItem={(url) => <ClaimAttachmentItem key={url} name="completed-claim-forms" url={url} />}
            />
            <RowItem
              label={t('carInsuranceClaim.supportingDocuments')}
              list={carClaim.supportingDocuments}
              renderItem={(url) => <ClaimAttachmentItem key={url} name="supporting-documents" url={url} />}
            />
            <RowItem label={t('claimForm.totalClaimedAmount.label')} value={amountToUsd(carClaim.totalClaimedAmount)} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { CarClaimDetails };
