import { createActions } from 'redux-actions';
import { Pagination } from '@models/Pagination';
import { GetAllArticleParams } from '@apis/ArticleApi';
import { Article } from '@models/Article';

export type State = {
  articles?: Article[] | null;
  pagination?: Pagination | undefined;
};

export type ArticlesFetchPayload = GetAllArticleParams;
export type StateUpdatePayload = Partial<State>;

export const {
  articlesFetch,
  stateUpdate,
} = createActions(
  'ARTICLES_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'ARTICLE_PAGE',
  },
);
