import { tEnum } from '@components/i18n';
import { PersonalAccidentPlanBenefit } from '@models/PersonalAccidentPlanBenefit';

export enum BenefitGroupTypes {
  BasicBenefits = 'basic_benefits',
  Extensions = 'extensions',
  Iema = 'iema',
}

export const tBenefitGroupType = (groupType: BenefitGroupTypes) => tEnum('personalAccidentPlanBenefitGroupTypes', groupType);

export const tBenefitType = (type: string) => tEnum('personalAccidentPlanBenefit', type);

export type BenefitGroupTypesKeys = keyof typeof BenefitGroupTypes;

export type PersonalAccidentPlanBenefitGroup = Record<BenefitGroupTypes, PersonalAccidentPlanBenefit[]>;
