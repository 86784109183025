import React from 'react';
import { InputGroup, Button, FormControl, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrderStatusVariant, OrderStatuses, tOrderStatus } from '@models/Order';

type Values = {
  search: string;
}

const OrderSearch = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const rawParams = Object.fromEntries(searchParams);
  const { status, search } = { status: '', search: '', ...rawParams };
  const initialValues: Values = { search };

  const handleSubmit = (values: Values) => {
    setSearchParams(values);
  }

  const handleStatus = (value: string) => {
    setSearchParams({ status: value });
  }

  return (
    <ButtonToolbar className="justify-content-between mb-4">
      <ButtonGroup>
        <Button variant="outline-primary" active={!status} onClick={() => handleStatus('')}>All</Button>
        <Button variant="outline-danger" active={status === 'renewable'} onClick={() => handleStatus('renewable')}>{t('common:renewable')}</Button>
        {map(OrderStatuses, (value,) => (
          <Button key={value} variant={`outline-${getOrderStatusVariant(value)}`} active={value === status} onClick={() => handleStatus(value)}>{tOrderStatus(value)}</Button>
        ))}
      </ButtonGroup>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleBlur, handleChange }) => (
          <Form>
            <InputGroup>
              <FormControl name="search" placeholder="order or policy number" onChange={handleChange} onBlur={handleBlur} />
              <Button type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </ButtonToolbar >
  );
}

export { OrderSearch };
