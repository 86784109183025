import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mergeInitialValues } from '@utils/formik';

import { selectCarQuoteBuy } from '../selector';
import { useFormValues, useFormSchema } from '../CarQuoteBuyForm';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const values = useFormValues();
  return useMemo(() => {
    if (!carQuote) return;
    return mergeInitialValues(values, {
      ...carQuote,
      ...carQuote.userCar,
      ...carQuote.user,
    });
  }, [carQuote]);
};

export const useValidationSchema = useFormSchema;
