import { call, put, takeLatest } from 'redux-saga/effects';

import { CarQuote } from '@models/CarQuote';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { SagaPayload } from '@utils/typescript';

import { carQuoteFind, CarQuoteFindPayload, carQuoteSendEmail, CarQuoteSendEmailPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleCarQuoteFind({ payload }: SagaPayload<CarQuoteFindPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.find, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteSendEmail({ payload }: SagaPayload<CarQuoteSendEmailPayload>) {
  try {
    yield call(CarQuoteApi.sendEmail, payload);
  } catch { }
}

export default function* () {
  yield takeLatest(carQuoteFind, handleCarQuoteFind);
  yield takeLatest(carQuoteSendEmail, handleCarQuoteSendEmail);
}
