import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { generalQuoteFetch, GeneralQuoteFetchPayload } from './action';
import { selectGeneralQuoteBuy } from './selector';

export const useGeneralQuoteFetch = () => {
  const dispatch = useDispatch();
  const generalOrderId = Number(useRouteParam('generalOrderId'));
  const { generalQuote } = useSelector(selectGeneralQuoteBuy);

  useEffectOnce(() => {
    if (generalQuote && generalQuote.generalOrderId == generalOrderId) return;
    const payload: GeneralQuoteFetchPayload = { generalOrderId };
    dispatch(generalQuoteFetch(payload));
  });

  return generalQuote;
};
