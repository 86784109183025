import React from 'react';
import { Card, useAccordionButton, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { QuotableDocument } from '@models/Quote';
import { QuoteDocumentStack } from './quote-document-stack';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <Card.Link className="c-pointer link-info" onClick={handleClick}>
      {children}
    </Card.Link>
  );
};

const QuoteDocumentCardBody = (props: QuotableDocument) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Accordion flush alwaysOpen>
        <Card.Title>
          <Toggle eventKey="documents">{pluralize(t('common:document'), 2)}</Toggle>
        </Card.Title>

        <Accordion.Collapse eventKey="documents">
          <QuoteDocumentStack {...props} />
        </Accordion.Collapse>
      </Accordion>
    </Card.Body>
  );
};

export { QuoteDocumentCardBody };
