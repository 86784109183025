import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { map, isUndefined, startCase } from 'lodash';

import { Article } from '@models/Article';
import { routeArticalDetailsPage } from '@components/ArticleDetailsPage';
import { routeArticlePage } from '@components/ArticlePage';

import { selectArticleSection } from './selector';
import { articlesFetch } from './action';
import { Title } from '../title';

const ArticleCard = ({ insuranceType, id, title, shortDescription, coverPhoto }: Article) => (
  <Card as={Link} to={routeArticalDetailsPage({ insuranceType, articleId: id })} className="shadow h-100 text-decoration-none text-body">
    <div style={{ height: '280px' }}>
      <Card.Img src={coverPhoto} variant="top" className="h-100" style={{ objectFit: 'cover' }} />
    </div>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text>{shortDescription}</Card.Text>
    </Card.Body>
  </Card>
);

const ArticleSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { articles } = useSelector(selectArticleSection);
  const hasArticles = !isUndefined(articles) && articles.length > 0;

  useEffectOnce(() => {
    dispatch(articlesFetch({ perPage: 3 }));
  });

  return (
    <>
      {hasArticles && (
        <section className="py-5">
          <Title title={startCase(t('common:latestArticles'))}></Title>
          <Container>
            <Row className="gy-3 justify-content-center">
              {map(articles, (article) => (
                <Col key={article.title} sm={12} lg={4}>
                  <ArticleCard {...article} />
                </Col>
              ))}
            </Row>
          </Container>
          <div className="text-center mt-5">
            <Button as={Link} to={routeArticlePage()} variant="outline-primary" className="fw-bold p-3">
              {t('common:viewMore')}
            </Button>
          </div>
        </section>
      )}
    </>
  );
};

export { ArticleSection };
