import { InferType, object, string, number, boolean, mixed, array } from 'yup';
import { forEach, union } from 'lodash';
import moment from 'moment';
import i18n from '@components/i18n';

const MAX_FILES = 5;
const MAX_FILE_SIZE = 5000000; //5MB
const PHOTO_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const PDF_FORMATS = ['application/pdf'];

export const formValuesSchema = object({
  insuredPolicyHolder: string().default('').required(i18n.t('validation:required')),
  policyNumber: string().default('').required(i18n.t('validation:required')),
  policyOrderNumber: string().default('').required(i18n.t('validation:required')),
  policyExpiryDate: string()
    .default(() => moment(new Date()).format('YYYY-MM-DDTHH:mm'))
    .required(i18n.t('validation:required')),
  accidentHappenedAt: string()
    .default(() => moment(new Date()).format('YYYY-MM-DDTHH:mm'))
    .required(i18n.t('validation:required')),
  accidentPhotos: mixed<File[]>()
    .default([])
    .test('required', i18n.t('validation:required'), (files) => files.length > 0)
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_FILES }),
      (files) => files.length <= MAX_FILES,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files) => {
      let isValid = false;

      forEach(files, (file) => {
        if (!PHOTO_SUPPORTED_FORMATS.includes(file.type)) {
          isValid = false;
          return;
        }

        isValid = true;
      });

      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < MAX_FILE_SIZE) isValid = true;
      });
      return isValid;
    }),
  claimTypes: array(string().required(i18n.t('validation:required')))
    .default([])
    .min(1, i18n.t('validation:required')),
  passengersAndThirdPartiesPoliceReports: mixed<File[]>()
    .default([])
    .test('required', i18n.t('validation:required'), (files) => files.length > 0)
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_FILES }),
      (files) => files.length <= MAX_FILES,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files) => {
      let isValid = false;

      forEach(files, (file) => {
        if (!union(PDF_FORMATS, PHOTO_SUPPORTED_FORMATS).includes(file.type)) {
          isValid = false;
          return;
        }

        isValid = true;
      });

      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < MAX_FILE_SIZE) isValid = true;
      });
      return isValid;
    })
  ,
  completedClaimForms: mixed<File[]>()
    .default([])
    .test('required', i18n.t('validation:required'), (files) => files.length > 0)
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_FILES }),
      (files) => files.length <= MAX_FILES,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files) => {
      let isValid = false;

      forEach(files, (file) => {
        if (!union(PDF_FORMATS, PHOTO_SUPPORTED_FORMATS).includes(file.type)) {
          isValid = false;
          return;
        }

        isValid = true;
      });

      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < MAX_FILE_SIZE) isValid = true;
      });
      return isValid;
    })
  ,
  supportingDocuments: mixed<File[]>()
    .default([])
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_FILES }),
      (files) => files.length <= MAX_FILES,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files) => {
      let isValid = false;

      forEach(files, (file) => {
        if (!union(PDF_FORMATS, PHOTO_SUPPORTED_FORMATS).includes(file.type)) {
          isValid = false;
          return;
        }

        isValid = true;
      });

      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < MAX_FILE_SIZE) isValid = true;
      });
      return isValid;
    }),
  totalClaimedAmount: number()
    .default(0)
    .required(i18n.t('validation:required'))
    .positive(i18n.t('validation:positive')),
  acceptTermsAndConditions: boolean()
    .default(false)
    .test(
      'required',
      i18n.t('validation:required'),
      (checked) => checked === true,
    ),
});

export type FormValues = InferType<typeof formValuesSchema>;
