import { getRoute } from '@utils/route';

type RouteParams = {
  personalAccidentOrderId: number;
  billingId: number;
};

export const PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL = '/personal-accident-insurance-quote-payment/:personalAccidentOrderId/:billingId';

export const routePersonalAccidentOrderPaymentPage = (routeParams: RouteParams) => getRoute(PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL, { routeParams });
