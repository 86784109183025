import { useMemo } from 'react';
import { GeneralQuote } from '@models/GeneralQuote';
import { mergeInitialValues } from '@utils/formik';
import { useFormSchema, useFormValues } from '../GeneralQuoteBuyForm';

export const useInitialValues = (generalQuote?: GeneralQuote | null) => {
  const values = useFormValues();
  return useMemo(() => {
    if (generalQuote) return mergeInitialValues(values, { ...generalQuote, ...generalQuote.user, proof: undefined });
    return values;
  }, [generalQuote]);
};

export const useValidationSchema = () => {
  const schema = useFormSchema();
  return schema.shape({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    proof: schema.fields.proof.optional()
  });
};
