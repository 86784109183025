import { pick } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { useParseParams } from '@hooks/params';
import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useHealthQuoteFind } from '@components/HealthQuoteDetailsRoute';

import { formValuesParamsSchema, FormValues, meFormValuesParamsSchema } from '../HealthQuoteBuyForm';
import { appendHealthPlanQuestionsToFormValues } from '@utils/health-quote';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const healthQuote = useHealthQuoteFind();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!currentUser) return;
    appendHealthPlanQuestionsToFormValues(initialValues, healthQuote?.healthPlanQuestions);
    return mergeInitialValues(initialValues, {
      isConfirmedDeclaration: false,
      me: isCustomer ? pick(currentUser, getFieldKeys(meFormValuesParamsSchema)) : undefined,
    });
  }, [currentUser, healthQuote]);
};
