import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/params';

import { selectPersonalAccidentQuoteComparePage } from './selector';
import { personalAccidentQuotesFetch } from './action';
import { PersonalAccidentQuoteComparePageParams } from './route';
import { personalAccidentQuoteComparePageParamsSchema } from './schema';

export const usePersonalAccidentQuotesComparePageParams = () =>
  useParseParams<PersonalAccidentQuoteComparePageParams>(personalAccidentQuoteComparePageParamsSchema);

export const usePersonalAccidentQuotesFetch = () => {
  const dispatch = useDispatch();
  const payload: PersonalAccidentQuoteComparePageParams = usePersonalAccidentQuotesComparePageParams();
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);

  useEffect(() => {
    dispatch(personalAccidentQuotesFetch(payload));
  }, []);

  return personalAccidentQuotes;
};
