import { createActions } from 'redux-actions';

import { CarQuoteList } from '@models/CarQuote';
import { CompareCarQuoteParams } from '@apis/CarQuoteApi';

export type State = {
  carQuotes: CarQuoteList[];
};

export type CarQuotesFetchPayload = CompareCarQuoteParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carQuotesFetch,
  stateUpdate,
} = createActions(
  'CAR_QUOTES_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_COMPARE_PAGE',
  },
);
