import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { carOrderFetch, CarOrderFetchPayload } from './action';
import { selectCarPolicyDetails } from './selector';

export const useCarOrderFetch = () => {
  const dispatch = useDispatch();
  const carOrderId = Number(useRouteParam('orderableId'));
  const { carOrder } = useSelector(selectCarPolicyDetails);

  useEffectOnce(() => {
    if (carOrder && carOrder.id === carOrderId) return;
    const payload: CarOrderFetchPayload = { id: carOrderId };
    dispatch(carOrderFetch(payload));
  });

  return carOrder;
};
