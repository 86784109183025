import { useTranslation } from 'react-i18next';
import { date, number, object, string } from 'yup';
import 'yup-phone-lite';
import moment from 'moment-timezone';
import { useUserFormSchema, useUserFormValues } from '@components/Feature/User/UserFormControl';
import { FormValues } from './type';

export const useFormValues = () => {
  const userValues = useUserFormValues();
  const values: FormValues = {
    ...userValues,
    chassisNumber: '',
    engineNumber: '',
    registrationNumber: '',
    registrationPlace: 'Phnom Penh',
    activationDate: '',
    companyId: '',
  };
  return values;
};

export const useFormSchema = () => {
  const { t } = useTranslation();
  const useSchema = useUserFormSchema();
  return useSchema.concat(object({
    engineNumber: string().required(t('validation:required')),
    chassisNumber: string().required(t('validation:required')),
    registrationPlace: string().required(t('validation:required')),
    registrationNumber: string().required(t('validation:required')),
    activationDate: date().optional()
      .min(moment(), 'must be today till 3 months from now')
      .max(moment().add(3, 'month'), 'must be today till 3 months from now'),
    campanyId: number().optional(),
  }));
};
