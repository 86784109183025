import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { CancerQuote } from '@models/CancerQuote';
import { amountToUsd } from '@utils/currency';

import { Title } from './title';

import { StretchRowItem } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/feature-section';

const FeatureSection = ({ annualLimitAmount, geographicCoverage, hospitals }: CancerQuote) => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Card>
              <Card.Body>
                <StretchRowItem label={t('cancerQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
                <StretchRowItem label={t('cancerQuote.geographicCoverage')} value={geographicCoverage} />
                <StretchRowItem label={t('cancerQuote.cashlessHospitals')} value={hospitals.length} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section >
  );
};

export { FeatureSection };
