import { tEnum } from '@components/i18n';

export enum InsuranceTypes {
  Car = 'car',
  Health = 'health',
  PersonalAccident = 'personal_accident',
  Cancer = 'cancer',
  Fire = 'fire',
  Life = 'life',
}

export const tInsuranceType = (type: InsuranceTypes) => tEnum('insuranceTypes', type);
export const tInsuranceTypeName = (type: InsuranceTypes) => tEnum('insuranceTypeNames', type);
