import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { selectCarPolicyRenewal } from './selector';
import { carOrderRenewalPreview, CarOrderRenewalPreviewPayload } from './action';

export const useCarOrderRenewalPreview = () => {
  const dispatch = useDispatch();
  const carOrderId = Number(useRouteParam('orderableId'));
  const { carOrder } = useSelector(selectCarPolicyRenewal);

  useEffectOnce(() => {
    const payload: CarOrderRenewalPreviewPayload = { id: carOrderId };
    dispatch(carOrderRenewalPreview(payload));
  });

  return carOrder;
};
