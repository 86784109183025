import { createActions } from 'redux-actions';
import { CarQuoteList } from '@models/CarQuote';
import { GetAllCarQuoteParams } from '@apis/CarQuoteApi';

export type State = {
  carQuotes: CarQuoteList[] | null | undefined;
  comparableCarQuotes: CarQuoteList[];
};

export type CarQuotesFetchPayload = GetAllCarQuoteParams;
export type CarQuoteComparableAddPayload = { carQuote: CarQuoteList };
export type CarQuoteComparableRemovePayload = CarQuoteComparableAddPayload;
export type StateUpdatePayload = Partial<State>;

export const {
  carQuotesFetch,
  carQuoteComparableAdd,
  carQuoteComparableRemove,
  carQuoteComparableRemoveAll,
  stateUpdate,
  stateReset,
} = createActions(
  'CAR_QUOTES_FETCH',
  'CAR_QUOTE_COMPARABLE_ADD',
  'CAR_QUOTE_COMPARABLE_REMOVE',
  'CAR_QUOTE_COMPARABLE_REMOVE_ALL',
  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'CAR_QUOTE_RESULT_PAGE',
  },
);
