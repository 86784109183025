import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BackButton } from '@components/Base';
import { dOrderPeriod } from '@models/Order';
import { useRouteParam } from '@hooks/params';

import { carOrderRenew, CarOrderRenewPayload } from './action';
import { useCarOrderRenewalPreview } from './hook';

const CarPolicyRenewal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const carOrderId = Number(useRouteParam('orderableId'));
  const carOrder = useCarOrderRenewalPreview();

  if (!carOrder) return null;

  const {
    policyNumber,
    startedAt,
    endedAt,
    insurer: { carProductEnabled },
  } = carOrder;

  const handleRenew = () => {
    const payload: CarOrderRenewPayload = { id: carOrderId };
    dispatch(carOrderRenew(payload));
  };

  return (
    <section data-testid="car-policy-renewal-section">
      <BackButton className="mb-4" />
      <h5 className="mb-4">{t('common:renewPolicy')}</h5>

      {!carProductEnabled && (
        <Alert variant="warning">
          <Alert.Heading>{t('carInsuranceQuote.disabledTitle')}</Alert.Heading>
          <p>{t('carInsuranceQuote.disabledDesc')}</p>
        </Alert>
      )}

      <Card>
        <Card.Body>
          <ul className="list-unstyled mb-4">
            <li>{t('common:policyNumber')}: {policyNumber}</li>
            <li>{t('common:periodOfInsurance')}: {dOrderPeriod(startedAt, endedAt)}</li>
          </ul>
          <Button disabled={!carProductEnabled} onClick={handleRenew}>
            Proceed to custom premium
          </Button>
        </Card.Body>
      </Card>
    </section>
  );
};

export { CarPolicyRenewal };
