import React, { useEffect, useState } from 'react';
import { isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Loading, LoadingError, Pagination } from '@components/Base';
import { isPaginated } from '@models/Pagination';
import { useParamsPage } from '@hooks/params';

import { selectVehicle } from './selector';
import { userCarsFetch, UserCarsFetchPayload } from './action';
import { UserCarList } from './user-car-list';

const Vehicle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useParamsPage();
  const { userCars, pagination } = useSelector(selectVehicle);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const payload: UserCarsFetchPayload = { page };
    dispatch(userCarsFetch(payload));
  }, [page]);

  useEffect(() => {
    if (isUndefined(userCars)) return;
    setIsLoading(false);
  }, [userCars]);

  if (isNull(userCars)) return <LoadingError />;

  return (
    <section>
      <h5 className="mb-4">{t('common:vehicle')}</h5>
      <div className="mb-4">
        {isLoading ? <Loading /> : <UserCarList />}
      </div>
      {isPaginated(pagination) && (
        <div className="d-flex justify-content-center">
          <br />
          <Pagination {...pagination} />
        </div>
      )}
    </section>
  );
};

export { Vehicle };
