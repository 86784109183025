import { keys, includes, forOwn } from 'lodash';
import { ObjectSchema, mixed } from 'yup';
import i18n from '@components/i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationSchema = ObjectSchema<any>;

export const yupFile = () => {
  const fileSize = 5_000_000; // 5MB
  const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

  return mixed()
    .test(
      'fileSize',
      i18n.t('validation:fileSizeOutOfRange'),
      (file: File) => !file || (file && file.size < fileSize),
    )
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => !file || (file && includes(supportedFormats, file.type)),
    );
};

/* eslint-disable */
export const getFields = (schema: ValidationSchema) => {
  return schema?.describe()?.fields;
}

export const getFieldKeys = (schema: ValidationSchema) => {
  return keys(getFields(schema));
}
/* eslint-enable */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired = <T extends object>(schema: ValidationSchema) => {
  const result = {} as T;
  forOwn(schema.fields, (field, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    result[key as keyof T] = !!field?.exclusiveTests?.required as T[keyof T];
  });
  return result;
};
