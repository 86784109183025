import { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';

export enum UserDocumentTypes {
  IdCard = 'ID Card',
  Passport = 'Passport',
  DrivingLicense = 'Driving License',
}

export enum UserDocumentStatuses {
  Unverified = 'unverified',
  Reviewing = 'reviewing',
  Rejected = 'rejected',
  Verified = 'verified',
}

export type UserDocument = CamelCaseKeys<{
  id: number;
  status: UserDocumentStatuses;
  document_type: UserDocumentTypes;
  document: string;
  rejected_reason?: string;
}>;

const USER_DOCUMENT_STATUSES_VARIANTS_MAPPING = {
  [UserDocumentStatuses.Unverified]: 'warning',
  [UserDocumentStatuses.Reviewing]: 'info',
  [UserDocumentStatuses.Rejected]: 'danger',
  [UserDocumentStatuses.Verified]: 'success',
};

export const getUserDocumentStatusVariant = (status: UserDocumentStatuses) => {
  return USER_DOCUMENT_STATUSES_VARIANTS_MAPPING[status];
};

export const tUserDocumentStatus = (status: UserDocumentStatuses) => tEnum('userDocumentStatuses', status);
export const tUserDocumentType = (type: UserDocumentTypes) => tEnum('userDocumentTypes', type);
