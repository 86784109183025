import { useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';

export const ServerError = <T,>({ errors }: { errors: FormikErrors<T> }) => {
  const { setErrors } = useFormikContext<T>();
  useEffect(() => {
    setErrors(errors);
  }, [errors, setErrors]);
  return null;
};

export const ResetForm = ({ reset }: { reset: boolean }) => {
  const { resetForm } = useFormikContext();
  useEffect(() => {
    if (reset) {
      resetForm();
    }
  }, [reset, resetForm]);
  return null;
};
