import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { RowItem, RowItemProps } from '@components/Base';
import { getPersonalAccidentPlanBenefitDescription } from '@utils/personal-accident-quote';
import { FeaturedPersonalAccidentPlanBenefits } from '@models/PersonalAccidentPlanBenefit';
import { amountToUsd } from '@utils/currency';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
  labelProps: { lg: 4 },
};

type Props = Pick<PersonalAccidentQuote,
  | 'geographicCoverage'
  | 'hospitals'
  | 'personalAccidentPlanBenefits'
  | 'annualLimitAmount'
>;

const FeatureCardBody = ({ geographicCoverage, hospitals, personalAccidentPlanBenefits, annualLimitAmount }: Props) => {
  const { t } = useTranslation();
  const permanentAndPartialDisablementDescription = getPersonalAccidentPlanBenefitDescription(
    personalAccidentPlanBenefits,
    FeaturedPersonalAccidentPlanBenefits.PermanentAndPartialDisablementDueToAccident,
  );

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
      <RowItem
        {...ROW_ITEM_PROPS}
        label={t('personalAccidentPlanBenefit.basicBenefitsDeathDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.DeathDueToAccident,
        )}
      />
      <RowItem
        {...ROW_ITEM_PROPS}
        label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndTotalDisablementDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.PermanentAndTotalDisablementDueToAccident,
        )}
      />
      {!!permanentAndPartialDisablementDescription && (
        <RowItem
          {...ROW_ITEM_PROPS}
          label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndPartialDisablementDueToAccident')}
          value={permanentAndPartialDisablementDescription}
        />
      )}
      <RowItem
        {...ROW_ITEM_PROPS}
        label={t('personalAccidentPlanBenefit.basicBenefitsMedicalExpenseDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.MedicalExpenseDueToAccident,
        )}
      />
    </Card.Body>
  );
};

export { FeatureCardBody };
