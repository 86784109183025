import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { isNull, isUndefined } from 'lodash';

import { FormGroupControl, Loading, LoadingError } from '@components/Base';

import { useCarQuoteFetch } from '../hook';
import { carQuoteLock, CarQuoteLockPayload } from '../action';
import { RightCol } from '../right-col';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyLockPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteLockPayload = { carOrderId, ...values };
    dispatch(carQuoteLock(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupControl
                        type="date"
                        name="activationDate"
                        label={t('common:effectiveDate')}
                        groupProps={GROUP_PROPS}
                        required
                        min={moment().subtract(1, 'year').format('YYYY-MM-DD')}
                        max={moment().add(3, 'month').format('YYYY-MM-DD')}
                        help="Date must be within 1 year ago and 3 months from now."
                      />

                      <FormGroupControl
                        name="customerConsent"
                        type="file"
                        label={t('common:customerConsent')}
                        groupProps={GROUP_PROPS}
                      />

                      <Alert>Notice: A closing instruction email will be sent to insurer.</Alert>
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                          Confirm
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <RightCol carQuote={carQuote} />
      </Row>
    </Container>
  );
};

export { CarQuoteBuyLockPage };
