import React from 'react';
import { map, startCase } from 'lodash';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useInsuranceProduct } from '@components/InsuranceProduct';

const InsuranceNavDropdown = () => {
  const { title, items } = useInsuranceProduct();

  return (
    <NavDropdown title={startCase(title)}>
      {map(items, ({ label, to }) => (
        <Link key={label} to={to} className="dropdown-item">
          {startCase(label)}
        </Link>
      ))}
    </NavDropdown>
  );
};

export { InsuranceNavDropdown };
