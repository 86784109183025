import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { generalOrderFetch, GeneralOrderFetchPayload } from './action';
import { selectGeneralPolicyDetails } from './selector';

export const usegeneralOrderFetch = () => {
  const dispatch = useDispatch();
  const generalOrderId = Number(useRouteParam('orderableId'));
  const { generalOrder } = useSelector(selectGeneralPolicyDetails);

  useEffectOnce(() => {
    if (generalOrder && generalOrder.id === generalOrderId) return;
    const payload: GeneralOrderFetchPayload = { id: generalOrderId };
    dispatch(generalOrderFetch(payload));
  });

  return generalOrder;
};
