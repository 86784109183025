import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { CancerQuote } from '@models/CancerQuote';
import { CancerQuoteBenefitListGroup } from '@components/Feature/CancerQuote';

const CancerBenefitCardBody = (props: Pick<CancerQuote, 'cancerPlanBenefits'>) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:benefit'), 2)}</Card.Title>
      <CancerQuoteBenefitListGroup {...props} />
    </Card.Body>
  );
};

export { CancerBenefitCardBody };
