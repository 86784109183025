import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import { Loading, LoadingError } from '@components/Base';
import { useIsRequired } from '@hooks/yup';

import GeneralQuoteForm, { FormDisabled, FormRequired, FormValues } from '../GeneralQuoteBuyForm';
import { GeneralQuoteUpdatePayload, generalQuoteUpdate } from '../action';
import { useGeneralQuoteFetch } from '../hook';
import { useValidationSchema, useInitialValues } from './hook';
import { OrderStatuses } from '@models/Order';

const GeneralQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const generalQuote = useGeneralQuoteFetch();
  const initialValues = useInitialValues(generalQuote);
  const validationSchema = useValidationSchema();
  const required = useIsRequired<FormRequired>(validationSchema);

  if (isUndefined(generalQuote)) return <Loading />;
  if (isNull(generalQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { generalOrderId, status } = generalQuote;
  const isLocked = status === OrderStatuses.Locked;
  const isActive = status === OrderStatuses.Active;
  const disabled: FormDisabled = {
    firstName: isLocked || isActive,
    lastName: isLocked || isActive,
    firstNameKm: isLocked || isActive,
    lastNameKm: isLocked || isActive,
    gender: isLocked || isActive,
    dob: isLocked || isActive,
    personalCode: isLocked || isActive,
    occupation: isLocked || isActive,
    email: isLocked || isActive,
    noMobileNumber: true,
    mobileNumber: isLocked || isActive,
    address: isLocked || isActive,
    insurerId: isLocked || isActive,
    orderTemplateId: isLocked || isActive,
    activationDate: isLocked || isActive,
    adminFeeAmount: isLocked || isActive,
    premium: isLocked || isActive,
    note: isLocked || isActive,
    proof: isLocked || isActive,
  }

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: GeneralQuoteUpdatePayload = { generalOrderId, ...values };
    dispatch(generalQuoteUpdate(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <GeneralQuoteForm required={required} disabled={disabled} />
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export { GeneralQuoteBuyEditPage };
