import React, { useMemo } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import { forOwn } from 'lodash';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { BackButton, FormGroupControl, Loading } from '@components/Base';
import { ServerError } from '@components/Helper';
import { GenderFormSelect } from '@components/Feature/User';
import { useIsRequired } from '@hooks/yup';
import { getBMI } from '@utils/health';

import { selectPersonalInfoEdit } from './selector';
import { userUpdate, UserUpdatePayload } from './action';
import { FormValues, FormRequired } from './type';
import { useInitialValues, useValidationSchema } from './hook';

const GROUP_PROPS = { className: 'mb-3' };

const isDisabled = <T,>(values: T, isCustomer: boolean) => {
  const result = {} as Record<keyof T, boolean>;
  forOwn(values, (v, k) => {
    result[k as keyof T] = isCustomer && !!v;
  });
  return result;
}

const FormForm = ({ required }: { required: FormRequired }) => {
  const { t } = useTranslation();
  const isCustomer = useSelector(selectIsCustomer);
  const { errors } = useSelector(selectPersonalInfoEdit);
  const { values, isSubmitting, initialValues, setFieldValue } = useFormikContext<FormValues>();
  const disabled = useMemo(() => ({
    ...isDisabled(initialValues, isCustomer),
  }), [initialValues, isCustomer]);

  const handleBlur = () => {
    const { weight, height } = values;
    if (weight && height) {
      setFieldValue('bmi', getBMI(weight, height));
    }
  };

  return (
    <Form>
      <Row>
        <Col>
          <FormGroupControl
            name="lastName"
            label={t('user.lastName')}
            disabled={disabled.lastName}
            required={required.lastName}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstName"
            label={t('user.firstName')}
            disabled={disabled.firstName}
            required={required.firstName}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="lastNameKm"
            label={t('user.lastNameKm')}
            disabled={disabled.lastNameKm}
            required={required.lastNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstNameKm"
            label={t('user.firstNameKm')}
            disabled={disabled.firstNameKm}
            required={required.firstNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <GenderFormSelect
        name="gender"
        disabled={disabled.gender}
        required={required.gender}
        groupProps={GROUP_PROPS}
      />

      <Row>
        <Col>
          <FormGroupControl
            type="number"
            name="weight"
            label={t('user.weight')}
            groupProps={GROUP_PROPS}
            onBlur={handleBlur}
            disabled={disabled.height}
            required={required.height}
          />
        </Col>

        <Col>
          <FormGroupControl
            type="number"
            name="height"
            label={t('user.height')}
            groupProps={GROUP_PROPS}
            onBlur={handleBlur}
            disabled={disabled.height}
            required={required.height}
          />
        </Col>

        <Col>
          <FormGroupControl
            type="number"
            name="bmi"
            label={t('user.bmi')}
            groupProps={GROUP_PROPS}
            required={required.bmi}
            disabled
          />
        </Col>
      </Row>

      <FormGroupControl
        name="personalCode"
        label={t('user.personalCode')}
        disabled={disabled.personalCode}
        required={required.personalCode}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        type="date"
        name="dob"
        label={t('user.dob')}
        disabled={disabled.dob}
        required={required.dob}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="occupation"
        label={t('user.occupation')}
        disabled={disabled.occupation}
        required={required.occupation}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="email"
        label={t('user.email')}
        disabled={disabled.email}
        required={required.email}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="address"
        label={t('user.address')}
        disabled={disabled.address}
        required={required.address}
        as="textarea"
        rows={2}
        groupProps={GROUP_PROPS}
      />
      <Button type="submit" disabled={isSubmitting}>
        {t('common:update')}
      </Button>
      <ServerError errors={errors} />
    </Form>
  );
}

const PersonalInfoEditPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const initialValues = useInitialValues(currentUser);
  const validationSchema = useValidationSchema();
  const required = useIsRequired<FormRequired>(validationSchema);

  if (!currentUser) return <Loading />;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: UserUpdatePayload = values;
    dispatch(userUpdate(payload))
    setSubmitting(false);
  };

  return (
    <>
      <BackButton className="mb-4" />
      <Card>
        <Card.Header className="fs-5 fw-bold">
          {t('common:personalInformation')}
        </Card.Header>
        <Card.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <FormForm required={required} />
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export { PersonalInfoEditPage };
