import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { Article, ArticleList } from '@models/Article';
import { Pagination, PaginationParams, paginate } from '@models/Pagination';
import { InsuranceTypes } from '@models/InsuranceType';
import { snakes } from '@utils/lodash';

export type GetAllArticleParams = PaginationParams & {
  insuranceType?: InsuranceTypes;
};

export interface GetArticleParams {
  id: string;
}

export class ArticleApi {
  static getAll = async (params = {} as GetAllArticleParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/articles`, { params: snakes(params) })
      .then((response) => paginate(response) as [ArticleList[], Pagination]);
  };

  static get = async ({ id }: GetArticleParams) => {
    return await authAxios.get(`${API_V1_BASE}/articles/${id}`).then((response) => humps(response.data) as Article);
  };
}
