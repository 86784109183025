import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { includes, kebabCase, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { dOrderPeriod, getOrderStatusVariant, getOrderType, OrderList, OrderStatuses } from '@models/Order';
import { getInsurerHotlines } from '@models/Insurer';
import { RowItem } from '@components/Base';
import { selectIsFrontline } from '@components/Auth';
import { tInsuranceTypeName } from '@models/InsuranceType';
import { routeUserDashboardPolicyClaim, routeUserDashboardPolicyDetails, routeUserDashboardPolicyRenewal } from '@components/UserDashboardRoute';
import { dUserFullname } from '@models/User';
import { OrderBadge } from '@components/Feature/Order';

const ROW_ITEM_PROPS = {
  className: 'mb-2',
  labelProps: { xs: 7, sm: 7, md: 4, lg: 3 },
};

type Props = {
  order: OrderList;
}

const OrderItem = ({ order }: Props) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const {
    orderableType,
    orderableId,
    orderNumber,
    insuranceType,
    status,
    policyNumber,
    renewable,
    claimable,
    startedAt,
    endedAt,
    insurer,
    activationDate,
    coverScopeTitle,
    user,
  } = order;

  const statusVariant = getOrderStatusVariant(status);
  const orderType = getOrderType(orderableType);
  const hotline = getInsurerHotlines(insurer, insuranceType);

  const shouldRenderViewDetails = !includes([OrderStatuses.Pending, OrderStatuses.Expired], status) || activationDate;
  const shouldRenderPay = includes([OrderStatuses.Pending, OrderStatuses.Locked], status);
  const shouldRenderPeriod = !!startedAt && !!endedAt;
  const shouldRenderRenew = renewable && isFrontline;
  const shouldRenderFooter = shouldRenderViewDetails || shouldRenderPay || claimable || shouldRenderRenew;

  return (
    <Card border={statusVariant} data-testid="user-dashboard-policy-item">
      <Card.Header as="h5" className="bg-transparent d-flex flex-row justify-content-between align-items-center">
        <span>{tInsuranceTypeName(insuranceType)}</span>
        <OrderBadge status={status} />
      </Card.Header>
      <Card.Body>
        <RowItem {...ROW_ITEM_PROPS} label={t('common:orderNumber')} value={orderNumber} />
        <RowItem {...ROW_ITEM_PROPS} label={t('common:theInsuredPolicyholder')} value={dUserFullname(user)} />
        {policyNumber && <RowItem {...ROW_ITEM_PROPS} label={t('common:policyNumber')} value={policyNumber} />}
        <RowItem {...ROW_ITEM_PROPS} label={t('common:insurer')} value={insurer.name} />
        {coverScopeTitle && <RowItem {...ROW_ITEM_PROPS} label={t('common:planType')} value={coverScopeTitle} />}
        {shouldRenderPeriod && <RowItem {...ROW_ITEM_PROPS} label={t('common:periodOfInsurance')} value={dOrderPeriod(startedAt, endedAt)} />}

        <small><Trans i18nKey="common:emergencyCall" values={{ hotline }} /></small>
      </Card.Body>
      {shouldRenderFooter && (
        <Card.Footer>
          <Stack direction="horizontal" gap={2}>
            {shouldRenderViewDetails && (
              <Button as={Link} to={routeUserDashboardPolicyDetails({ orderableType, orderableId })} variant="outline-primary" size="sm">
                {startCase(t('common:viewDetails'))}
              </Button>
            )}

            {shouldRenderPay && (
              <Button as={Link} to={`/${kebabCase(orderType)}-insurance-quote-buy/${orderableId}/review`} variant="outline-primary" size="sm">
                {isFrontline ? t('common:review') : t('policy.actions.reviewAndPay')}
              </Button>
            )}

            {claimable && (
              <Button as={Link} to={routeUserDashboardPolicyClaim({ orderableType, orderableId })} variant="outline-primary" size="sm">
                {t('common:claim')}
              </Button>
            )}

            {shouldRenderRenew && (
              <Button as={Link} to={routeUserDashboardPolicyRenewal({ orderableType, orderableId })} variant="outline-primary" size="sm">
                {t('common:renew')}
              </Button>
            )}
          </Stack>
        </Card.Footer>
      )}
    </Card >
  );
};

export { OrderItem };
