import { createActions } from 'redux-actions';
import { Pagination } from '@models/Pagination';
import { GetAllUserCarParams } from '@apis/UserCarApi';
import { UserCar } from '@models/UserCar';

export type State = {
  userCars?: UserCar[] | null;
  pagination?: Pagination | undefined;
};

export type UserCarsFetchPayload = GetAllUserCarParams;
export type StateUpdatePayload = Partial<State>;

export const {
  userCarsFetch,
  stateUpdate,
} = createActions(
  'USER_CARS_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_VEHICLE',
  },
);
