import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number, string, boolean } from 'yup';
import { pick } from 'lodash';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useParseParams } from '@hooks/params';

import { selectCarQuoteBuy } from '../selector';
import { formValuesParamsSchema } from './route';
import { FormValues } from './type';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!carQuote) return null;

    return mergeInitialValues(initialValues, pick(carQuote, getFieldKeys(formValuesParamsSchema)));
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    planType: string().required(t('validation:required')),
    idvAmount: number().required(t('validation:required')),
    accidentToDriver: boolean(),
    passengerLiability: boolean(),
  });
};
