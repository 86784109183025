import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, LinkProps } from 'react-router-dom';
import { map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FACEBOOK_PAGE_URL, TELEGRAM_CHANNEL_URL } from '@config';
import { useInsuranceProduct } from '@components/InsuranceProduct';
import { SUPPORTS } from '@components/Feature/Support';
import { routeAboutUsPage } from '@components/AboutUsPage';
import { routeContactUsPage } from '@components/ContactUsPage';
import { routePrivacyPolicyPage } from '@components/PrivacyPolicyPage';
import { routeTermsAndConditionsPage } from '@components/TermsAndConditionsPage';
import { routeCancellationAndRefundPage } from '@components/CancellationAndRefundPage/route';
import { routeArticlePage } from '@components/ArticlePage';

const GroupTitle = ({ title }: { title: string }) => <div className="fw-bold fs-5 mb-2">{title}</div>;

interface GroupProps {
  title: string;
  items: Array<{
    label: string;
    to: LinkProps['to'];
  }>;
}

const Group = ({ title, items }: GroupProps) => (
  <>
    <GroupTitle title={title} />
    <div className="mb-n2">
      {map(items, ({ label, to }) => (
        <Link key={label} to={to} className="link-light text-decoration-none d-block mb-2">
          {label}
        </Link>
      ))}
    </div>
  </>
);

const ContactSection = () => {
  const { t } = useTranslation();
  const { title: productTitle, items: productItems } = useInsuranceProduct();
  const companyTitle = t('common:company');
  const companyItems = useMemo(
    () => [
      {
        label: t('common:aboutUs'),
        to: routeAboutUsPage(),
      },
      {
        label: t('common:contactUs'),
        to: routeContactUsPage(),
      },
      {
        label: t('common:privacyPolicy'),
        to: routePrivacyPolicyPage(),
      },
      {
        label: t('common:termsAndConditions'),
        to: routeTermsAndConditionsPage(),
      },
      {
        label: t('common:cancellationAndRefund'),
        to: routeCancellationAndRefundPage(),
      },
    ],
    [],
  );
  const otherTitle = t('common:others');
  const otherItems = useMemo(
    () => [
      {
        label: t('common:articles'),
        to: routeArticlePage(),
      },
    ],
    [],
  );

  return (
    <section className="mt-5">
      <Container>
        <Row className="mb-n3">
          <Col xs={12} md={3} className="mb-3">
            <div className="mb-3">
              <GroupTitle title={t('user.address')} />
              <address>{t('mga.address')}</address>
            </div>

            <div>
              <GroupTitle title={t('common:customerService')} />
              {map(SUPPORTS, ({ value, href, icon, label }) => (
                <a key={value} href={href} className="text-decoration-none link-light d-block mb-1">
                  <FontAwesomeIcon icon={icon} className="fa-fw me-2" />
                  {label}
                </a>
              ))}
            </div>
          </Col>

          <Col xs={12} md={3} className="mb-3">
            <Group title={productTitle} items={productItems} />
          </Col>

          <Col xs={12} md={3} className="mb-3">
            <Group title={companyTitle} items={companyItems} />
          </Col>

          <Col xs={12} md={3} className="mb-3">
            <div className="mb-3">
              <Group title={otherTitle} items={otherItems} />
            </div>

            <div>
              <div className="fs-5 fw-bold mb-2 mt-3">{t('common:followUs')}</div>
              <div className="mb-2">
                <a
                  href={FACEBOOK_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none link-light d-block"
                >
                  Facebook Page
                </a>
              </div>
              <div className="mb-2">
                <a
                  href={TELEGRAM_CHANNEL_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none link-light d-block"
                >
                  Telegram Channel
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { ContactSection };
