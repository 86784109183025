import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { CarQuote } from '@models/CarQuote';
import { amountToUsd } from '@utils/currency';
import { CarPlanTypes } from '@models/CarPlan';
import { InsurerLogo } from '@components/Feature/Insurer';
import { useCarQuotesResultPageParams, routeCarQuoteResultPage } from '@components/CarQuoteResultPage';
import { routeCarQuoteBuyNewPage } from '@components/CarQuoteBuyRoute';

import { routeCarQuoteSendEmailPage } from '../route';

type Props = {
  carQuote: CarQuote;
};

const CarQuoteDetailsHeader = ({ carQuote }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useCarQuotesResultPageParams();
  const { insurer, totalPremium, planType, idvAmount, carPlanId } = carQuote;

  const handleBuyNow = () => {
    navigate(routeCarQuoteBuyNewPage({ ...params, carPlanId }));
  };

  const handleViewMore = () => {
    navigate(routeCarQuoteResultPage(params));
  };

  return (
    <>
      {!insurer.carProductEnabled && (
        <Alert variant="warning">
          <Alert.Heading>{t('carInsuranceQuote.disabledTitle')}</Alert.Heading>
          <p>{t('carInsuranceQuote.disabledDesc')}</p>
          <Button onClick={handleViewMore}>{t('common:viewMore')}</Button>
        </Alert>
      )}

      <div className="d-flex align-items-lg-center justify-content-lg-between flex-column flex-lg-row text-center text-lg-start">
        <div className="mb-3 mb-lg-0">
          <InsurerLogo insurer={insurer} fluid rounded />
        </div>

        {planType === CarPlanTypes.Comprehensive && (
          <div className="mb-3 mb-lg-0">
            <span className="d-block fs-5">{t('common:coverValueIdv')}</span>
            <span>{amountToUsd(idvAmount)}</span>
          </div>
        )}

        <div className="mb-3 mb-lg-0">
          <Button disabled={!insurer.carProductEnabled} onClick={handleBuyNow} variant="primary" className="w-100">
            <span className="fs-5">{startCase(t('common:buyNow'))}</span>
            <span className="fw-bold d-block fs-5">
              {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
            </span>
          </Button>
        </div>
      </div>

      <div className="d-flex justify-content-center justify-content-sm-end mt-2">
        <Button as={Link} variant="link" to={routeCarQuoteSendEmailPage({ ...params, carPlanId })} target="_blank" rel="noreferrer noopener">
          {t('common:sendQuoteToMyEmail')}
        </Button>
      </div>
    </>
  );
};

export { CarQuoteDetailsHeader };
