import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import moment from 'moment';

import { selectCurrentUser } from '@components/Auth';
import { formatDateTime } from '@utils/datetime';

import { selectCarPolicyClaim } from './selector';
import { FormValues, formValuesSchema } from './schema';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { policy } = useSelector(selectCarPolicyClaim);
  const initialValues: FormValues = formValuesSchema.getDefault() as unknown as FormValues;

  return useMemo(() => {
    if (!currentUser || !policy) return;

    merge(initialValues, {
      insuredPolicyHolder: currentUser.fullName,
      policyNumber: policy.policyNumber,
      policyOrderNumber: policy.orderNumber,
      policyExpiryDate: moment(new Date(formatDateTime(policy.endedAt))).format('YYYY-MM-DDTHH:mm'),
    });

    return initialValues;
  }, [currentUser, policy]);
};
