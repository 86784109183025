import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { cancerOrderFetch, CancerOrderFetchPayload } from './action';
import { selectCancerPolicyDetails } from './selector';

export const useCancerOrderFetch = () => {
  const dispatch = useDispatch();
  const cancerOrderId = Number(useRouteParam('orderableId'));
  const { cancerOrder } = useSelector(selectCancerPolicyDetails);

  useEffectOnce(() => {
    if (cancerOrder && cancerOrder.id === cancerOrderId) return;
    const payload: CancerOrderFetchPayload = { id: cancerOrderId };
    dispatch(cancerOrderFetch(payload));
  });

  return cancerOrder;
};
