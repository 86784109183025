import { InsuranceTypes } from "@models/InsuranceType";
import { getRoute } from "@utils/route";

type RouteParams = {
  insuranceType: InsuranceTypes;
  articleId: number;
};

export const ARTICAL_DETAILS_PAGE_URL = '/articles/:insuranceType/:articleId';

export const routeArticalDetailsPage = (routeParams: RouteParams) => getRoute(ARTICAL_DETAILS_PAGE_URL, { routeParams });
