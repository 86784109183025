import { array, object, string } from 'yup';
import { UserFormValues, userFormValuesSchema } from '@components/Feature/User/UserFormControl';
import { Numeric } from '@utils/typescript';

/* eslint-disable */
export type MeFormValues = UserFormValues & {
  weight: Numeric | '';
  height: Numeric | '';
  bmi: Numeric | '';
  medicalQuestionnaire: {
    question: string;
    answer: 'yes' | 'no';
    remark: string;
  }[];
};

export type MemberFormValues = Omit<MeFormValues, 'gender' | 'email' | 'mobileNumber' | 'address'>;

export type MedicalQuestionnaire = {
  question: string;
  answer: string;
  remark: string;
};

export type FormValues = {
  me: MeFormValues;
  spouse?: MemberFormValues;
  sons?: MemberFormValues[];
  daughters?: MemberFormValues[];
  healthPlanId: Numeric | '';
  isConfirmedDeclaration: boolean;
  activationDate: string;
};

export const meFormValuesParamsSchema = userFormValuesSchema.concat(
  object({
    weight: string().default(''),
    height: string().default(''),
    bmi: string().default(''),
    medicalQuestionnaire: array()
      .of(
        object({
          question: string().default(''),
          answer: string().default(''),
          remark: string().default(''),
        }),
      )
      .default([]),
  }),
);

const memberFormValuesParamsSchema = meFormValuesParamsSchema.omit(['gender', 'email', 'mobileNumber', 'address']);

export const formValuesParamsSchema = object({
  me: meFormValuesParamsSchema,
  spouse: memberFormValuesParamsSchema.default(undefined),
  sons: array().of(memberFormValuesParamsSchema).default([]),
  daughters: array().of(memberFormValuesParamsSchema).default([]),
  healthPlanId: string().default(''),
  activationDate: string().default('')
});
/* eslint-enable */
