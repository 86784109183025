import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tInsuranceTypeName, InsuranceTypes } from '@models/InsuranceType';
import { routeCarInsurancePage } from '@components/CarInsurancePage';
import { routeHealthInsurancePage } from '@components/HealthInsurancePage';
import { routePersonalAccidentInsurancePage } from '@components/PersonalAccidentInsurancePage';
import { routeCancerInsurancePage } from '@components/CancerInsurancePage';
import { routeCorporateInsurancePage } from '@components/CorporateInsurancePage';

import carInsurance from './car-insurance.png';
import healthInsurance from './health-insurance.png';
import personalAccidentInsurance from './personal-accident-insurance.png';
import cancerInsurance from './cancer-insurance.png';

export const useInsuranceProduct = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: t('common:insuranceProducts'),
      items: [
        {
          label: tInsuranceTypeName(InsuranceTypes.Car),
          to: routeCarInsurancePage(),
          image: carInsurance as string,
          visible: true,
        },
        {
          label: tInsuranceTypeName(InsuranceTypes.Health),
          to: routeHealthInsurancePage(),
          image: healthInsurance as string,
          visible: true,
        },
        {
          label: tInsuranceTypeName(InsuranceTypes.PersonalAccident),
          to: routePersonalAccidentInsurancePage(),
          image: personalAccidentInsurance as string,
          visible: true,
        },
        {
          label: tInsuranceTypeName(InsuranceTypes.Cancer),
          to: routeCancerInsurancePage(),
          image: cancerInsurance as string,
          visible: true,
        },
        {
          label: t('common:corporateInsurance'),
          to: routeCorporateInsurancePage(),
          visible: false,
        },
      ],
    }),
    [],
  );
};
