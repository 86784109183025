import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { HealthQuote } from '@models/HealthQuote';
import { amountToUsd } from '@utils/currency';
import { InsurerLogo } from '@components/Feature/Insurer';

type Props = Pick<HealthQuote, 'insurer' | 'annualLimitAmount' | 'totalPremium'> & {
  buyLinkTo: LinkProps['to'];
  sendEmailLinkTo: LinkProps['to'];
  planName: string;
};

const HeaderSection = ({ buyLinkTo, sendEmailLinkTo, insurer, planName, annualLimitAmount, totalPremium }: Props) => {
  const { t } = useTranslation();

  return (
    <section>
      <Container>
        <Card className="shadow">
          <Card.Body>
            <Row className="g-3">
              <Col lg={2} className="d-flex align-items-center justify-content-center">
                <InsurerLogo insurer={insurer} rounded fluid />
              </Col>

              <Col lg={4} className="d-flex flex-column align-items-center justify-content-center">
                <Card.Title className="mb-3 ">{planName}</Card.Title>
                <Card.Text>{insurer.name}</Card.Text>
              </Col>

              <Col lg={3} className="d-flex flex-column align-items-center justify-content-center">
                <Card.Title className="mb-3 ">{t('healthQuoteDetailsPage.coverAmount')}</Card.Title>
                <Card.Text className="fs-5 fw-bold">{amountToUsd(annualLimitAmount)}</Card.Text>
              </Col>

              <Col lg={3} className="d-flex flex-column align-items-center justify-content-center">
                <Button as={Link} to={buyLinkTo} variant="primary" size="lg" className="w-100">
                  <span className="d-block">{t('common:buyNow')}</span>
                  <strong className="fs-5 ms-3">{amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}</strong>
                </Button>
                <Button as={Link} to={sendEmailLinkTo} variant="link" target="_blank" rel="noreferrer noopener">
                  {t('common:sendQuoteToMyEmail')}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export { HeaderSection };
