import React from 'react';
import { Card } from 'react-bootstrap';
import { lowerCase, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { amountToUsd, amountToKhr } from '@utils/currency';
import { HealthQuote } from '@models/HealthQuote';
import { PersonalDetails } from '@models/HealthOrder';

import { tOrdinalItem } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';
import { InsurerLogoWrapper } from '@components/Feature/Insurer';

type Props = Pick<HealthQuote,
  | 'adminFeeAmount'
  | 'totalPremium'
  | 'totalPremiumKhr'
  | 'insurer'
  | 'people'
> & {
  planName: string;
};

const HealthQuotePremiumCardBody = ({
  planName,
  totalPremium,
  totalPremiumKhr,
  adminFeeAmount,
  insurer,
  people: {
    me, spouse, sons, daughters
  },
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <InsurerLogoWrapper insurer={insurer} />

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:policyTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premiumPaymentTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:planType')}:</span>
          <span>{planName}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:adminFee')}:</span>
          <span>{amountToUsd(adminFeeAmount)}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:quoteDetails')}:</span>
          <span>&nbsp;</span>
        </li>

        {me && (
          <li className="d-flex justify-content-between">
            <span>{t('common:myself')}</span>
            <span>{amountToUsd(me.chargeFinalAmount)}</span>
          </li>
        )}

        {spouse && (
          <li className="d-flex justify-content-between">
            <span>{t('common:spouse')}</span>
            <span>{amountToUsd((spouse as unknown as CamelCaseKeys<PersonalDetails>).chargeFinalAmount)}</span>
          </li>
        )}

        {sons &&
          map(sons, (son: CamelCaseKeys<PersonalDetails>, idx) => (
            <li key={`son-${idx}`} className="d-flex justify-content-between">
              <span>{tOrdinalItem(idx + 1, 'common:son')}</span>
              <span>{amountToUsd(son.chargeFinalAmount)}</span>
            </li>
          ))}

        {daughters &&
          map(daughters, (daughter: CamelCaseKeys<PersonalDetails>, idx) => (
            <li key={`daughter-${idx}`} className="d-flex justify-content-between">
              <span>{tOrdinalItem(idx + 1, 'common:daughter')}</span>
              <span>{amountToUsd(daughter.chargeFinalAmount)}</span>
            </li>
          ))}
      </ul>

      <ul className="list-unstyled pb-2 mb-0">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:youWillPay')}:</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </li>
        <li className="d-flex justify-content-between">
          <span>&nbsp;</span>
          <span>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </span>
        </li>
      </ul>
    </Card.Body>
  );
};

export { HealthQuotePremiumCardBody };
