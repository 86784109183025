import React from 'react';
import { useSelector } from 'react-redux';
import { Stack } from 'react-bootstrap';
import { map, isNil } from 'lodash';

import { NoData } from '@components/Base';
import { isEmptyArray } from '@utils/lodash';

import { selectPolicy } from './selector';
import { OrderItem } from './order-item';

const OrderList = () => {
  const { orders } = useSelector(selectPolicy);

  if (isNil(orders)) return null;
  if (isEmptyArray(orders)) return <NoData />;

  return (
    <Stack gap={3}>
      {map(orders, (order) => (
        <OrderItem order={order} key={order.orderNumber} />
      ))}
    </Stack>
  );
};

export { OrderList };
