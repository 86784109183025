import { createActions } from 'redux-actions';

import { GetCancerOrderParams } from '@apis/CancerOrderApi';
import { CancerOrder } from '@models/CancerOrder';

export type State = {
  cancerOrder?: CancerOrder | null;
};

export type CancerOrderFetchPayload = GetCancerOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  cancerOrderFetch,
  stateUpdate,
} = createActions(
  'CANCER_ORDER_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CANCER_POLICY_DETAILS',
  },
);
