import { getRoute } from '@utils/route';

export const GENERAL_QUOTE_BUY_ROUTE_URL = '/general-insurance-quote-buy/*';
export const GENERAL_QUOTE_BUY_NEW_PAGE_URL = '/general-insurance-quote-buy';
export const GENERAL_QUOTE_BUY_REVIEW_PAGE_URL = '/general-insurance-quote-buy/:generalOrderId/review';
export const GENERAL_QUOTE_BUY_EDIT_PAGE_URL = '/general-insurance-quote-buy/:generalOrderId/edit';
export const GENERAL_QUOTE_BUY_LOCK_PAGE_URL = '/general-insurance-quote-buy/:generalOrderId/lock';

type RouteParams = {
  generalOrderId: number;
};

export const routeGeneralQuoteBuyNewPage = () => getRoute(GENERAL_QUOTE_BUY_NEW_PAGE_URL);
export const routeGeneralQuoteBuyReviewPage = (routeParams: RouteParams) => getRoute(GENERAL_QUOTE_BUY_REVIEW_PAGE_URL, { routeParams });
export const routeGeneralQuoteBuyEditPage = (routeParams: RouteParams) => getRoute(GENERAL_QUOTE_BUY_EDIT_PAGE_URL, { routeParams });
export const routeGeneralQuoteBuyLockPage = (routeParams: RouteParams) => getRoute(GENERAL_QUOTE_BUY_LOCK_PAGE_URL, { routeParams });
