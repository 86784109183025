import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { FormGroupControl, FormGroupSelect, FormGroupCheck, Loading, LoadingError } from '@components/Base';
import { CarPlanTypes, tCarPlanType } from '@models/CarPlan';

import { useCarQuoteFetch } from '../hook';
import { carQuoteReset, CarQuoteResetPayload } from '../action';
import { RightCol } from '../right-col';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyResetPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteResetPayload = { ...values, carOrderId };
    dispatch(carQuoteReset(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form>
                <Card>
                  <Card.Body>
                    <FormGroupSelect name="planType" label="Plan type" required groupProps={GROUP_PROPS}>
                      <option value={CarPlanTypes.ThirdParty}>{tCarPlanType(CarPlanTypes.ThirdParty)}</option>
                      <option value={CarPlanTypes.Comprehensive}>{tCarPlanType(CarPlanTypes.Comprehensive)}</option>
                    </FormGroupSelect>

                    <FormGroupControl
                      name="idvAmount"
                      label="IDV amount"
                      required
                      groupProps={GROUP_PROPS}
                      type="number"
                    />

                    <FormGroupCheck name="accidentToDriver" groupProps={GROUP_PROPS} label="Accident to driver" />
                    <FormGroupCheck name="passengerLiability" groupProps={GROUP_PROPS} label="Passenger liability" />

                    <Alert variant="info">Notice: All customizing amounts will be reset.</Alert>
                  </Card.Body>

                  <Card.Footer className="bg-white border-0 mx-auto my-4">
                    <Stack direction="horizontal" gap={3}>
                      <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                        {t('common:cancel')}
                      </Button>

                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        {t('common:update')}
                      </Button>
                    </Stack>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Col>

        <RightCol carQuote={carQuote} />
      </Row>
    </Container >
  );
};

export { CarQuoteBuyResetPage };
