import humps from 'lodash-humps-ts';
import { array, boolean, number, object, string } from 'yup';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { CarQuote, CarQuoteList } from '@models/CarQuote';
import { API_V1_BASE } from '@config';
import { CarPlanCategories, CarPlanTypes } from '@models/CarPlan';
import { QuoteUserParams } from '@models/Quote';
import { Numeric, NumericString, WithParams } from '@utils/typescript';

export type CarQuoteOptionParams = WithParams<{
  car_id: number;
  manufactured_year: number;
  category: CarPlanCategories;
  plan_type: CarPlanTypes;
  idv_amount?: NumericString;
  accident_to_driver?: boolean;
  passenger_liability?: boolean;
}>;

export type GetAllCarQuoteParams = CarQuoteOptionParams;

export const getAllCarQuoteParamsSchema = object({
  carId: number(),
  manufacturedYear: number(),
  idvAmount: number(),
  category: string(),
  planType: string(),
  accidentToDriver: boolean(),
  passengerLiability: boolean(),
});

export type CompareCarQuoteParams = GetAllCarQuoteParams & WithParams<{
  car_plan_ids: number[];
}>;

export const compareCarQuoteParamsSchema = getAllCarQuoteParamsSchema.concat(
  object({
    carPlanIds: array().of(number()).default([]),
  })
);

export type FindCarQuoteParams = GetAllCarQuoteParams & WithParams<{
  car_plan_id: number;
}>;

export const findCarQuoteParamsSchema = getAllCarQuoteParamsSchema.concat(object({
  carPlanId: number(),
}));

export type SendEmailCarQuoteParams = FindCarQuoteParams & WithParams<{
  first_name: string;
  last_name: string;
  email: string;
  note: string;
}>;

type CarQuoteQuoteParams = WithParams<{
  engine_number: string;
  chassis_number: string;
  registration_place: string;
  registration_number: string;
  activation_date: string;
  company_id: number;
}>;

export type CreateCarQuoteParams = FindCarQuoteParams & QuoteUserParams & CarQuoteQuoteParams;

export type GetCarQuoteParams = {
  carOrderId: number;
};

export type UpdateCarQuoteParams = GetCarQuoteParams & QuoteUserParams & CarQuoteQuoteParams;

export type ResetCarQuoteParams = GetCarQuoteParams & WithParams<{
  plan_type: CarPlanTypes;
  idv_amount?: number;
  accident_to_driver?: boolean;
  passenger_liability?: boolean;
}>;

export type CustomCarQuoteParams = GetCarQuoteParams & WithParams<{
  third_party_amount: NumericString;
  third_party_discount_amount: NumericString;
  third_party_deductible_amount: NumericString;
  third_party_deductible_discount_amount: NumericString;
  own_damage_amount: NumericString;
  own_damage_discount_amount: NumericString;
  own_damage_deductible_amount: NumericString;
  own_damage_deductible_discount_amount: NumericString;
  theft_deductible_amount: NumericString;
  theft_deductible_discount_amount: NumericString;
  accident_to_driver_amount: NumericString;
  accident_to_driver_discount_amount: NumericString;
  passenger_liability_amount: NumericString;
  passenger_liability_discount_amount: NumericString;
  admin_fee_amount: NumericString;
  idv_amount: NumericString;
  premium: NumericString;
  no_claim_discount_percentage: Numeric;
  loyalty_discount_percentage: Numeric;
  custom_type: 'lump_sum' | 'custom' | 'no_custom';
  note: string;
  proof: File | undefined;
}>;

export type LockCarQuoteParams = GetCarQuoteParams & WithParams<{
  activation_date: string;
  customer_consent: File | undefined;
}>;

export type PayByCashCarQuoteParams = GetCarQuoteParams & WithParams<{
  agree: boolean;
  proof: File | undefined;
}>;

export class CarQuoteApi {
  static getAll = async (params: GetAllCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_quotes`, { params: snakes(params) })
      .then((response) => humps(response.data) as CarQuoteList[]);
  };

  static compare = async (params: GetAllCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_quotes/compare`, { params: snakes(params) })
      .then((response) => humps(response.data) as CarQuoteList[]);
  };

  static find = async (params: FindCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_quotes/find`, { params: snakes(params) })
      .then((response) => humps(response.data) as CarQuote);
  };

  static sendEmail = async (params: SendEmailCarQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/car_quotes/send_email`, snakes(params));
  };

  static create = async (params: CreateCarQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_quotes`, snakes(params))
      .then((response) => humps(response.data) as CarQuote);
  };

  static get = async ({ carOrderId }: GetCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_quotes/${carOrderId}`)
      .then((response) => humps(response.data) as CarQuote);
  };

  static update = async ({ carOrderId, ...rest }: UpdateCarQuoteParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/user/car_quotes/${carOrderId}`, snakes(rest))
      .then((response) => humps(response.data) as CarQuote);
  };

  static reset = async ({ carOrderId, ...rest }: ResetCarQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/car_quotes/${carOrderId}/reset`, snakes(rest))
      .then((response) => humps(response.data) as CarQuote);
  };

  static custom = async ({ carOrderId, ...rest }: CustomCarQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/car_quotes/${carOrderId}/custom`, snakes(rest))
      .then((response) => humps(response.data) as CarQuote);
  };

  static lock = async ({ carOrderId, ...rest }: LockCarQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/car_quotes/${carOrderId}/lock`, snakes(rest))
      .then((response) => humps(response.data) as CarQuote);
  };

  static payByCash = async ({ carOrderId, ...rest }: PayByCashCarQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/car_quotes/${carOrderId}/pay_by_cash`, snakes(rest))
      .then((response) => humps(response.data) as CarQuote);
  };
}
