import { call, put, takeLatest } from 'redux-saga/effects';
import { Claim } from '@models/Claim';
import { Pagination } from '@models/Pagination';
import { ClaimApi } from '@apis/ClaimApi';
import { SagaPayload } from '@utils/typescript';
import { claimsFetch, ClaimsFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleClaimsFetch({ payload }: SagaPayload<ClaimsFetchPayload>) {
  const state: StateUpdatePayload = { claims: null, pagination: undefined };
  try {
    const [claims, pagination] = (yield call(ClaimApi.getAll, payload)) as [Claim[], Pagination];
    state.claims = claims;
    state.pagination = pagination;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(claimsFetch, handleClaimsFetch);
}
