import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Loading, LoadingError } from '@components/Base';
import { OrderableCard } from '../orderable-card';
import { usePersonalAccidentOrderFetch } from './hook';
import { FeatureCardBody } from '@components/PersonalAccidentQuoteBuyRoute/PersonalAccidentQuoteReviewPage/feature-card-body';
import { QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';
import { BenefitCardBody } from '@components/PersonalAccidentQuoteBuyRoute/PersonalAccidentQuoteReviewPage/benefit-card-body';

const PersonalAccidentPolicyDetails = () => {
  const personalAccidentOrder = usePersonalAccidentOrderFetch();

  if (isUndefined(personalAccidentOrder)) return <Loading />;
  if (isNull(personalAccidentOrder)) return <LoadingError />;

  return (
    <OrderableCard {...personalAccidentOrder}>
      <FeatureCardBody {...personalAccidentOrder} />
      <BenefitCardBody {...personalAccidentOrder} />
      <QuoteExclusionCardBody {...personalAccidentOrder} />
      <QuoteDocumentCardBody {...personalAccidentOrder} />
    </OrderableCard>
  );
};

export { PersonalAccidentPolicyDetails };
