import { pick } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { useParseParams } from '@hooks/params';
import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { usePersonalAccidentQuoteFind } from '@components/PersonalAccidentQuoteDetailsRoute';

import { formValuesParamsSchema, FormValues, meFormValuesParamsSchema } from '../PersonalAccidentQuoteBuyForm';
import { appendPersonalAccidentPlanQuestionsToFormValues } from '@utils/personal-accident-quote';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const personalAccidentQuote = usePersonalAccidentQuoteFind();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!currentUser) return;
    appendPersonalAccidentPlanQuestionsToFormValues(initialValues, personalAccidentQuote?.personalAccidentPlanQuestions);
    return mergeInitialValues(initialValues, {
      isConfirmedDeclaration: false,
      me: isCustomer ? pick(currentUser, getFieldKeys(meFormValuesParamsSchema)) : undefined,
    });
  }, [currentUser, personalAccidentQuote]);
};
