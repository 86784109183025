import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { FormGroupCheck, FormGroupControl, Loading, LoadingError } from '@components/Base';

import { useCarQuoteFetch } from '../hook';
import { carQuotePayByCash, CarQuotePayByCashPayload } from '../action';
import { RightCol } from '../right-col';
import { useInitialValues, useValidationSchema } from './hook';
import { FormValues } from './type';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyPayByCashPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuotePayByCashPayload = { carOrderId, ...values };
    dispatch(carQuotePayByCash(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {() => (
              <Form>
                <Card>
                  <Card.Body>
                    <FormGroupCheck name="agree" groupProps={GROUP_PROPS} label="Mark as paid" />

                    <FormGroupControl name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />

                    <p>Notice: A receipt email will be sent to customer.</p>
                  </Card.Body>

                  <Card.Footer className="bg-white border-0 mx-auto my-4">
                    <Stack direction="horizontal" gap={3}>
                      <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                        {t('common:cancel')}
                      </Button>

                      <Button type="submit" variant="primary" size="lg">
                        Pay By Cash
                      </Button>
                    </Stack>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Col>

        <RightCol carQuote={carQuote} />
      </Row>
    </Container>
  );
};

export { CarQuoteBuyPayByCashPage };
