import { ArticleApi } from '@apis/ArticleApi';
import { Article } from '@models/Article';
import { SagaPayload } from '@utils/typescript';
import { call, put, takeLatest } from 'redux-saga/effects';
import { articleFetch, ArticleFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleArticleFetch({ payload }: SagaPayload<ArticleFetchPayload>) {
  const state: StateUpdatePayload = { article: null };
  try {
    state.article = (yield call(ArticleApi.get, payload)) as Article;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(articleFetch, handleArticleFetch);
}
