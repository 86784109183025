import { getRoute } from '@utils/route';

type RouteParams = {
  healthOrderId: number;
  billingId: number;
};

export const HEALTH_ORDER_PAYMENT_PAGE_URL = '/health-insurance-quote-payment/:healthOrderId/:billingId';

export const routeHealthOrderPaymentPage = (routeParams: RouteParams) => getRoute(HEALTH_ORDER_PAYMENT_PAGE_URL, { routeParams });
