import React from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { GeneralQuote } from '@models/GeneralQuote';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <Card.Link className="c-pointer link-info" onClick={handleClick}>
      {children}
    </Card.Link>
  );
};

type Props = Pick<GeneralQuote, 'descriptionHtml'>;

const GeneralQuoteDescriptionCardBody = ({ descriptionHtml }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Accordion flush>
        <Card.Title>
          <Toggle eventKey="0">{t('common:description')}</Toggle>
        </Card.Title>
        <Accordion.Collapse eventKey="0">
          <>{parse(descriptionHtml)}</>
        </Accordion.Collapse>
      </Accordion>
    </Card.Body>
  );
};

export { GeneralQuoteDescriptionCardBody };
