import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { FormGroupControl } from '@components/Base';
import { selectIsFrontline } from '@components/Auth';

const GROUP_PROPS = { className: 'mb-3' };

type Props = Omit<ComponentProps<typeof FormGroupControl>, 'name'>;

const ActivationDateControl = ({
  min = moment().format('YYYY-MM-DD'),
  max = moment().add(3, 'month').format('YYYY-MM-DD'),
  ...props
}: Props) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);

  return (
    <FormGroupControl
      min={isFrontline ? undefined : min}
      max={isFrontline ? undefined : max}
      type="date"
      label={t('common:effectiveDate')}
      groupProps={GROUP_PROPS}
      name="activationDate"
      {...props}
    />
  );
};

export { ActivationDateControl };
