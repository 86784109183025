

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

type Props = {
  orderEncryptedId: string;
}

const OrderInvoiceLink = ({ orderEncryptedId }: Props) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`/invoices/${orderEncryptedId}.pdf`}
      target="_blank"
      className="d-inline-block my-3"
    >
      <FontAwesomeIcon icon={faDownload} className="me-2" />
      {t('common:downloadInvoice')}
    </Link>
  );
};

export { OrderInvoiceLink };
