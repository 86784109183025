import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { paginate } from '@models/Pagination';
import { Pagination, PaginationParams } from '@models/Pagination';
import { UserCar } from '@models/UserCar';

export type GetAllUserCarParams = PaginationParams;

export interface GetUserCarParams {
  id: number;
}

export interface ReviewUserCarParams extends GetUserCarParams {
  identificationCard: File;
}

export class UserCarApi {
  static getAll = async (params = {} as GetAllUserCarParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/user_cars`, { params: snakes(params) })
      .then((response) => paginate(response) as [UserCar[], Pagination]);
  };

  static get = async ({ id }: GetUserCarParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/user_cars/${id}`)
      .then((response) => humps(response.data) as UserCar);
  };

  static review = async (params: ReviewUserCarParams) => {
    const { id, ...reviewParams } = params;

    return await authAxios
      .putForm(`${API_V1_BASE}/user/user_cars/${id}/review`, snakes(reviewParams))
      .then((response) => humps(response.data) as UserCar);
  };
}
