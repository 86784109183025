import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { InsurerLogo } from '../insurer-logo';
import style from './style.module.scss';

type Props = ComponentProps<typeof InsurerLogo>;

const InsurerLogoWrapper = (props: Props) => {
  return (
    <div className={classNames('position-relative mb-4', style.wrapper)}>
      <InsurerLogo {...props} className={classNames('position-absolute top-50 start-0 translate-middle-y', style.logo)} />
    </div>

  );
};

export { InsurerLogoWrapper };
