import React from 'react';
import { map, startCase } from 'lodash';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { InsuranceTypes, tInsuranceType } from '@models/InsuranceType';
import { routeArticlePage } from '@components/ArticlePage';

const ArticleNavDropdown = () => {
  const { t } = useTranslation();
  const insuranceTypes = Object.values(InsuranceTypes);

  return (
    <NavDropdown title={startCase(t('common:articles'))}>
      <NavDropdown.Item as={Link} to={routeArticlePage()}>{t('common:all')}</NavDropdown.Item>

      {map(insuranceTypes, (insuranceType) => (
        <NavDropdown.Item key={insuranceType} as={Link} to={routeArticlePage({ insuranceType })}>
          {startCase(tInsuranceType(insuranceType))}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export { ArticleNavDropdown };
