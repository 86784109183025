import { all, fork } from 'redux-saga/effects';
import { carPolicyDetailsSaga } from './CarPolicyDetails';
import { cancerPolicyDetailsSaga } from './CancerPolicyDetails';
import { generalPolicyDetailsSaga } from './GeneralPolicyDetails';
import { healthPolicyDetailsSaga } from './HealthPolicyDetails';
import { personalAccidentPolicyDetailsSaga } from './PersonalAccidentPolicyDetails';

export default function* () {
  yield all([
    fork(carPolicyDetailsSaga),
    fork(cancerPolicyDetailsSaga),
    fork(generalPolicyDetailsSaga),
    fork(healthPolicyDetailsSaga),
    fork(personalAccidentPolicyDetailsSaga),
  ]);
}
