import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { getUserCarStatusVariant, UserCar } from '@models/UserCar';
import { tFuelType } from '@models/Car';
import { RowItem, VehicleBadge } from '@components/Base';

const ROW_ITEM_PROPS = {
  className: 'mb-2',
  labelProps: { xs: 7, sm: 7, md: 4, lg: 3 },
};

interface Props {
  userCar: UserCar;
}

const UserCarItem = ({ userCar }: Props) => {
  const { t } = useTranslation();
  const {
    id,
    status,
    manufacturedYear,
    registrationPlace,
    registrationNumber,
    chassisNumber,
    engineNumber,
    engineCapacity,
    fuelType,
    name,
  } = userCar;

  const statusVariant = getUserCarStatusVariant(status);

  return (
    <Card border={statusVariant}>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
        <h5 className="mb-0">{name}</h5>
        <VehicleBadge status={status} />
      </Card.Header>
      <Card.Body>
        <RowItem {...ROW_ITEM_PROPS} label={t('car.yearOfManufacture')} value={manufacturedYear} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.registrationPlace')} value={registrationPlace} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.registrationNumber')} value={registrationNumber} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.chassisNumber')} value={chassisNumber} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.engineNumber')} value={engineNumber} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.engineCapacity')} value={engineCapacity} />
        <RowItem {...ROW_ITEM_PROPS} label={t('car.fuelType')} value={tFuelType(fuelType)} />

        <div className="d-flex flex-row mt-3">
          <Button as={Link} to={id.toString()} variant="outline-primary">
            {startCase(t('common:viewDetails'))}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export { UserCarItem };
