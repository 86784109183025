import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CarQuote, CarQuotePremium } from '@models/CarQuote';
import { AmountWithDiscount } from '@components/Base';
import { CarPlanTypes } from '@models/CarPlan';

type Props = CarQuotePremium & Pick<CarQuote,
  | 'thirdPartyDeductibleDescription'
  | 'ownDamageDeductibleDescription'
  | 'theftDeductibleDescription'
  | 'planType'
>;

const CarQuoteDeductibleCardBody = ({
  thirdPartyDeductibleAmount,
  thirdPartyDeductibleDiscountAmount,
  thirdPartyDeductibleFinalAmount,
  thirdPartyDeductibleDescription,
  ownDamageDeductibleAmount,
  ownDamageDeductibleDiscountAmount,
  ownDamageDeductibleFinalAmount,
  ownDamageDeductibleDescription,
  theftDeductibleAmount,
  theftDeductibleDiscountAmount,
  theftDeductibleFinalAmount,
  theftDeductibleDescription,
  planType,
}: Props) => {
  const isComprehensive = planType === CarPlanTypes.Comprehensive;
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceQuote.deductibleTitle')}</Card.Title>
      <small className="text-secondary">{t('carInsuranceQuote.deductibleDescription')}</small>
      <ListGroup variant="flush">
        {thirdPartyDeductibleDescription && (
          <ListGroup.Item>
            {!!Number(thirdPartyDeductibleAmount) && (
              <AmountWithDiscount
                amount={thirdPartyDeductibleAmount}
                discountAmount={thirdPartyDeductibleDiscountAmount}
                finalAmount={thirdPartyDeductibleFinalAmount}
              />
            )}{' '}
            {thirdPartyDeductibleDescription}
          </ListGroup.Item>
        )}

        {isComprehensive && ownDamageDeductibleDescription && (
          <ListGroup.Item>
            {!!Number(ownDamageDeductibleAmount) && (
              <AmountWithDiscount
                amount={ownDamageDeductibleAmount}
                discountAmount={ownDamageDeductibleDiscountAmount}
                finalAmount={ownDamageDeductibleFinalAmount}
              />
            )}{' '}
            {ownDamageDeductibleDescription}
          </ListGroup.Item>
        )}

        {isComprehensive && theftDeductibleDescription && (
          <ListGroup.Item>
            {!!Number(theftDeductibleAmount) && (
              <AmountWithDiscount
                amount={theftDeductibleAmount}
                discountAmount={theftDeductibleDiscountAmount}
                finalAmount={theftDeductibleFinalAmount}
              />
            )}{' '}
            {theftDeductibleDescription}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card.Body>
  );
};

export { CarQuoteDeductibleCardBody };
