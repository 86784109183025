import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import parse from 'html-react-parser';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

import { Title } from './title';

const BenefitSection = ({ benefitScheduleHtml }: Pick<PersonalAccidentQuote, 'benefitScheduleHtml'>) => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <Title>{pluralize(t('common:benefit'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Card>
              <Card.Body>{parse(benefitScheduleHtml)}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { BenefitSection };
