import React, { useCallback } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { UserAvatar } from '@components/Feature/User/user-avatar';
import { FormGroupControl } from '@components/Base';
import { ServerError } from '@components/Helper';
import { SignInWithOtpCodeAuthParams } from '@apis/AuthApi';
import { camDxLogin } from '@components/CamDxPage/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import i18n from '@components/i18n';

import { otpCodeSignIn, passwordSignIn } from './action';
import { selectMobileNumber } from './selector';

export type FormValues = SignInWithOtpCodeAuthParams;

enum SubmitAction {
  OtpCode = 'OtpCode',
  Password = 'Password',
}

const validationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required(i18n.t('validation:required'))
    .phone('KH', i18n.t('validation:validPhone', { countryName: i18n.t('common:cambodia') })),
});

const initialValues: FormValues = {
  mobileNumber: '',
};

const MobileNumberCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errors } = useSelector(selectMobileNumber);
  const isServerError = !isNil(errors.mobileNumber);
  let submitAction: SubmitAction;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    switch (submitAction) {
      case SubmitAction.OtpCode:
        dispatch(otpCodeSignIn(values));
        break;
      case SubmitAction.Password:
        dispatch(passwordSignIn(values));
        break;
      default:
        break;
    }
    setSubmitting(false);
  };

  const handleActionClick = (action: SubmitAction, onSubmit: () => void) => {
    submitAction = action;
    onSubmit();
  };

  const handleCamDxSignIn = useCallback(() => {
    dispatch(camDxLogin());
  }, []);

  return (
    <Card>
      <Card.Header className="text-center">
        <UserAvatar />
      </Card.Header>
      <Card.Body>
        <Card.Title>{t('registration.title')}</Card.Title>
        <Card.Text>{t('registration.desc')}</Card.Text>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={handleSubmit}
        >
          {({ errors: formikErrors, values, isSubmitting, handleSubmit: onSubmit }) => {
            const shouldButtonBeDisabled =
              isSubmitting || (!isNil(formikErrors.mobileNumber) && !isServerError) || values.mobileNumber === '';

            return (
              <Form>
                <FormGroupControl
                  type="tel"
                  name="mobileNumber"
                  label={t('registration.mobileNumberLabel')}
                  groupProps={{ className: 'mb-3' }}
                />

                <div className="d-grid gap-2">
                  <Button
                    disabled={shouldButtonBeDisabled}
                    onClick={() => handleActionClick(SubmitAction.OtpCode, onSubmit)}
                  >
                    {t('registration.ctaOtp')}
                  </Button>

                  <Button
                    disabled={shouldButtonBeDisabled}
                    onClick={() => handleActionClick(SubmitAction.Password, onSubmit)}
                    variant="outline-dark"
                  >
                    {t('registration.ctaPassword')}
                  </Button>
                </div>

                <Card.Subtitle className="my-3 text-center">
                  <small className="text-muted">{t('registration.continueWith')}</small>
                </Card.Subtitle>

                <div className="d-grid">
                  <Button onClick={handleCamDxSignIn} variant="outline-primary">
                    <FontAwesomeIcon icon={faKey} className="me-2" />
                    {t('registration.ctaCamDx')}
                  </Button>
                  {errors.camDx && <Alert variant="danger">{errors.camDx}</Alert>}
                </div>
                <ServerError errors={errors} />
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { MobileNumberCard };
