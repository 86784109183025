import { call, put, takeLatest } from 'redux-saga/effects';

import { OrderTemplate } from '@models/OrderTemplate';
import { OrderTemplateApi } from '@apis/OrderTemplateApi';

import {
  orderTemplatesFetch,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleOrderTemplatesFetch() {
  const state: StateUpdatePayload = { orderTemplates: [] };
  try {
    state.orderTemplates = (yield call(OrderTemplateApi.getAll)) as OrderTemplate[];
    yield put(stateUpdate(state));
  } catch { }
}

export default function* () {
  yield takeLatest(orderTemplatesFetch, handleOrderTemplatesFetch);
}
