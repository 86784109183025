import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from '@utils/history';
import { CarQuote } from '@models/CarQuote';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { CarOrder } from '@models/CarOrder';
import { CarOrderApi } from '@apis/CarOrderApi';
import { SagaPayload } from '@utils/typescript';
import { routeCarOrderPaymentPageRoute } from '@components/CarOrderPaymentPage';

import {
  carQuoteFind,
  CarQuoteFindPayload,
  carQuoteCreate,
  CarQuoteCreatePayload,
  carQuoteFetch,
  CarQuoteFetchPayload,
  carQuoteUpdate,
  CarQuoteUpdatePayload,
  carQuoteReset,
  CarQuoteResetPayload,
  carQuoteCustom,
  CarQuoteCustomPayload,
  carQuoteLock,
  CarQuoteLockPayload,
  carQuotePayByCash,
  CarQuotePayByCashPayload,
  carOrderPay,
  CarOrderPayPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { routeCarQuoteBuyReviewPage } from './route';

function* handleCarQuoteFind({ payload }: SagaPayload<CarQuoteFindPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.find, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteCreate({ payload }: SagaPayload<CarQuoteCreatePayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.create, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteFetch({ payload }: SagaPayload<CarQuoteFetchPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.get, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteUpdate({ payload }: SagaPayload<CarQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.update, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteReset({ payload }: SagaPayload<CarQuoteResetPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.reset, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteCustom({ payload }: SagaPayload<CarQuoteCustomPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.custom, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteLock({ payload }: SagaPayload<CarQuoteLockPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.lock, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: payload.carOrderId }));
  } catch { }
}

function* handleCarQuotePayByCash({ payload }: SagaPayload<CarQuotePayByCashPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.payByCash, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(routeCarQuoteBuyReviewPage({ carOrderId: payload.carOrderId }));
  } catch { }
}

function* handleOrderCreate({ payload }: SagaPayload<CarOrderPayPayload>) {
  try {
    const { id, billingId } = (yield call(CarOrderApi.pay, payload)) as CarOrder;
    history.navigate(routeCarOrderPaymentPageRoute({ carOrderId: id, billingId }));
  } catch { }
}

export default function* () {
  yield takeLatest(carQuoteFind, handleCarQuoteFind);
  yield takeLatest(carQuoteCreate, handleCarQuoteCreate);
  yield takeLatest(carQuoteFetch, handleCarQuoteFetch);
  yield takeLatest(carQuoteUpdate, handleCarQuoteUpdate);
  yield takeLatest(carQuoteReset, handleCarQuoteReset);
  yield takeLatest(carQuoteCustom, handleCarQuoteCustom);
  yield takeLatest(carQuoteLock, handleCarQuoteLock);
  yield takeLatest(carQuotePayByCash, handleCarQuotePayByCash);
  yield takeLatest(carOrderPay, handleOrderCreate);
}
