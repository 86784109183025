import { createActions } from 'redux-actions';
import { FindCarQuoteParams, SendEmailCarQuoteParams } from '@apis/CarQuoteApi';
import { CarQuote } from '@models/CarQuote';

export type State = {
  carQuote?: CarQuote | null;
};

export type CarQuoteFindPayload = FindCarQuoteParams;
export type CarQuoteSendEmailPayload = SendEmailCarQuoteParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carQuoteFind,
  carQuoteSendEmail,
  stateUpdate,
} = createActions(
  'CAR_QUOTE_FIND',
  'CAR_QUOTE_SEND_EMAIL',
  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_DETAILS_ROUTE',
  },
);
