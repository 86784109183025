import React from 'react';
import { isUndefined, isNull } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, LoadingError, BackButton } from '@components/Base';
import SupportSection from '@components/SupportSection';
import { useCancerQuoteResultPageParams, routeCancerQuoteResultPage } from '@components/CancerQuoteResultPage';
import { routeCancerQuoteBuyNewPage } from '@components/CancerQuoteBuyRoute';

import { useCancerQuoteFind } from '../hook';
import { routeCancerQuoteSendEmailPage } from '../route';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';

import { HeaderSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/header-section';
import { ExclusionSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/exclusion-section';
import { DocumentSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/document-section';
import { HospitalSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/hospital-section';
import { MemberSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/member-section';

const CancerQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const cancerQuote = useCancerQuoteFind();

  if (isUndefined(cancerQuote)) return <Loading />;
  if (isNull(cancerQuote)) return <LoadingError />;

  const { cancerPlanId, cancerPlanName } = cancerQuote;

  const handleBackClick = () => {
    navigate(routeCancerQuoteResultPage(params), { replace: true });
  };

  return (
    <>
      <Container className="mt-4">
        <BackButton onClick={handleBackClick} className="mb-4" />
      </Container>

      <HeaderSection
        {...cancerQuote}
        planName={cancerPlanName}
        buyLinkTo={routeCancerQuoteBuyNewPage({ ...params, cancerPlanId })}
        sendEmailLinkTo={routeCancerQuoteSendEmailPage({ ...params, cancerPlanId })}
      />
      <FeatureSection {...cancerQuote} />
      <BenefitSection {...cancerQuote} />
      <ExclusionSection {...cancerQuote} />
      <DocumentSection {...cancerQuote} />
      <HospitalSection {...cancerQuote} />
      <MemberSection {...params} pathname='/cancer-insurance-quote/members' />

      <SupportSection />
    </>
  );
};

export { CancerQuoteDetailsPage };
