import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { GeneralQuote } from '@models/GeneralQuote';
import { API_V1_BASE } from '@config';
import { NumericString, Numeric, WithParams } from '@utils/typescript';
import { QuoteUserParams } from '@models/Quote';

export type CreateGeneralQuoteParams = QuoteUserParams & WithParams<{
  insurer_id: Numeric;
  order_template_id: Numeric;
  activation_date: string;
  admin_fee_amount: NumericString;
  premium: NumericString;
  description_html: string;
  note: string;
  proof: File | undefined;
}>;

export type GetGeneralQuoteParams = WithParams<{
  general_order_id: Numeric;
}>;

export type UpdateGeneralQuoteParams = GetGeneralQuoteParams & CreateGeneralQuoteParams;

export type LockGeneralQuoteParams = GetGeneralQuoteParams & Pick<CreateGeneralQuoteParams, 'activationDate'>;

export class GeneralQuoteApi {
  static create = async (params: CreateGeneralQuoteParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/general_quotes`, snakes(params))
      .then((response) => humps(response.data) as GeneralQuote);
  };

  static get = async ({ generalOrderId }: GetGeneralQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/general_quotes/${generalOrderId}`)
      .then((response) => humps(response.data) as GeneralQuote);
  };

  static update = async ({ generalOrderId, ...rest }: GetGeneralQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/general_quotes/${generalOrderId}`, snakes(rest))
      .then((response) => humps(response.data) as GeneralQuote);
  };

  static lock = async ({ generalOrderId, ...rest }: LockGeneralQuoteParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/user/general_quotes/${generalOrderId}/lock`, snakes(rest))
      .then((response) => humps(response.data) as GeneralQuote);
  };
}
