import '@scss/application.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "trix/dist/trix";

import React from 'react';
import { render } from 'react-dom';
import App from '@components/App';

document.addEventListener('DOMContentLoaded', () => {
  render(<App />, document.body.appendChild(document.createElement('div')));
});
