import { array, boolean, object, string } from 'yup';

export const peopleParamsSchema = object({
  me: object({
    gender: string(),
    dob: string(),
  }),
  spouse: object({
    dob: string(),
  }).default(undefined),
  sons: array().of(
    object({
      dob: string(),
    }),
  ),
  daughters: array().of(
    object({
      dob: string(),
    }),
  ),
});

export const healthQuotesFilterParamsSchema = object({
  ipd: boolean().default(false),
  opd: boolean().default(false),
  healthCheckup: boolean().default(false),
  dental: boolean().default(false),
  maternity: boolean().default(false),
  vision: boolean().default(false),
});

export const healthQuoteResultPageParamsSchema = peopleParamsSchema.concat(healthQuotesFilterParamsSchema).concat(
  object({
    activationDate: string().default('')
  })
);
