import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import CancerQuoteBuyNewPage from './CancerQuoteBuyNewPage';
import CancerQuoteReviewPage from './CancerQuoteReviewPage';
import CancerQuoteBuyEditPage from './CancerQuoteBuyEditPage';
import CancerQuoteBuyCustomPage from './CancerQuoteBuyCustomPage';

const CancerQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route path="/*" element={<CancerQuoteBuyNewPage />} />
        <Route path=":cancerOrderId/edit/*" element={<CancerQuoteBuyEditPage />} />
        <Route path=":cancerOrderId/review" element={<CancerQuoteReviewPage />} />
        <Route path=":cancerOrderId/custom" element={<CancerQuoteBuyCustomPage />} />
      </Routes>
    </>
  );
};

export { CancerQuoteBuyRoute };
