import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { personalAccidentOrderFetch, PersonalAccidentOrderFetchPayload } from './action';
import { selectPersonalAccidentPolicyDetails } from './selector';

export const usePersonalAccidentOrderFetch = () => {
  const dispatch = useDispatch();
  const personalAccidentOrderId = Number(useRouteParam('orderableId'));
  const { personalAccidentOrder } = useSelector(selectPersonalAccidentPolicyDetails);

  useEffectOnce(() => {
    if (personalAccidentOrder && personalAccidentOrder.id === personalAccidentOrderId) return;
    const payload: PersonalAccidentOrderFetchPayload = { id: personalAccidentOrderId };
    dispatch(personalAccidentOrderFetch(payload));
  });

  return personalAccidentOrder;
};
