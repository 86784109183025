import { HealthQuoteResultPageParams } from '@components/HealthQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type HealthQuoteComparePageParams = Required<Omit<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>> &
  Pick<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    healthPlanIds: number[];
  };

export const HEALTH_QUOTE_COMPARE_PAGE_URL = '/health-insurance-quote-compare';

export const routeHealthQuoteComparePage = (searchParams: HealthQuoteComparePageParams) => getRoute(HEALTH_QUOTE_COMPARE_PAGE_URL, { searchParams });
