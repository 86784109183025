import { stringify } from 'qs';
import { keys } from 'lodash';

const replaceRouteParams = (url: string, params: Record<string, string | number>): string => {
  let resultUrl = url;
  keys(params).forEach((param) => {
    resultUrl = resultUrl.replace(`:${param}`, params[param].toString());
  });
  return resultUrl;
};

type GetRuoteOptions = {
  routeParams?: Record<string, string | number>;
  searchParams?: object;
}

export const getRoute = (pathname: string, options: GetRuoteOptions = {}) => {
  const { routeParams, searchParams } = options;
  return {
    pathname: routeParams ? replaceRouteParams(pathname, routeParams) : pathname,
    search: stringify(searchParams),
  };
};
