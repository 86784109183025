import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { FormGroupControl, FormGroupSelect, Loading, LoadingError } from '@components/Base';
import { CarPlanTypes } from '@models/CarPlan';

import { useCarQuoteFetch } from '../hook';
import { carQuoteCustom, CarQuoteCustomPayload } from '../action';
import { RightCol } from '../right-col';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';

const GROUP_PROPS = { className: 'mb-3' };

const AmountControl = ({ name, title, disabled }: { name: string; title: string; disabled?: boolean }) => {
  return (
    <fieldset className="mb-4 pb-2 border-bottom">
      <legend className="h6">{title}</legend>

      <Row>
        <Col>
          <FormGroupControl
            name={`${name}Amount`}
            label="Premium"
            required
            groupProps={GROUP_PROPS}
            type="number"
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormGroupControl
            name={`${name}DiscountAmount`}
            label="Discount"
            required
            groupProps={GROUP_PROPS}
            type="number"
            disabled={disabled}
          />
        </Col>
      </Row>
    </fieldset>
  );
};

const CarQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { planType, accidentToDriver, passengerLiability, carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteCustomPayload = { ...values, carOrderId };
    dispatch(carQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values }) => (
              <Form>
                <Card>
                  <Card.Body>
                    <FormGroupSelect name="customType" label="Custom type" required groupProps={GROUP_PROPS}>
                      <option value="no_custom">No Custom</option>
                      <option value="custom">Custom</option>
                      <option value="lump_sum">Lump sum</option>
                    </FormGroupSelect>

                    <AmountControl
                      name="thirdParty"
                      title={t('common:tpl')}
                      disabled={values.customType !== 'custom'}
                    />

                    {planType === CarPlanTypes.Comprehensive && (
                      <AmountControl
                        name="ownDamage"
                        title={t('common:od')}
                        disabled={values.customType !== 'custom'}
                      />
                    )}

                    <AmountControl
                      name="thirdPartyDeductible"
                      title="Third-party deductible"
                      disabled={values.customType === 'no_custom'}
                    />

                    {planType === CarPlanTypes.Comprehensive && (
                      <>
                        <AmountControl
                          name="ownDamageDeductible"
                          title="Own damage deductible"
                          disabled={values.customType === 'no_custom'}
                        />
                        <AmountControl
                          name="theftDeductible"
                          title="Theft deductible"
                          disabled={values.customType === 'no_custom'}
                        />
                      </>
                    )}

                    {accidentToDriver && (
                      <AmountControl
                        name="accidentToDriver"
                        title={t('common:ad')}
                        disabled={values.customType !== 'custom'}
                      />
                    )}

                    {passengerLiability && (
                      <AmountControl
                        name="passengerLiability"
                        title={t('common:pl')}
                        disabled={values.customType !== 'custom'}
                      />
                    )}

                    {planType === CarPlanTypes.Comprehensive && (
                      <FormGroupControl
                        name="idvAmount"
                        label="IDV Amount"
                        required
                        groupProps={GROUP_PROPS}
                        type="number"
                        disabled={values.customType === 'no_custom'}
                      />
                    )}

                    <FormGroupControl
                      name="premium"
                      label="Premium"
                      required
                      groupProps={GROUP_PROPS}
                      type="number"
                      disabled={values.customType !== 'lump_sum'}
                    />

                    <FormGroupControl
                      name="adminFeeAmount"
                      label="Admin fee"
                      required
                      groupProps={GROUP_PROPS}
                      type="number"
                      disabled={values.customType === 'no_custom'}
                    />

                    <FormGroupControl
                      required
                      name="noClaimDiscountPercentage"
                      label="No claim discount (%)"
                      groupProps={GROUP_PROPS}
                      type="number"
                    />

                    <FormGroupControl
                      required
                      name="loyaltyDiscountPercentage"
                      label="Loyalty discount (%)"
                      groupProps={GROUP_PROPS}
                      type="number"
                    />

                    <FormGroupControl required name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />

                    <FormGroupControl required name="note" label="Note" groupProps={GROUP_PROPS} as="textarea" rows={4} />
                  </Card.Body>

                  <Card.Footer className="bg-white border-0 mx-auto my-4">
                    <Stack direction="horizontal" gap={3}>
                      <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                        {t('common:cancel')}
                      </Button>

                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        {t('common:update')}
                      </Button>
                    </Stack>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Col>

        <RightCol carQuote={carQuote} />
      </Row>
    </Container>
  );
};

export { CarQuoteBuyCustomPage };
