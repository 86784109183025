import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mergeInitialValues } from '@utils/formik';

import { FormValues } from '../CancerQuoteBuyForm';
import { selectCancerQuoteBuyRoute } from '../selector';
import { Genders } from '@models/User';

export const useInitialValues = () => {
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);
  const initialValues: FormValues = {
    me: {
      firstName: '',
      lastName: '',
      firstNameKm: '',
      lastNameKm: '',
      gender: Genders.Male,
      dob: '',
      personalCode: '',
      occupation: '',
      email: '',
      noMobileNumber: false,
      mobileNumber: '',
      address: '',
      medicalQuestionnaire: [],
    },
    spouse: undefined,
    daughters: [],
    sons: [],
    activationDate: '',
    cancerPlanId: '',
    isConfirmedDeclaration: false,
  }

  return useMemo(() => {
    if (!cancerQuote) return;
    return mergeInitialValues(initialValues, { ...cancerQuote, ...cancerQuote.people });
  }, [cancerQuote]);
};
