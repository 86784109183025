import humps from 'lodash-humps-ts';

import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { Billing, BillingList } from '@models/Billing';
import { API_V1_BASE } from '@config';
import { paginate } from '@models/Pagination';
import { Pagination, PaginationParams } from '@models/Pagination';

export type GetAllBillingParams = PaginationParams;
export type GetBillingParams = {
  id: number;
};

export class BillingApi {
  static getAll = async (params = {} as GetAllBillingParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/billings`, { params: snakes(params) })
      .then((response) => paginate(response) as [BillingList[], Pagination]);
  };

  static get = async ({ id }: GetBillingParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/billings/${id}`)
      .then((response) => humps(response.data) as Billing);
  };
}
