import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { FeaturedPersonalAccidentPlanBenefits } from '@models/PersonalAccidentPlanBenefit';
import { getPersonalAccidentPlanBenefitDescription } from '@utils/personal-accident-quote';
import { amountToUsd } from '@utils/currency';

import { Title } from './title';

import { StretchRowItem } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/feature-section';

const FeatureSection = ({
  geographicCoverage,
  personalAccidentPlanBenefits,
  annualLimitAmount,
}: PersonalAccidentQuote) => {
  const { t } = useTranslation();
  const permanentAndPartialDisablementDescription = getPersonalAccidentPlanBenefitDescription(
    personalAccidentPlanBenefits,
    FeaturedPersonalAccidentPlanBenefits.PermanentAndPartialDisablementDueToAccident,
  );

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Card>
              <Card.Body>
                <StretchRowItem label={t('personalAccidentQuote.geographicCoverage')} value={geographicCoverage} />
                <StretchRowItem label={t('personalAccidentQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
                <StretchRowItem
                  label={t('personalAccidentPlanBenefit.basicBenefitsDeathDueToAccident')}
                  value={getPersonalAccidentPlanBenefitDescription(
                    personalAccidentPlanBenefits,
                    FeaturedPersonalAccidentPlanBenefits.DeathDueToAccident,
                  )}
                />
                <StretchRowItem
                  label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndTotalDisablementDueToAccident')}
                  value={getPersonalAccidentPlanBenefitDescription(
                    personalAccidentPlanBenefits,
                    FeaturedPersonalAccidentPlanBenefits.PermanentAndTotalDisablementDueToAccident,
                  )}
                />
                {!!permanentAndPartialDisablementDescription && (
                  <StretchRowItem
                    label={t(
                      'personalAccidentPlanBenefit.basicBenefitsPermanentAndPartialDisablementDueToAccident',
                    )}
                    value={permanentAndPartialDisablementDescription}
                  />
                )}
                <StretchRowItem
                  label={t('personalAccidentPlanBenefit.basicBenefitsMedicalExpenseDueToAccident')}
                  value={getPersonalAccidentPlanBenefitDescription(
                    personalAccidentPlanBenefits,
                    FeaturedPersonalAccidentPlanBenefits.MedicalExpenseDueToAccident,
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
    </section >
  );
};

export { FeatureSection };
