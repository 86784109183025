import { getRoute } from "@utils/route";

export const HEALTH_QUOTE_ROUTE_URL = '/health-insurance-quote/*';
export const HEALTH_QUOTE_PAGE_URL = '/health-insurance-quote';

export const routeHealthQuotePage = () => getRoute(HEALTH_QUOTE_PAGE_URL);

export const CANCER_QUOTE_ROUTE_URL = '/cancer-insurance-quote/*';
export const CANCER_QUOTE_PAGE_URL = '/cancer-insurance-quote';

export const routeCancerQuotePage = () => getRoute(CANCER_QUOTE_PAGE_URL);

export const PERSONAL_ACCIDENT_QUOTE_ROUTE_URL = '/personal-accident-insurance-quote/*';
export const PERSONAL_ACCIDENT_QUOTE_PAGE_URL = '/personal-accident-insurance-quote';

export const routePersonalAccidentQuotePage = () => getRoute(PERSONAL_ACCIDENT_QUOTE_PAGE_URL);
