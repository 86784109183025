import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { isUndefined, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Loading, Pagination, LoadingError } from '@components/Base';
import { isPaginated } from '@models/Pagination';
import { useParamsPage } from '@hooks/params';

import { claimsFetch, ClaimsFetchPayload } from './action';
import { selectClaim } from './selector';
import { routeUserDashboardDetails } from '../route';
import { ClaimList } from './claim-list';

const Claim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useParamsPage();
  const { claims, pagination } = useSelector(selectClaim);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const payload: ClaimsFetchPayload = { page };
    dispatch(claimsFetch(payload));
  }, []);

  useEffect(() => {
    if (isUndefined(claims)) return;
    setIsLoading(false);
  }, [claims]);

  if (isNull(claims)) return <LoadingError />;

  return (
    <section>
      <div className="d-flex justify-content-between mb-4">
        <h5>{t('common:claim')}</h5>
        <Button as={Link} to={routeUserDashboardDetails()} variant="primary" size="sm">
          {t('common:newClaim')}
        </Button>
      </div>
      <Card>
        <Card.Body>
          <div className="mb-4">
            {isLoading ? <Loading /> : <ClaimList />}
          </div>
          {isPaginated(pagination) && (
            <div className="d-flex justify-content-center">
              <br />
              <Pagination {...pagination} />
            </div>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export { Claim };
