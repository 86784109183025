import { createActions } from 'redux-actions';
import { OrderTemplate } from '@models/OrderTemplate';

export type State = {
  orderTemplates: OrderTemplate[];
};

export type StateUpdatePayload = Partial<State>;

export const {
  orderTemplatesFetch,
  stateUpdate,
} = createActions(
  'ORDER_TEMPLATES_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'FEATURE_ORDER_TEMPLATE',
  },
);
