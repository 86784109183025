import { call, put, takeLatest } from 'redux-saga/effects';
import { UserCar } from '@models/UserCar';
import { Pagination } from '@models/Pagination';
import { GetAllUserCarParams, UserCarApi } from '@apis/UserCarApi';
import { SagaPayload } from '@utils/typescript';
import { stateUpdate, StateUpdatePayload, userCarsFetch } from './action';

function* handleUserCarsFetch({ payload }: SagaPayload<GetAllUserCarParams>) {
  const state: StateUpdatePayload = { userCars: null, pagination: undefined };
  try {
    const [userCars, pagination] = (yield call(UserCarApi.getAll, payload)) as [UserCar[], Pagination];
    state.userCars = userCars;
    state.pagination = pagination;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(userCarsFetch, handleUserCarsFetch);
}
