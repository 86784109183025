import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { camDxVerify } from './action';
import { UserAvatar } from '@components/Feature/User/user-avatar';
import { useTranslation } from 'react-i18next';

export type FormValues = { camDx: object };

const CamDxPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffectOnce(() => {
    const authToken = searchParams.get('authToken');
    dispatch(camDxVerify({ authToken }));
  });

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Header className="text-center">
              <UserAvatar />
            </Card.Header>
            <Card.Body>
              <Card.Title>{t('common:loading')}...</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { CamDxPage };
