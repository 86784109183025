import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number, string, mixed } from 'yup';
import { includes } from 'lodash';

import { mergeInitialValues } from '@utils/formik';
import { CarPlanTypes } from '@models/CarPlan';

import { selectCarQuoteBuy } from '../selector';
import { FormValues } from './type';

const FILE_SIZE = 5_000_000; //5MB
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues: FormValues = {
    thirdPartyAmount: '',
    thirdPartyDiscountAmount: '',
    thirdPartyDeductibleAmount: '',
    thirdPartyDeductibleDiscountAmount: '',
    ownDamageAmount: '',
    ownDamageDiscountAmount: '',
    ownDamageDeductibleAmount: '',
    ownDamageDeductibleDiscountAmount: '',
    theftDeductibleAmount: '',
    theftDeductibleDiscountAmount: '',
    accidentToDriverAmount: '',
    accidentToDriverDiscountAmount: '',
    passengerLiabilityAmount: '',
    passengerLiabilityDiscountAmount: '',
    adminFeeAmount: '',
    idvAmount: '',
    premium: '',
    noClaimDiscountPercentage: '',
    loyaltyDiscountPercentage: '',
    customType: 'no_custom',
    note: '',
    proof: undefined,
  };

  return useMemo(() => {
    if (!carQuote) return null;
    return mergeInitialValues(initialValues, carQuote);
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const { carQuote } = useSelector(selectCarQuoteBuy);

  if (!carQuote) return null;

  const { planType, accidentToDriver, passengerLiability } = carQuote;

  const schema = object({
    thirdPartyAmount: number().required(t('validation:required')),
    thirdPartyDiscountAmount: number().required(t('validation:required')),

    thirdPartyDeductibleAmount: number().required(t('validation:required')),
    thirdPartyDeductibleDiscountAmount: number().required(t('validation:required')),

    adminFeeAmount: number().required(t('validation:required')),
    noClaimDiscountPercentage: number().required(t('validation:required')),
    loyaltyDiscountPercentage: number().required(t('validation:required')),

    customType: string().required(t('validation:required')),
    note: string().required(t('validation:required')),
    proof: mixed()
      .required(t('validation:required'))
      .test(
        'fileSize',
        t('validation:fileSizeOutOfRange'),
        (file: File) => !file || (file && file.size < FILE_SIZE),
      )
      .test(
        'fileFormat',
        t('validation:invalidFormat'),
        (file: File) => !file || (file && includes(SUPPORTED_FORMATS, file.type)),
      ),
  });

  if (planType === CarPlanTypes.Comprehensive) {
    schema.concat(
      object({
        ownDamageAmount: number().required(t('validation:required')),
        ownDamageDiscountAmount: number().required(t('validation:required')),

        ownDamageDeductibleAmount: number().required(t('validation:required')),
        ownDamageDeductibleDiscountAmount: number().required(t('validation:required')),

        theftDeductibleAmount: number().required(t('validation:required')),
        theftDeductibleDiscountAmount: number().required(t('validation:required')),

        idvAmount: number().required(t('validation:required')),
      }),
    );
  }

  if (accidentToDriver) {
    schema.concat(
      object({
        accidentToDriverAmount: number().required(t('validation:required')),
        accidentToDriverDiscountAmount: number().required(t('validation:required')),
      }),
    );
  }

  if (passengerLiability) {
    schema.concat(
      object({
        passengerLiabilityAmount: number().required(t('validation:required')),
        passengerLiabilityDiscountAmount: number().required(t('validation:required')),
      }),
    );
  }

  return schema;
};
