import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { CancerOrder } from '@models/CancerOrder';

export type GetCancerOrderParams = {
  id: number;
};

export type PayCancerOrderParams = GetCancerOrderParams;

export class CancerOrderApi {
  static get = async ({ id }: GetCancerOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/cancer_orders/${id}`)
      .then((response) => humps(response.data) as CancerOrder);
  };

  static pay = async ({ id }: PayCancerOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/cancer_orders/${id}/pay`)
      .then((response) => humps(response.data) as CancerOrder);
  };
}
