import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { MGA_PHONE_NUMBER_LINE_1, MGA_PHONE_NUMBER_LINE_2, MGA_SUPPORT_EMAIL } from '@config';

const CancellationAndRefundPage = () => (
  <>
    <HeaderPrimary />
    <Container className="py-5">
      <main className="w-75">
        <h1 className="mb-5">Cancellation and Refund Policy</h1>
        <section>
          <h3>Refund Policy</h3>
          <p>
            In case of an erroneous transaction, the premium paid for an insurance product shall be refunded or adjusted
            to be matched with the premium of the insurance policy that you have purchased.
          </p>
        </section>
        <section>
          <h3>Cancellation of Insurance Policy</h3>
          <p>
            If the Insured wishes to cancel the insurance before the expiry date, the refund shall be made in accordance
            with the applicable insurance laws and is specifically mentioned in respective policy wordings of each
            insurer.
          </p>
        </section>
        <section>
          <h3>Contact Us</h3>
          <p>
            If you would like to refund or cancel your insurance policy as mentioned above, or if you have any concerns,
            please contact us:
          </p>
          <span className="d-block">MGA Asia Insurance Brokers</span>
          <span className="d-block">
            Address: Building No. 466, Mao Tse Toung Boulevard (245) corner, St 150, Phnom Penh
          </span>
          <span className="d-block">
            Telephone: {MGA_PHONE_NUMBER_LINE_1} | {MGA_PHONE_NUMBER_LINE_2}
          </span>
          <span className="d-block">Email: {MGA_SUPPORT_EMAIL}</span>
        </section>
      </main>
    </Container>
    <Footer />
  </>
);

export { CancellationAndRefundPage };
