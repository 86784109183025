import { createActions } from 'redux-actions';

import {
  CreateHealthQuoteParams,
  GetHealthQuoteParams,
  FindHealthQuoteParams,
  UpdateHealthQuoteParams,
  CustomHealthQuoteParams,
} from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';
import { PayHealthOrderParams } from '@apis/HealthOrderApi';

export type State = {
  healthQuote?: HealthQuote | null;
};

export type HealthQuoteFindPayload = FindHealthQuoteParams;
export type HealthQuoteCreatePayload = CreateHealthQuoteParams;
export type HealthQuoteFetchPayload = GetHealthQuoteParams;
export type HealthQuoteUpdatePayload = UpdateHealthQuoteParams;
export type HealthQuoteCustomPayload = CustomHealthQuoteParams;
export type HealthOrderPayPayload = PayHealthOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  healthQuoteFind,
  healthQuoteCreate,
  healthQuoteFetch,
  healthQuoteUpdate,
  healthQuoteCustom,
  healthOrderPay,
  stateUpdate,
} = createActions(
  'HEALTH_QUOTE_FIND',
  'HEALTH_QUOTE_CREATE',
  'HEALTH_QUOTE_FETCH',
  'HEALTH_QUOTE_UPDATE',
  'HEALTH_QUOTE_CUSTOM',
  'HEALTH_ORDER_PAY',
  'STATE_UPDATE',
  {
    prefix: 'HEALTH_QUOTE_BUY_ROUTE',
  },
);
