import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormGroupControl } from '@components/Base';

const ClaimPaymentMethod = () => {
  return (
    <Card.Body>
      <Card.Title>Claim Payment Method</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="modeOfPayment"
            label="Mode of Payment"
            as="select"
            required
            groupProps={{ className: 'mb-3' }}
            className="form-select"
          >
            <option value="bank-transfer">Bank Transfer</option>
            <option value="cheque">Cheque</option>
          </FormGroupControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="beneficiaryName"
            label="Beneficiary name"
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl name="bankName" label="Bank name" required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl name="bankAccount" label="Bank account no." required groupProps={{ className: 'mb-3' }} />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { ClaimPaymentMethod };
