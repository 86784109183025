import React from 'react';
import { Stack, StackProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { QuotableDocument } from '@models/Quote';
import { DownloadLinkButton } from '@components/Base';

const QuoteDocumentStack = ({ policyWordingFile, brochureFile, termsAndConditionsFile, className }: QuotableDocument & StackProps) => {
  const { t } = useTranslation();
  const hasDocument = policyWordingFile || brochureFile || termsAndConditionsFile;

  return (
    <Stack direction="horizontal" gap={3} className={className}>
      {(hasDocument) ? (
        <>
          {policyWordingFile && (
            <DownloadLinkButton href={policyWordingFile}>
              {t('healthQuoteDetailsPage.policyWording')}
            </DownloadLinkButton>
          )}
          {brochureFile && (
            <DownloadLinkButton href={brochureFile}>
              {t('healthQuoteDetailsPage.planBrochure')}
            </DownloadLinkButton>
          )}
          {termsAndConditionsFile && (
            <DownloadLinkButton href={termsAndConditionsFile}>
              {t('common:termsAndConditions')}
            </DownloadLinkButton>
          )}
        </>
      ) : (
        <div>{t('common:noDocument')}</div>
      )}
    </Stack>
  );
};

export { QuoteDocumentStack };
