import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteParam } from '@hooks/params';

import { selectHealthOrderPaymentPage } from './selector';
import {
  healthOrderFetch,
  HealthOrderFetchPayload,
  billingFetch,
  BillingFetchPayload,
} from './action';

export const useHealthOrderFetch = () => {
  const dispatch = useDispatch();
  const healthOrderId = Number(useRouteParam('healthOrderId'));
  const { healthOrder } = useSelector(selectHealthOrderPaymentPage);

  useEffect(() => {
    const payload: HealthOrderFetchPayload = { id: healthOrderId };
    dispatch(healthOrderFetch(payload));
  }, []);

  return healthOrder;
};

export const useBillingFetch = () => {
  const dispatch = useDispatch();
  const billingId = Number(useRouteParam('billingId'));
  const { billing } = useSelector(selectHealthOrderPaymentPage);

  useEffect(() => {
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  }, []);

  return billing;
};
