import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { map } from 'lodash';

import { CancerQuote } from '@models/CancerQuote';
import { RowItem } from '@components/Base';

const CancerQuoteBenefitListGroup = ({ cancerPlanBenefits }: Pick<CancerQuote, 'cancerPlanBenefits'>) => {
  return (
    <ListGroup variant="flush">
      {map(cancerPlanBenefits, ({ title, description }) => (
        <ListGroup.Item key={title}>
          <RowItem labelProps={{ xs: 9 }} label={<strong>{title}</strong>} value={description} />
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export { CancerQuoteBenefitListGroup };
