import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useParseParams } from '@hooks/params';

import { PersonalAccidentQuoteDetailsPageParams } from './route';
import { personalAccidentQuoteDetailsPageParamsSchema } from './schema';
import { selectPersonalAccidentQuoteDetailsPage } from './selector';
import { personalAccidentQuoteFind, PersonalAccidentQuoteFindPayload } from './action';

export const usePersonalAccidentQuoteDetailsParams = () =>
  useParseParams<PersonalAccidentQuoteDetailsPageParams>(personalAccidentQuoteDetailsPageParamsSchema);

export const usePersonalAccidentQuoteFind = () => {
  const dispatch = useDispatch();
  const payload: PersonalAccidentQuoteFindPayload = usePersonalAccidentQuoteDetailsParams();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteDetailsPage);

  useEffectOnce(() => {
    dispatch(personalAccidentQuoteFind(payload));
  });

  return personalAccidentQuote;
};
