import React from 'react';
import { map } from 'lodash';
import { Route, Routes } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderPrimary from '@components/HeaderPrimary';

import Dashboard from './Dashboard';
import Billing from './Billing';
import Policy from './Policy';
import PolicyDetails from './PolicyDetails';
import PolicyClaim from './PolicyClaim';
import PolicyRenewal from './PolicyRenewal';
import PersonalInfoPage from './PersonalInfoPage';
import PersonalInfoEditPage from './PersonalInfoEditPage';
import BillingDetails from './BillingDetails';
import Claim from './Claim';
import ClaimDetails from './ClaimDetails';
import Password from './Password';
import Vehicle from './Vehicle';
import VehicleDetails from './VehicleDetails';
import VehicleForm from './VehicleForm';
import Document from './Document';
import DocumentNew from './DocumentNew';
import DocumentDetails from './DocumentDetails';
import DocumentEdit from './DocumentEdit';
import { useActiveKey, useUserDashboard } from './hook';

const UserDashboardRoute = () => {
  const { items } = useUserDashboard();
  const activeKey = useActiveKey();

  return (
    <>
      <HeaderPrimary />
      <Container className="my-4">
        <Row className="gy-4">
          <Col sm={3}>
            <ListGroup activeKey={activeKey}>
              {map(items, ({ key, to, icon, label }) => (
                <ListGroup.Item as={Link} action to={to} key={key} eventKey={key}>
                  <FontAwesomeIcon icon={icon} className="fa-fw me-2" />
                  {label}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col sm={9}>
            <Routes>
              <Route index element={<Dashboard />} />
              <Route path="billing" element={<Billing />} />
              <Route path="billing/:billingId" element={<BillingDetails />} />
              <Route path="policy" element={<Policy />} />
              <Route path="policy/:orderableType/:orderableId" element={<PolicyDetails />} />
              <Route path="policy/:orderableType/:orderableId/claim" element={<PolicyClaim />} />
              <Route path="policy/:orderableType/:orderableId/renewal" element={<PolicyRenewal />} />
              <Route path="claim" element={<Claim />} />
              <Route path="claim/:claimableType/:claimableId" element={<ClaimDetails />} />
              <Route path="password" element={<Password />} />
              <Route path="personal-info" element={<PersonalInfoPage />} />
              <Route path="personal-info/edit" element={<PersonalInfoEditPage />} />
              <Route path="vehicle" element={<Vehicle />} />
              <Route path="vehicle/:vehicleId" element={<VehicleDetails />} />
              <Route path="vehicle/:vehicleId/edit" element={<VehicleForm />} />
              <Route path="document" element={<Document />} />
              <Route path="document/new" element={<DocumentNew />} />
              <Route path="document/:id" element={<DocumentDetails />} />
              <Route path="document/:id/edit" element={<DocumentEdit />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { UserDashboardRoute };
