import React, { Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import parse from 'html-react-parser';
import { map } from 'lodash';

import { HealthQuoteBenefitSchedule } from '@models/HealthQuote';
import { HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';

const HealthQuoteBenefitScheduleAccordion = ({
  benefitScheduleIpdHtml,
  benefitScheduleOpdHtml,
  benefitScheduleHealthCheckHtml,
  benefitScheduleMaternityHtml,
  benefitScheduleVisionHtml,
  benefitScheduleDentalHtml,
  benefitScheduleOtherHtml,
}: HealthQuoteBenefitSchedule) => {
  const items: [HealthPlanBenefitTypes, string][] = [
    [HealthPlanBenefitTypes.Inpatient, benefitScheduleIpdHtml],
    [HealthPlanBenefitTypes.Outpatient, benefitScheduleOpdHtml],
    [HealthPlanBenefitTypes.HealthCheckup, benefitScheduleHealthCheckHtml],
    [HealthPlanBenefitTypes.Maternity, benefitScheduleMaternityHtml],
    [HealthPlanBenefitTypes.Vision, benefitScheduleVisionHtml],
    [HealthPlanBenefitTypes.Dental, benefitScheduleDentalHtml],
    [HealthPlanBenefitTypes.Others, benefitScheduleOtherHtml],
  ];

  return (
    <Accordion flush alwaysOpen>
      {map(items, ([type, scheduleHtml]) => (
        <Fragment key={type}>
          {scheduleHtml ? (
            <Accordion.Item eventKey={type}>
              <Accordion.Header>{tHealthPlanBenefitType(type)}</Accordion.Header>
              <Accordion.Body>{parse(scheduleHtml)}</Accordion.Body>
            </Accordion.Item>
          ) : null}
        </Fragment>
      ))}
    </Accordion>
  );
};

export { HealthQuoteBenefitScheduleAccordion };
