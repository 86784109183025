import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { boolean, object } from 'yup';

import { useRouteParam } from '@hooks/params';
import { PaymentMethods } from '@models/PaymentMethod';

import {
  billingFetch,
  BillingFetchPayload,
  generalOrderFetch,
  GeneralOrderFetchPayload,
} from './action';
import { selectGeneralOrderPayment } from './selector';
import { FormValues } from './type';

export const useGeneralOrderFetch = () => {
  const dispatch = useDispatch();
  const generalOrderId = Number(useRouteParam('generalOrderId'));
  const { generalOrder } = useSelector(selectGeneralOrderPayment);

  useEffectOnce(() => {
    if (generalOrder && generalOrder.id == generalOrderId) return;
    const payload: GeneralOrderFetchPayload = { id: generalOrderId };
    dispatch(generalOrderFetch(payload));
  });

  return generalOrder;
}

export const useBillingFetch = () => {
  const dispatch = useDispatch();
  const billingId = Number(useRouteParam('billingId'));
  const { billing } = useSelector(selectGeneralOrderPayment);

  useEffectOnce(() => {
    if (billing && billing.id == billingId) return;
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  });

  return billing;
}

export const useInitialValues = () => {
  const initialValues: FormValues = {
    agreeToTermsAndConditions: false,
    paymentMethod: PaymentMethods.AbaPay,
  };
  return initialValues;
}

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    agreeToTermsAndConditions: boolean().test(
      'required',
      t('validation:required'),
      (checked) => checked === true,
    ),
  });
}
