import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';

export type GetPersonalAccidentOrderParams = {
  id: number;
};

export type PayPersonalAccidentOrderParams = GetPersonalAccidentOrderParams;

export class PersonalAccidentOrderApi {
  static get = async ({ id }: GetPersonalAccidentOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/personal_accident_orders/${id}`)
      .then((response) => humps(response.data) as PersonalAccidentOrder);
  };

  static pay = async ({ id }: PayPersonalAccidentOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/personal_accident_orders/${id}/pay`)
      .then((response) => humps(response.data) as PersonalAccidentOrder);
  };
}
