import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { isNull, map, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoData } from '@components/Base';
import { amountToUsd } from '@utils/currency';

import { selectBilling } from './selector';
import { isEmptyArray } from '@utils/lodash';
import { Link } from 'react-router-dom';

const BillingList = () => {
  const { t } = useTranslation();
  const { billings } = useSelector(selectBilling);

  if (isNull(billings)) return null;
  if (isEmptyArray(billings)) return <NoData />;

  return (
    <Table className="align-middle">
      <thead>
        <tr>
          <th>#</th>
          <th>Order no.</th>
          <th>Status</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {map(billings, ({ id, invoiceNumber, status, chargeAmount, orderNumber }) => (
          <tr key={invoiceNumber} data-testid="billing-item">
            <td>{invoiceNumber}</td>
            <td>{orderNumber}</td>
            <td>{status}</td>
            <td>{amountToUsd(chargeAmount)}</td>
            <td>
              <Button as={Link} to={`/user-dashboard/billing/${id}`} variant="outline-primary" size="sm">
                {startCase(t('common:viewDetails'))}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export { BillingList };
