import { all, fork } from 'redux-saga/effects';
import { MobileNumberSaga } from './MobileNumberCard';
import { OtpCodeSaga } from './OtpCodeCard';
import { passwordSaga } from './PasswordCard';

export default function* () {
  yield all([
    fork(MobileNumberSaga),
    fork(OtpCodeSaga),
    fork(passwordSaga)
  ]);
}
