import { tEnum } from '@components/i18n';
import { CamelCaseKeys } from '@utils/typescript';
import { CarPrice } from './CarPrice';

export enum FuelTypes {
  Unknown = 'unknown',
  Petrol = 'petrol',
  Diesel = 'diesel',
  Hybrid = 'hybrid',
}

export const tFuelType = (fuelType: FuelTypes) => tEnum('FuelTypes', fuelType);

export type Car = CamelCaseKeys<{
  id: number;
  car_brand_id: number;
  car_model_id: number;
  car_sub_model_id: number;
  name: string;
  car_brand_name: string;
  car_model_name: string;
  car_sub_model_name: string;
  engine_capacity: number;
  manufactured_year: number;
  fuel_type: FuelTypes;
  lowest_amount: number;
  highest_amount: number;
  recommended_amount: number;
  unknown_idv: boolean;
  car_prices: CarPrice[];
}>;
