import { cloneDeep, forOwn, has, isNil } from 'lodash';
import { isObjectOnly, isEmptyArray } from '@utils/lodash';

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
export const mergeInitialValues = <T extends object>(base: T, source: object): T => {
  const clonedBase = cloneDeep(base);
  forOwn(clonedBase, (value, key) => {
    if (has(source, key)) {
      const sourceValue = source[key];
      if (isNil(sourceValue)) return;
      if (isEmptyArray(sourceValue)) return;
      if (isObjectOnly(value) && isObjectOnly(sourceValue)) {
        (clonedBase as any)[key] = mergeInitialValues(value as object, sourceValue);
        return;
      }
      (clonedBase as any)[key] = sourceValue;
    }
  });
  return clonedBase;
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
