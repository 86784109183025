import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';

import { Loading, LoadingError } from '@components/Base';

import { personalAccidentQuoteUpdate, PersonalAccidentQuoteUpdatePayload } from '../action';
import { usePersonalAccidentQuoteFetch } from '../hook';
import PersonalAccidentQuoteBuyForm from '../PersonalAccidentQuoteBuyForm';
import { FormValues } from '../PersonalAccidentQuoteBuyForm/schema';
import { useInitialValues } from './hook';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';

const PersonalAccidentQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const personalAccidentQuote = usePersonalAccidentQuoteFetch();
  const initialValues = useInitialValues();

  if (isUndefined(personalAccidentQuote)) return <Loading />;
  if (isNull(personalAccidentQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { personalAccidentOrderId } = personalAccidentQuote;

  const handleSubmit = (values: FormValues) => {
    const payload: PersonalAccidentQuoteUpdatePayload = { ...values, personalAccidentOrderId };
    dispatch(personalAccidentQuoteUpdate(payload));
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <PersonalAccidentQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>
        <RightCol {...personalAccidentQuote} planName={personalAccidentQuote.personalAccidentPlanName} />
      </Row>
    </Container>
  );
};

export { PersonalAccidentQuoteBuyEditPage };
