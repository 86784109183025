import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Loading, LoadingError, Pagination } from '@components/Base';

import { ordersFetch } from './action';
import { selectPolicy } from './selector';
import { OrdersFetchPayload } from './action';
import { OrderList } from './order-list';
import { OrderSearch } from './order-search';
import { useSearchParams } from 'react-router-dom';
import { isPaginated } from '@models/Pagination';

const Policy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orders, pagination } = useSelector(selectPolicy);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const payload: OrdersFetchPayload = Object.fromEntries(searchParams);
    dispatch(ordersFetch(payload));
  }, [searchParams]);

  useEffect(() => {
    if (isUndefined(orders)) return;
    setIsLoading(false);
  }, [orders]);

  if (isNull(orders)) return <LoadingError />;

  return (
    <section>
      <h5 className="mb-4">{t('common:policy')}</h5>
      <OrderSearch />
      <div className="mb-4">
        {isLoading ? <Loading /> : <OrderList />}
      </div>
      {isPaginated(pagination) && (
        <div className="d-flex justify-content-center">
          <br />
          <Pagination {...pagination} />
        </div>
      )}
    </section >
  );
};

export { Policy };
