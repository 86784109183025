import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import pluralize from 'pluralize';

import { Genders, tGender } from '@models/User';
import { formatDate, getAge } from '@utils/datetime';
import { HealthQuote, PersonalDetails } from '@models/HealthQuote';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => (
  <Row className="mb-2">
    <Col xs={7} sm={7} md={4}>
      {label}
    </Col>
    <Col xs="auto">:</Col>
    <Col>{value}</Col>
  </Row>
);

interface MemberItemProps {
  member: PersonalDetails;
  gender: Genders;
}

const MemberItem = ({ member, gender }: MemberItemProps) => {
  const { t } = useTranslation();
  const {
    fullName,
    fullNameKm,
    dob,
    mobileNumber,
    personalCode,
    occupation,
    weight,
    height,
    email,
    address,
  } = member

  return (
    <Card className="mb-3">
      <Card.Header className="bg-white fs-5">{fullName}</Card.Header>
      <Card.Body>
        <RowItem label={t('user.fullNameKm')} value={fullNameKm} />
        <RowItem label={t('user.gender')} value={tGender(gender)} />
        <RowItem label={t('user.dob')} value={formatDate(dob)} />
        <RowItem
          label={t('user.age')}
          value={t('user.yearsOld', {
            age: getAge(dob),
            pluralizedYear: pluralize(t('common:year'), getAge(dob)),
          })}
        />
        <RowItem label={t('user.personalCode')} value={personalCode} />
        <RowItem label={t('user.occupation')} value={occupation} />
        <RowItem label={t('user.weight')} value={weight} />
        <RowItem label={t('user.height')} value={height} />

        {mobileNumber && (
          <>
            <RowItem label={t('user.mobileNumber')} value={mobileNumber} />
            <RowItem label={t('user.email')} value={email} />
            <RowItem label={t('user.address')} value={address} />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

type Props = HealthQuote['people'];

const MemberCardBody = ({ me, spouse, sons, daughters }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('healthQuoteReviewPage.memberDetailsTitle')}</Card.Title>
      <MemberItem member={me} gender={me.gender} />

      {!!spouse && (
        <MemberItem
          member={spouse}
          gender={me.gender === 'male' ? Genders.Female : Genders.Male}
        />
      )}

      {!!sons &&
        map(sons, (son, idx: number) => (
          <MemberItem key={`sons.${idx}`} member={son} gender={Genders.Male} />
        ))}

      {!!daughters &&
        map(daughters, (daughter, idx: number) => (
          <MemberItem key={`daughters.${idx}`} member={daughter} gender={Genders.Female} />
        ))}
    </Card.Body>
  );
};

export { MemberCardBody };
