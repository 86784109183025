import { isUndefined } from 'lodash';
import { useContext } from 'react';
import { boolean, number, object, string } from 'yup';
import { useParseParams } from '@hooks/params';
import { CarPlanCategories, CarPlanTypes } from '@models/CarPlan';
import { FilterContext, FilterType } from './context';

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (isUndefined(context)) throw new Error('useFilterContext must be used within a FilterProvider');
  return context;
};


export const useFilterParams = () => {
  const schema = object({
    idvAmount: number().default(0),
    category: string().default(CarPlanCategories.Individual),
    planType: string().default(CarPlanTypes.ThirdParty),
    accidentToDriver: boolean().default(false),
    passengerLiability: boolean().default(false),
  })
  return useParseParams<FilterType>(schema);
};
