import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam } from '@hooks/params';

import { healthOrderFetch, HealthOrderFetchPayload } from './action';
import { selectHealthPolicyDetails } from './selector';

export const useHealthOrderFetch = () => {
  const dispatch = useDispatch();
  const healthOrderId = Number(useRouteParam('orderableId'));
  const { healthOrder } = useSelector(selectHealthPolicyDetails);

  useEffectOnce(() => {
    if (healthOrder && healthOrder.id === healthOrderId) return;
    const payload: HealthOrderFetchPayload = { id: healthOrderId };
    dispatch(healthOrderFetch(payload));
  });

  return healthOrder;
};
