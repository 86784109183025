import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = ({ onClick, className }: Pick<ButtonProps, 'onClick' | 'className'>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button onClick={isUndefined(onClick) ? () => navigate(-1) : onClick} className={className} variant="outline-primary" data-testid="back-button">
      <FontAwesomeIcon icon={faArrowLeft} className="fa-fw me-2" />
      {t('common:back')}
    </Button>
  );
};

export { BackButton };
