import { FindCarQuoteParams } from '@apis/CarQuoteApi';
import { getRoute } from '@utils/route';

export const CAR_QUOTE_DETAILS_ROUTE_URL = '/car-insurance-quote-details/*';
export const CAR_QUOTE_DETAILS_PAGE_URL = '/car-insurance-quote-details';
export const CAR_QUOTE_SEND_MAIL_PAGE_URL = '/car-insurance-quote-details/send-email';

export type CarQuoteDetailsPageParams = FindCarQuoteParams;
export type CarQuoteSendEmailPageParams = CarQuoteDetailsPageParams;

export const routeCarQuoteDetailsPage = (searchParams: CarQuoteDetailsPageParams) => getRoute(CAR_QUOTE_DETAILS_PAGE_URL, { searchParams });
export const routeCarQuoteSendEmailPage = (searchParams: CarQuoteDetailsPageParams) => getRoute(CAR_QUOTE_SEND_MAIL_PAGE_URL, { searchParams });
