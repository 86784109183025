import { call, put, takeLatest } from 'redux-saga/effects';
import { OrderList } from '@models/Order';
import { OrderApi, GetAllOrderParams } from '@apis/OrderApi';
import { Pagination } from '@models/Pagination';
import { SagaPayload } from '@utils/typescript';
import { ordersFetch, stateUpdate, StateUpdatePayload } from './action';

function* handleOrdersFetch({ payload }: SagaPayload<GetAllOrderParams>) {
  const state: StateUpdatePayload = { orders: null, pagination: undefined };
  try {
    const [orders, pagination] = (yield call(OrderApi.getAll, payload)) as [OrderList[], Pagination];
    state.orders = orders;
    state.pagination = pagination;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(ordersFetch, handleOrdersFetch);
}
