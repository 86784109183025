import React, { useMemo, useState } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { map, find, filter } from 'lodash';
import { TrixEditor } from 'react-trix';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import UserFormControl from '@components/Feature/User/UserFormControl';
import { FormGroupControl, FormGroupSelect } from '@components/Base';
import { useInsurersFetch } from '@components/Feature/Insurer';
import { useOrderTemplatesFetch } from '@components/Feature/OrderTemplate';
import { ActivationDateControl } from '@components/Feature/Quote';
import { noob } from '@utils/typescript';

import { FormDisabled, FormRequired, FormValues } from './type';
import { useUpdateEffect } from 'usehooks-ts';

const GROUP_PROPS = { className: 'mb-3' };

type Props = {
  disabled?: FormDisabled,
  required: FormRequired,
};

const GeneralQuoteBuyForm = ({ disabled, required }: Props) => {
  const { t } = useTranslation();
  const insurers = useInsurersFetch();
  const orderTemplates = useOrderTemplatesFetch();
  const { values, isSubmitting, setFieldValue } = useFormikContext<FormValues>();
  const { orderTemplateId, descriptionHtml, insurerId } = values;
  const [key, setKey] = useState(0);
  const insurerOrderTemplates = useMemo(() => filter(orderTemplates, (x) => x.insurerId == insurerId), [orderTemplates, insurerId]);

  useUpdateEffect(() => {
    if (!orderTemplateId) return;
    if (descriptionHtml) return;
    const orderTemplate = find(orderTemplates, ({ id }) => id == orderTemplateId);
    setFieldValue('descriptionHtml', orderTemplate?.templateHtml);
    setKey((prev) => prev + 1);
  }, [orderTemplateId]);

  useUpdateEffect(() => {
    if (!insurerId) return;
    setFieldValue('orderTemplateId', '');
  }, [insurerId]);

  return (
    <Form>
      <Alert variant="warning">{t('personalInfo.changeabilityWarningMessage')}</Alert>

      <Card>
        <Card.Body>
          <Card.Title>{t('common:fillPolicyDetails')}</Card.Title>
          <FormGroupSelect
            name="insurerId"
            label={t('common:insurer')}
            required={required.insurerId}
            disabled={disabled?.insurerId}
            groupProps={GROUP_PROPS}
          >
            <option></option>
            {map(insurers, ({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </FormGroupSelect>

          <FormGroupSelect
            name="orderTemplateId"
            label={t('common:policyTemplate')}
            required={required.orderTemplateId}
            disabled={disabled?.orderTemplateId}
            groupProps={GROUP_PROPS}
          >
            <option></option>
            {map(insurerOrderTemplates, ({ id, insuranceType, name }) => (
              <option key={id} value={id}>
                {insuranceType} - {name}
              </option>
            ))}
          </FormGroupSelect>

          <ActivationDateControl noMin noMax required={required.activationDate} disabled={disabled?.activationDate} />

          <FormGroupControl
            name="adminFeeAmount"
            label="Admin Fee"
            required={required.adminFeeAmount}
            disabled={disabled?.adminFeeAmount}
            groupProps={GROUP_PROPS}
            type="number"
          />

          <FormGroupControl
            name="premium"
            label="Premium"
            required={required.premium}
            disabled={disabled?.premium}
            groupProps={GROUP_PROPS}
            type="number"
          />

          <FormGroupControl
            name="proof"
            type="file"
            label={t('common:proof')}
            required={required.proof}
            disabled={disabled?.proof}
            groupProps={GROUP_PROPS}
          />

          <FormGroupControl
            name="note"
            as="textarea"
            rows={4}
            label="Note"
            required={required.note}
            disabled={disabled?.note}
            groupProps={GROUP_PROPS}
          />
        </Card.Body>

        <Card.Body>
          <Card.Title>{t('common:fillOwnerDetails')}</Card.Title>
          <UserFormControl disabled={disabled} required={required} />
        </Card.Body>

        <Card.Body>
          <Card.Title className="mb-3">{t('common:fillDescriptionDetails')}</Card.Title>
          <TrixEditor
            key={key}
            className="trix-content"
            onChange={(html) => setFieldValue('descriptionHtml', html)}
            onEditorReady={noob}
            mergeTags={[]}
            value={values.descriptionHtml}
            placeholder={t('common:description')}
          />
        </Card.Body>

        <Card.Footer className="bg-white border-0">
          <div className="text-center my-4">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              <span className="me-2">
                {t('common:summary')} & {t('common:review')}
              </span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export { GeneralQuoteBuyForm };
