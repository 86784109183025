import { getRoute } from '@utils/route';
import { HealthQuoteDetailsPageParams } from '@components/HealthQuoteDetailsRoute/route';

export type HealthQuoteBuyNewPageParams = HealthQuoteDetailsPageParams;

export const HEALTH_QUOTE_BUY_ROUTE_URL = '/health-insurance-quote-buy/*';
export const HEALTH_QUOTE_BUY_NEW_PAGE_URL = '/health-insurance-quote-buy';
export const HEALTH_QUOTE_BUY_EDIT_PAGE_URL = '/health-insurance-quote-buy/:healthOrderId/edit';
export const HEALTH_QUOTE_BUY_REVIEW_PAGE_URL = '/health-insurance-quote-buy/:healthOrderId/review';
export const HEALTH_QUOTE_BUY_CUSTOM_PAGE_URL = '/health-insurance-quote-buy/:healthOrderId/custom';

type RouteParams = {
  healthOrderId: number;
}

export const routeHealthQuoteBuyNewPage = (searchParams: HealthQuoteBuyNewPageParams) => getRoute(HEALTH_QUOTE_BUY_NEW_PAGE_URL, { searchParams });
export const routeHealthQuoteBuyEditPage = ({ healthOrderId, ...searchParams }: RouteParams) => getRoute(HEALTH_QUOTE_BUY_EDIT_PAGE_URL, { routeParams: { healthOrderId }, searchParams });
export const routeHealthQuoteBuyReviewPage = (routeParams: RouteParams) => getRoute(HEALTH_QUOTE_BUY_REVIEW_PAGE_URL, { routeParams });
export const routeHealthQuoteBuyCustomPage = (routeParams: RouteParams) => getRoute(HEALTH_QUOTE_BUY_CUSTOM_PAGE_URL, { routeParams });
