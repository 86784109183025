import humps from 'lodash-humps-ts';
import { omitBy, isArray } from 'lodash';
import { stringify } from 'qs';

import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { HealthQuote, HealthQuoteMember } from '@models/HealthQuote';
import { Genders } from '@models/User';
import { FilterType } from '@components/HealthQuoteResultPage/FilterProvider';
import { CustomQuoteParams, serializeCustomFormData } from '@utils/serialize-custom-form-data';

export type MedicalQuestionnaire = {
  question: string;
  answer: 'yes' | 'no';
  remark: string;
};

export type PersonalDetails = {
  firstName: string;
  firstNameKm?: string | null;
  lastName: string;
  lastNameKm?: string | null;
  dob: string;
  personalCode?: string | null;
  occupation: string;
  weight: string;
  height: string;
  medicalQuestionnaire: MedicalQuestionnaire[];
};

export type SendEmailHealthQuoteParams = FindHealthQuoteParams & {
  firstName: string;
  lastName: string;
  email: string;
};

export type HealthQuotePeopleParams = {
  me: PersonalDetails & {
    gender: Genders;
    email: string;
    mobileNumber: string;
    address: string;
  };
  spouse?: PersonalDetails;
  sons?: PersonalDetails[];
  daughters?: PersonalDetails[];
};

export type GetAllHealthQuoteParams = HealthQuoteMember & {
  healthPlanIds?: number[];
  filters: FilterType;
};

export type FindHealthQuoteParams = HealthQuoteMember & {
  healthPlanId: number;
};

export type CreateHealthQuoteParams = HealthQuotePeopleParams & {
  healthPlanId: number;
};

export type GetHealthQuoteParams = {
  healthOrderId: number;
};

export type UpdateHealthQuoteParams = GetHealthQuoteParams & HealthQuotePeopleParams;

export type CustomHealthQuoteParams = GetHealthQuoteParams & CustomQuoteParams;

export class HealthQuoteApi {
  static getAll = async (params: GetAllHealthQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/health_quotes`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as HealthQuote[]);
  };

  static find = async (params: FindHealthQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/health_quotes/find`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as HealthQuote);
  };

  static create = async (params: CreateHealthQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/health_quotes`, {
        ...omitBy(snakes(params), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as HealthQuote);
  };

  static get = async ({ healthOrderId }: GetHealthQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/health_quotes/${healthOrderId}`)
      .then((response) => humps(response.data) as HealthQuote);
  };

  static update = async ({ healthOrderId, ...rest }: UpdateHealthQuoteParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/user/health_quotes/${healthOrderId}`, {
        ...omitBy(snakes(rest), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as HealthQuote);
  };

  static sendEmail = async (params: SendEmailHealthQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/health_quotes/send_email`, snakes(params));
  };

  static custom = async ({ healthOrderId, ...rest }: CustomHealthQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/health_quotes/${healthOrderId}/custom`, serializeCustomFormData(rest))
      .then((response) => humps(response.data) as HealthQuote);
  };
}
