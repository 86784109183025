import React, { useCallback, useMemo } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useUserDashboard } from '@components/UserDashboardRoute';
import { authLogout } from '@components/Auth';
import { useToast } from '@components/ToastProvider';
import { selectCurrentUser, selectIsCustomer } from '@components/Auth';

const UserNavDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);

  const { items } = useUserDashboard();

  const title = useMemo(() => {
    const name = currentUser?.fullName || currentUser?.mobileNumber || '';
    const role = currentUser?.role || '';

    if (isCustomer || !role) return name;
    return `${name} - ${role}`;
  }, [currentUser, isCustomer]);

  const handleSignOut = useCallback(() => {
    dispatch(authLogout());
    navigate('/');
    toast.success({ body: t('toast.signedOut') });
  }, []);

  return (
    <Nav>
      <NavDropdown title={title} data-testid="account-dropdown">
        {map(items, ({ label, to }) => (
          <NavDropdown.Item as={Link} key={label} eventKey={to} to={to}>
            {label}
          </NavDropdown.Item>
        ))}
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="sign-out" onClick={handleSignOut}>
          {t('common:signOut')}
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

export { UserNavDropdown };
