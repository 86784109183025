import { camelCase } from 'lodash';
import { CamelCase, CamelCaseKeys } from '@utils/typescript';
import { BusinessPartner } from '@models/BusinessPartner';
import { InsuranceTypes } from '@models/InsuranceType';

type HotlineKeys = {
  [K in InsuranceTypes as CamelCase<`hotlines_${K}`>]: string;
};

type InsurerHotlines = {
  [K in keyof HotlineKeys]: string;
};

export type InsurerList = InsurerHotlines & CamelCaseKeys<{
  id: number;
  name: string;
  logo: string;

  car_product_enabled: boolean;
  health_product_enabled: boolean;
  personal_accident_product_enabled: boolean;
  cancer_product_enabled: boolean;
}>;

export type Insurer = InsurerList & CamelCaseKeys<{
  garages: BusinessPartner[];
}>;

export const getInsurerHotlines = (insurer: InsurerHotlines, insuranceType: InsuranceTypes) => {
  return insurer[camelCase(`hotlines_${insuranceType}`) as keyof InsurerHotlines] as string;
};
