import { call, put, takeLatest } from 'redux-saga/effects';
import { Billing } from '@models/Billing';
import { Pagination } from '@models/Pagination';
import { BillingApi } from '@apis/BillingApi';
import { SagaPayload } from '@utils/typescript';
import { billingsFetch, BillingsFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleBillingsFetch({ payload }: SagaPayload<BillingsFetchPayload>) {
  const state: StateUpdatePayload = { billings: null, pagination: undefined };
  try {
    const [billings, pagination] = (yield call(BillingApi.getAll, payload)) as [Billing[], Pagination];
    state.billings = billings;
    state.pagination = pagination;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(billingsFetch, handleBillingsFetch);
}
