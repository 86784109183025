import React from 'react';
import { Container, Table } from 'react-bootstrap';

import HeaderSecondary from '@components/HeaderSecondary';
import { BackButton, CopyLinkButton } from '@components/Base';

import { useCarQuotesFetch } from './hook';
import { TableInsurer } from './table-insurer';
import { TablePremium } from './table-premium';
import { TableCover } from './table-cover';
import { TableDeductible } from './table-deductible';
import { TableExclusion } from './table-exclusion';

const CarQuoteComparePage = () => {
  const carQuotes = useCarQuotesFetch();

  if (!carQuotes) return null;

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <BackButton />
          <CopyLinkButton />
        </div>

        <Table>
          <tbody>
            <TableInsurer />
            <TablePremium />
            <TableCover />
            <TableDeductible />
            <TableExclusion />
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export { CarQuoteComparePage };
