import React from 'react';
import { Image, ToggleButton } from 'react-bootstrap';
import classNames from 'classnames';
import { useField } from 'formik';
import { getPaymentMethod, PaymentMethods } from '@models/PaymentMethod';

type Props = {
  value: PaymentMethods;
};

const PaymentMethodToggleControl = ({ value }: Props) => {
  const name = 'paymentMethod';
  const [field] = useField(name);
  const checked = field.value === value;
  const { image, title, description } = getPaymentMethod(value);

  return (
    <ToggleButton
      {...field}
      id={`${name}-${value}`}
      type="radio"
      className={classNames('w-100', { 'border-primary': checked })}
      variant="light"
      value={value}
      checked={checked}
    >
      <div className="d-flex align-items-center">
        <Image src={image} width={80} />
        <div className="d-flex flex-column align-items-start mx-3">
          <span className="fw-bold">{title}</span>
          <small className="text-secondary text-start">{description}</small>
        </div>
      </div>
    </ToggleButton>
  );
};

export { PaymentMethodToggleControl };
