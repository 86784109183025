// Utility to convert snake_case to camelCase
export type CamelCase<S extends string> = S extends `${infer First}_${infer Rest}`
  ? `${First}${Capitalize<CamelCase<Rest>>}` // Capitalize the first letter of Rest
  : S; // No change if no underscore is found

// Utility to apply CamelCase to all keys of an object (including nested objects and arrays)
export type CamelCaseKeys<T> = T extends Array<infer U>
  ? Array<CamelCaseKeys<U>> // Handle arrays
  : T extends object
  ? { [K in keyof T as K extends string ? CamelCase<K> : K]: CamelCaseKeys<T[K]> }
  : T; // Handle primitives

export type NumericString = `${number}`;

export type Numeric = number | NumericString;

export type WithEmptyString<T> = {
  [K in keyof T]: T[K] extends Numeric | undefined ? Numeric | '' : T[K];
};

export type WithParams<T> = CamelCaseKeys<WithEmptyString<T>>;

export type SagaPayload<T> = { payload: T; type: string };

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noob = () => { };
