import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash';

import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { Loading, LoadingError, Pagination } from '@components/Base';
import { ArticleBreadcrumb } from '@components/Feature/Article/article-breadcrumb';
import { isPaginated } from '@models/Pagination';

import { ArticleList } from './article-list';
import { useArticlesFetch } from './hook';

const ArticlePage = () => {
  const { t } = useTranslation();
  const { articles, pagination, isLoading } = useArticlesFetch();

  if (isNull(articles)) return <LoadingError />;

  return (
    <>
      <HeaderPrimary />
      <Container>
        <ArticleBreadcrumb />
        <h1 className="my-5">{t('common:articles')}</h1>
        <div className="mb-5">
          {isLoading ? <Loading /> : <ArticleList />}
        </div>
        {isPaginated(pagination) && (
          <div className="mb-5 d-flex justify-content-center justify-content-lg-start">
            <Pagination {...pagination} />
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ArticlePage };
