import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';

import CancerQuoteBuyForm from '../CancerQuoteBuyForm';
import { FormValues } from '../CancerQuoteBuyForm/schema';
import { cancerQuoteCreate, CancerQuoteCreatePayload } from '../action';
import { useCancerQuoteBuyNewPageParams, useCancerQuoteFind } from '../hook';
import { useInitialValues } from './hook';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';

const CancerQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useCancerQuoteBuyNewPageParams();
  const cancerQuote = useCancerQuoteFind(params);
  useCurrentUser();
  const initialValues = useInitialValues();

  if (isUndefined(cancerQuote)) return <Loading />;
  if (isNull(cancerQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { cancerPlanId } = cancerQuote;

  const handleSubmit = (values: FormValues) => {
    const payload: CancerQuoteCreatePayload = { ...values, cancerPlanId };
    dispatch(cancerQuoteCreate(payload));
  };

  return (
    <Container className="my-5">
      <Row>
        <h2>{t('cancerQuoteBuyPage.title')}</h2>
        <p>{t('cancerQuoteBuyPage.subTitle')}</p>
        <Col lg={8}>
          <CancerQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>

        <RightCol {...cancerQuote} planName={cancerQuote.cancerPlanName} />
      </Row>
    </Container>
  );
};

export { CancerQuoteBuyNewPage };
