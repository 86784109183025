import { call, put, takeLatest } from 'redux-saga/effects';
import { Order } from '@models/Order';
import { OrderApi, GetAllOrderParams } from '@apis/OrderApi';
import { Pagination } from '@models/Pagination';
import { SagaPayload } from '@utils/typescript';
import { ordersFetch, stateUpdate, StateUpdatePayload } from './action';

function* handleOrdersFetch({ payload }: SagaPayload<GetAllOrderParams>) {
  const state: StateUpdatePayload = { orders: [] };
  try {
    const [orders] = (yield call(OrderApi.getAll, payload)) as [Order[], Pagination];
    state.orders = orders;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(ordersFetch, handleOrdersFetch);
}
