import React from 'react';
import { Card } from 'react-bootstrap';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { amountToUsd, amountToKhr } from '@utils/currency';
import { CarPlanTypes, tCarPlanType } from '@models/CarPlan';
import { CarQuote, CarQuotePremium } from '@models/CarQuote';

import { InsurerLogoWrapper } from '@components/Feature/Insurer';
import { AmountWithDiscount } from '@components/Base';

type Props = CarQuotePremium &
  Pick<CarQuote,
    | 'idvAmount'
    | 'planType'
    | 'insurer'
    | 'accidentToDriver'
    | 'passengerLiability'
  >;

const CarQuotePremiumCardBody = ({
  insurer,

  planType,
  idvAmount,
  accidentToDriver,
  passengerLiability,

  thirdPartyAmount,
  thirdPartyDiscountAmount,
  thirdPartyFinalAmount,

  ownDamageAmount,
  ownDamageDiscountAmount,
  ownDamageFinalAmount,

  accidentToDriverAmount,
  accidentToDriverDiscountAmount,
  accidentToDriverFinalAmount,

  passengerLiabilityAmount,
  passengerLiabilityDiscountAmount,
  passengerLiabilityFinalAmount,

  adminFeeFinalAmount,
  finalPremium,
  totalPremium,
  totalPremiumKhr,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <InsurerLogoWrapper insurer={insurer} />

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:policyTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premiumPaymentTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        {planType === CarPlanTypes.Comprehensive && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:coverValueIdv')}:</span>
            <span>{amountToUsd(idvAmount)}</span>
          </li>
        )}

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:planType')}:</span>
          <span>{tCarPlanType(planType)}</span>
        </li>
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        {planType === CarPlanTypes.Comprehensive && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">
              {t('common:od')} / {t('common:theft')}:
            </span>
            <AmountWithDiscount
              amount={ownDamageAmount}
              discountAmount={ownDamageDiscountAmount}
              finalAmount={ownDamageFinalAmount}
            />
          </li>
        )}

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:tpl')}:</span>
          <AmountWithDiscount
            amount={thirdPartyAmount}
            discountAmount={thirdPartyDiscountAmount}
            finalAmount={thirdPartyFinalAmount}
          />
        </li>

        {accidentToDriver && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:ad')}:</span>
            <AmountWithDiscount
              amount={accidentToDriverAmount}
              discountAmount={accidentToDriverDiscountAmount}
              finalAmount={accidentToDriverFinalAmount}
            />
          </li>
        )}

        {passengerLiability && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:pl')}:</span>
            <AmountWithDiscount
              amount={passengerLiabilityAmount}
              discountAmount={passengerLiabilityDiscountAmount}
              finalAmount={passengerLiabilityFinalAmount}
            />
          </li>
        )}
      </ul>

      <ul className="list-unstyled pb-2 border-bottom border-bottom-dashed">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premium')}:</span>
          <AmountWithDiscount finalAmount={finalPremium} />
        </li>

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:adminFee')}:</span>
          <AmountWithDiscount finalAmount={adminFeeFinalAmount} />
        </li>
      </ul>

      <ul className="list-unstyled pb-2 mb-0">
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:youWillPay')}:</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </li>
        <li className="d-flex justify-content-between">
          <span>&nbsp;</span>
          <span>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </span>
        </li>
      </ul>
    </Card.Body>
  );
};

export { CarQuotePremiumCardBody };
