import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { map, startCase, isNull } from 'lodash';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';

import { NoData, ClaimBadge } from '@components/Base';
import { formatDateTime } from '@utils/datetime';
import { tInsuranceTypeName } from '@models/InsuranceType';
import { isEmptyArray } from '@utils/lodash';

import { selectClaim } from './selector';
import { routeUserDashboardClaimDetails } from '../route';
import { Link } from 'react-router-dom';

const ClaimList = () => {
  const { t } = useTranslation();
  const { claims } = useSelector(selectClaim);

  if (isNull(claims)) return null;
  if (isEmptyArray(claims)) return <NoData />;

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>{t('common:policyNumber')}</th>
          <th>{t('common:orderNumber')}</th>
          <th>{pluralize.singular(t('common:insuranceProducts'))}</th>
          <th>{t('common:claimedDate')}</th>
          <th>{t('common:status')}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {map(claims, ({ id, orderNumber, policyNumber, status, createdAt, insuranceType, claimableType, claimableId }) => (
          <tr key={id} data-testid="claim-item">
            <td>{policyNumber}</td>
            <td>{orderNumber}</td>
            <td>{tInsuranceTypeName(insuranceType)}</td>
            <td>{formatDateTime(createdAt)}</td>
            <td>
              <ClaimBadge {...{ status }} />
            </td>
            <td>
              <Button as={Link} to={routeUserDashboardClaimDetails({ claimableType, claimableId })} variant="outline-primary" size="sm">
                {startCase(t('common:viewDetails'))}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export { ClaimList };
