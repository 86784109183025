import { CamelCaseKeys } from '@utils/typescript';
import { tEnum } from '@components/i18n';
import { ClaimStatuses } from '@models/Claim';

export enum PersonalAccidentClaimTypes {
  Death = 'Death',
  PermanentAndTotalDisablement = 'Permanent and total disablement',
  PermanentAndPartialDisablement = 'Permanent and partial disablement',
  MedicalExpense = 'Medical expense',
  CashAllowance = 'Cash allowance',
  InternationalEmergencyMedicalAssistance = 'International emergency medical assistance',
  Others = 'Others',
}

export type PersonalAccidentClaim = CamelCaseKeys<{
  id: number;
  status: ClaimStatuses;
  claim_type: string;
  completed_claim_form: string;
  supporting_documents: string[];
  invoice: string;
  total_claimed_amount: number;
}>;

export const getPersonalAccidentClaimType = (type: PersonalAccidentClaimTypes) => tEnum('personalAccidentClaimTypes', type);
