import { createActions } from 'redux-actions';
import { ClaimList } from '@models/Claim';
import { Pagination } from '@models/Pagination';
import { GetAllClaimParams } from '@apis/ClaimApi';

export type State = {
  claims?: ClaimList[] | null;
  pagination?: Pagination;
};

export type ClaimsFetchPayload = GetAllClaimParams;
export type StateUpdatePayload = Partial<State>;

export const {
  claimsFetch,
  stateUpdate,
} = createActions(
  'CLAIMS_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CLAIM',
  },
);
