import humps from 'lodash-humps-ts';
import { AxiosResponse } from 'axios';
import { toNumber } from 'lodash';
import { Numeric, WithParams } from '@utils/typescript';

export type Pagination = {
  nextPage?: number;
  prevPage?: number;
  perPage?: number;
  page: number;
  offset?: number;
  total?: number;
  totalPages: number;
};

export type PaginationParams = WithParams<{
  page?: Numeric;
  per_page?: Numeric;
}>;

export const paginate = (response: AxiosResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { xNextPage, xPrevPage, xPerPage, xPage, xOffset, xTotal, xTotalPages } = humps(response.headers);
  const pagination: Pagination = {
    nextPage: toNumber(xNextPage),
    prevPage: toNumber(xPrevPage),
    perPage: toNumber(xPerPage),
    page: toNumber(xPage),
    offset: toNumber(xOffset),
    total: toNumber(xTotal),
    totalPages: toNumber(xTotalPages),
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = humps(response.data);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [data, pagination];
};

export const isPaginated = (pagination?: Pagination | null): pagination is Pagination => !!pagination && pagination.totalPages > 1;
