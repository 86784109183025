import React from 'react';
import { includes } from 'lodash';
import { Button, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import i18n from '@components/i18n';
import { BackButton, FormGroupControl } from '@components/Base';

import { userDocumentUpdate } from './action';
import { FILE_SIZE, DOCUMENT_SUPPORTED_FORMATS } from '../DocumentNew/view';

const initialValues: FormValues = {
  document: null,
};

const validationSchema = Yup.object({
  document: Yup.mixed()
    .required(i18n.t('validation:required'))
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && includes(DOCUMENT_SUPPORTED_FORMATS, file.type),
    )
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE),
});

type FormValues = {
  document: File | null;
};

const DocumentEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(userDocumentUpdate({ id, ...values }));
    setSubmitting(false);
  };

  return (
    <>
      <BackButton className="mb-4" />
      <Card>
        <Card.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <FormGroupControl
                  type="file"
                  name="document"
                  label={t('common:document')}
                  required
                  groupProps={{ className: 'mb-3' }}
                />

                <Button type="submit" disabled={isSubmitting}>
                  {t('common:update')}
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export { DocumentEdit };
