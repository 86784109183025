import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { HealthQuote, HealthQuoteAnnualLimitAmount, HealthQuoteBenefit } from '@models/HealthQuote';
import { RowItem, RowItemProps } from '@components/Base';
import { amountToUsd } from '@utils/currency';
import { HealthPlanBenefit, HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';
import { NumericString } from '@utils/typescript';
import { isEmpty, map } from 'lodash';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
  labelProps: { lg: 4 },
};

type Props = HealthQuoteBenefit & HealthQuoteAnnualLimitAmount & Pick<HealthQuote,
  | 'geographicCoverage'
  | 'hospitals'
  | 'annualLimitAmount'
>;

const FeatureCardBody = ({
  geographicCoverage,
  hospitals,
  annualLimitAmount,
  inpatientAnnualLimitAmount,
  inpatientBenefits,
  outpatientAnnualLimitAmount,
  outpatientBenefits,
  healthCheckupAnnualLimitAmount,
  healthCheckupBenefits,
  maternityAnnualLimitAmount,
  maternityBenefits,
  visionAnnualLimitAmount,
  visionBenefits,
  dentalAnnualLimitAmount,
  dentalBenefits,
  copayAnnualLimitAmount,
  copayBenefits,
  othersAnnualLimitAmount,
  othersBenefits,
}: Props) => {
  const { t } = useTranslation();
  const items: [HealthPlanBenefitTypes, NumericString, HealthPlanBenefit[]][] = [
    [HealthPlanBenefitTypes.Inpatient, inpatientAnnualLimitAmount, inpatientBenefits],
    [HealthPlanBenefitTypes.Outpatient, outpatientAnnualLimitAmount, outpatientBenefits],
    [HealthPlanBenefitTypes.HealthCheckup, healthCheckupAnnualLimitAmount, healthCheckupBenefits],
    [HealthPlanBenefitTypes.Maternity, maternityAnnualLimitAmount, maternityBenefits],
    [HealthPlanBenefitTypes.Vision, visionAnnualLimitAmount, visionBenefits],
    [HealthPlanBenefitTypes.Dental, dentalAnnualLimitAmount, dentalBenefits],
    [HealthPlanBenefitTypes.Copay, copayAnnualLimitAmount, copayBenefits],
    [HealthPlanBenefitTypes.Others, othersAnnualLimitAmount, othersBenefits],
  ];

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem {...ROW_ITEM_PROPS} label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
      {map(items, ([type, amount, benefits]) => (
        <Fragment key={type}>
          {(isEmpty(benefits) || !Number(amount)) ? null : (
            <RowItem {...ROW_ITEM_PROPS} label={tHealthPlanBenefitType(type)} value={amountToUsd(amount)} />
          )}
        </Fragment>
      ))}
    </Card.Body>
  );
};

export { FeatureCardBody };
