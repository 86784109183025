import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam, useParseParams } from '@hooks/params';

import { cancerQuoteFetch, CancerQuoteFetchPayload, cancerQuoteFind, CancerQuoteFindPayload } from './action';
import { selectCancerQuoteBuyRoute } from './selector';
import { CancerQuoteBuyNewPageParams } from './route';
import { cancerQuoteBuyNewPageParamsSchema } from './schema';

export const useCancerQuoteBuyNewPageParams = () =>
  useParseParams<CancerQuoteBuyNewPageParams>(cancerQuoteBuyNewPageParamsSchema);

export const useCancerQuoteFind = (payload: CancerQuoteFindPayload) => {
  const dispatch = useDispatch();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  useEffectOnce(() => {
    dispatch(cancerQuoteFind(payload));
  });

  return cancerQuote;
};

export const useCancerQuoteFetch = () => {
  const cancerOrderId = Number(useRouteParam('cancerOrderId'));
  const dispatch = useDispatch();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  useEffectOnce(() => {
    if (cancerQuote && cancerQuote.id == cancerOrderId) return;
    const payload: CancerQuoteFetchPayload = { cancerOrderId };
    dispatch(cancerQuoteFetch(payload));
  });

  return cancerQuote;
};
