import { combineReducers } from 'redux';

import { dashboardReducer } from './Dashboard';
import { billingReducer } from './Billing';
import { billingDetailsReducer } from './BillingDetails';
import { passwordReducer } from './Password';
import { policyReducer } from './Policy';
import { policyDetailsReducer } from './PolicyDetails';
import { claimReducer } from './Claim';
import { ClaimDetailsReducer } from './ClaimDetails';
import { PolicyClaimReducer } from './PolicyClaim';
import { policyRenewalReducer } from './PolicyRenewal';
import { vehicleReducer } from './Vehicle';
import { VehicleDetailsReducer } from './VehicleDetails';
import { DocumentNewReducer } from './DocumentNew';
import { DocumentReducer } from './Document';
import { DocumentDetailsReducer } from './DocumentDetails';
import { personalInfoEditReducer } from './PersonalInfoEditPage';

export default combineReducers({
  dashboardReducer,
  billingReducer,
  billingDetailsReducer,
  passwordReducer,
  policyReducer,
  policyDetailsReducer,
  claimReducer,
  claimDetails: ClaimDetailsReducer,
  policyClaim: PolicyClaimReducer,
  policyRenewalReducer,
  vehicleReducer,
  vehicleDetails: VehicleDetailsReducer,
  documentNew: DocumentNewReducer,
  document: DocumentReducer,
  documentDetails: DocumentDetailsReducer,
  personalInfoEditReducer,
});
