import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectCurrentUser } from '@components/Auth';
import { tGender } from '@models/User';
import { formatDate } from '@utils/datetime';
import { RowItem, RowItemProps } from '@components/Base';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
  labelProps: { xs: 4, sm: 4 },
  valueProps: { xs: 'auto' }
};

const PersonalInfoPage = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  if (!currentUser) return null;

  const {
    email,
    dob,
    gender,
    mobileNumber,
    address,
    fullName,
    fullNameKm,
    personalCode,
    role,
    occupation,
    height,
    weight,
    bmi,
  } = currentUser;

  return (
    <>
      <section>
        <div className="d-flex justify-content-between mb-4">
          <h5>{t('common:personalInformation')}</h5>
          <Button as={Link} to={'edit'} variant="outline-primary" size="sm">
            {t('common:edit')}
          </Button>
        </div>
        <Card>
          <Card.Body>
            <RowItem {...ROW_ITEM_PROPS} label={t('user.fullName')} value={fullName} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.fullNameKm')} value={fullNameKm} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.mobileNumber')} value={mobileNumber} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.email')} value={email} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.gender')} value={tGender(gender)} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.height')} value={height} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.weight')} value={weight} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.bmi')} value={bmi} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.dob')} value={formatDate(dob)} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.personalCode')} value={personalCode} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.occupation')} value={occupation} />
            <RowItem {...ROW_ITEM_PROPS} label={t('user.address')} value={address} />
          </Card.Body>
        </Card>
      </section>

      <section className="mt-4">
        <h5 className="mb-4">{t('common:accountInformation')}</h5>
        <Card>
          <Card.Body>
            <RowItem {...ROW_ITEM_PROPS} label={t('common:role')} value={role} />
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export { PersonalInfoPage };
