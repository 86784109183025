import { combineReducers } from 'redux';
import { carPolicyDetailsReducer } from './CarPolicyDetails';
import { cancerPolicyDetailsReducer } from './CancerPolicyDetails';
import { generalPolicyDetailsReducer } from './GeneralPolicyDetails';
import { healthPolicyDetailsReducer } from './HealthPolicyDetails';
import { personalAccidentPolicyDetailsReducer } from './PersonalAccidentPolicyDetails';

export default combineReducers({
  carPolicyDetailsReducer,
  cancerPolicyDetailsReducer,
  generalPolicyDetailsReducer,
  healthPolicyDetailsReducer,
  personalAccidentPolicyDetailsReducer,
});
