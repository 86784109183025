import { CamelCaseKeys } from '@utils/typescript';
import { camelCase } from 'lodash';
import i18n from '@components/i18n';
import { ClaimStatuses } from '@models/Claim';

export const CLAIM_TYPES = [
  'Death liability',
  'Bodily injury liability',
  'Property damage liability',
  'Cost of defense',
  'Own damage',
  'Theft',
  'Accident to driver',
  'Passenger liability'
];

export type CarClaim = CamelCaseKeys<{
  id: number;
  status: ClaimStatuses;
  accident_happened_at: number;
  accident_photos: string[];
  claim_types: string[];
  passengers_and_third_parties_police_reports: string[];
  completed_claim_forms: string[];
  supporting_documents: string[];
  total_claimed_amount: number;
}>;

export const tCarClaimType = (type: string) => {
  return i18n.t(`carClaimTypes.${camelCase(type)}`);
};
