import { getRoute } from '@utils/route';

type RouteParams = {
  generalOrderId: number;
  billingId: number;
};

export const GENERAL_ORDER_PAYMENT_PAGE_URL = '/general-insurance-quote-payment/:generalOrderId/:billingId';

export const routeGeneralOrderPaymentPage = (routeParams: RouteParams) => getRoute(GENERAL_ORDER_PAYMENT_PAGE_URL, { routeParams });
