import { call, put, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentQuoteApi } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentOrderApi } from '@apis/PersonalAccidentOrderApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routePersonalAccidentOrderPaymentPage } from '@components/PersonalAccidentOrderPaymentPage';

import {
  personalAccidentQuoteFind,
  PersonalAccidentQuoteFindPayload,
  personalAccidentQuoteCreate,
  PersonalAccidentQuoteCreatePayload,
  personalAccidentQuoteFetch,
  PersonalAccidentQuoteFetchPayload,
  personalAccidentQuoteUpdate,
  PersonalAccidentQuoteUpdatePayload,
  personalAccidentQuoteCustom,
  PersonalAccidentQuoteCustomPayload,
  personalAccidentOrderPay,
  PersonalAccidentOrderPayPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { routePersonalAccidentQuoteBuyReviewPage } from './route';

function* handlePersonalAccidentQuoteFind({ payload }: SagaPayload<PersonalAccidentQuoteFindPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.find, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteCreate({ payload }: SagaPayload<PersonalAccidentQuoteCreatePayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.create, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      routePersonalAccidentQuoteBuyReviewPage({
        personalAccidentOrderId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch { }
}

function* handlePersonalAccidentQuoteFetch({ payload }: SagaPayload<PersonalAccidentQuoteFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.get, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteUpdate({ payload }: SagaPayload<PersonalAccidentQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.update, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      routePersonalAccidentQuoteBuyReviewPage({
        personalAccidentOrderId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch { }
}



function* handlePersonalAccidentQuoteCustom({ payload }: SagaPayload<PersonalAccidentQuoteCustomPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.custom, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      routePersonalAccidentQuoteBuyReviewPage({
        personalAccidentOrderId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch { }
}

function* handlePersonalAccidentOrderPay({ payload }: SagaPayload<PersonalAccidentOrderPayPayload>) {
  try {
    const { id, billingId } = (yield call(PersonalAccidentOrderApi.pay, payload)) as PersonalAccidentOrder;
    history.navigate(routePersonalAccidentOrderPaymentPage({ personalAccidentOrderId: id, billingId, }));
  } catch { }
}

export default function* () {
  yield takeLatest(personalAccidentQuoteFind, handlePersonalAccidentQuoteFind);
  yield takeLatest(personalAccidentQuoteCreate, handlePersonalAccidentQuoteCreate);
  yield takeLatest(personalAccidentQuoteFetch, handlePersonalAccidentQuoteFetch);
  yield takeLatest(personalAccidentQuoteUpdate, handlePersonalAccidentQuoteUpdate);
  yield takeLatest(personalAccidentQuoteCustom, handlePersonalAccidentQuoteCustom);
  yield takeLatest(personalAccidentOrderPay, handlePersonalAccidentOrderPay);
}
