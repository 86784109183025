import React from 'react';
import { ModalProps, Modal, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { CarQuote } from '@models/CarQuote';
import { CarQuoteDetailsHeader, CarQuoteDetailsTabs, routeCarQuoteDetailsPage } from '@components/CarQuoteDetailsRoute';

import { useCarQuotesResultPageParams } from './hook';

type Props = Pick<ModalProps, 'show' | 'onHide'> & {
  carQuote: CarQuote;
};

const CarQuoteItemDetailsModal = ({ carQuote, show, onHide }: Props) => {
  const { t } = useTranslation();
  const params = useCarQuotesResultPageParams();

  return (
    <Modal size="xl" show={show} onHide={onHide} scrollable data-testid="car-quote-details-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t('common:quoteDetails')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <CarQuoteDetailsHeader carQuote={carQuote} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <CarQuoteDetailsTabs carQuote={carQuote} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button as={Link} to={routeCarQuoteDetailsPage({ ...params, carPlanId: carQuote.carPlanId })} target="_blank" rel="noreferrer noopener">
          {startCase(t('common:viewDetails'))}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { CarQuoteItemDetailsModal };
