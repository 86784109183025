import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { tOrderStatus, getOrderStatusVariant, OrderStatuses } from '@models/Order';

type Props = BadgeProps & {
  status: OrderStatuses
};

const OrderBadge = ({ status, ...rest }: Props) => {
  const variant = getOrderStatusVariant(status);

  return (
    <Badge {...rest} data-testid={`order-badge ${variant}`} bg={variant}>
      {tOrderStatus(status)}
    </Badge>
  );
};

export { OrderBadge };
