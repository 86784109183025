import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isUndefined, isNull } from 'lodash';

import { FormGroupControl, Loading, LoadingError } from '@components/Base';
import { useCarFetch } from '@components/Feature/Car';

import { carQuoteSendEmail, CarQuoteSendEmailPayload } from '../action';
import { useCarQuoteDetailsPageParams } from '../hook';
import { routeCarQuoteDetailsPage } from '../route';
import { useInitialValues, useValidationSchema } from './hook';
import { FormValues } from './type';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteSendEmailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useCarQuoteDetailsPageParams();
  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const car = useCarFetch();

  if (isUndefined(car)) return <Loading />;
  if (isNull(car)) return <LoadingError />;

  const { name, manufacturedYear } = car;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteSendEmailPayload = { ...params, ...values };
    dispatch(carQuoteSendEmail(payload));
    setSubmitting(false);
    navigate(routeCarQuoteDetailsPage(params));
  };

  return (
    <Card>
      <Card.Body>
        <Alert variant="secondary">
          <span className="d-block">{name}</span>
          <span className="d-block fw-light">
            {t('common:privateCar')} . {manufacturedYear}
          </span>
        </Alert>
      </Card.Body>

      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormGroupControl name="firstName" label={t('user.firstName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="lastName" label={t('user.lastName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="email" label={t('user.email')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="note" label={t('common:note')} groupProps={GROUP_PROPS} as="textarea" rows={4} />

            <Button type="submit" variant="primary" size="lg">
              {t('common:sendNow')}
            </Button>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { CarQuoteSendEmailPage };
