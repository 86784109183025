import ABAPayLogo from '@images/aba-pay.png';
import KHQRLogo from '@images/khqr.png';
import { tEnum } from '@components/i18n';

export enum PaymentProviders {
  PayWay = 'payway',
}

export enum PaymentMethods {
  AbaPay = 'aba_pay',
  Bakong = 'bakong',
};

export type PaymentMethod = {
  value: PaymentMethods;
  image: string;
  title: string;
  description: string;
};

export const getPaymentMethod = (paymentMethod: PaymentMethods): PaymentMethod => {
  const mapped = {
    [PaymentMethods.AbaPay]: ABAPayLogo as string,
    [PaymentMethods.Bakong]: KHQRLogo as string,
  };

  return {
    value: paymentMethod,
    image: mapped[paymentMethod],
    title: tEnum('paymentMethods', paymentMethod),
    description: tEnum('paymentMethods', `${paymentMethod}Description`),
  };
};
