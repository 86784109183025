import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useRouteParam, useParseParams } from '@hooks/params';

import { healthQuoteFetch, HealthQuoteFetchPayload, healthQuoteFind, HealthQuoteFindPayload } from './action';
import { selectHealthQuoteBuyRoute } from './selector';
import { HealthQuoteBuyNewPageParams } from './route';
import { healthQuoteBuyNewPageParamsSchema } from './schema';

export const useHealthQuoteBuyNewPageParams = () =>
  useParseParams<HealthQuoteBuyNewPageParams>(healthQuoteBuyNewPageParamsSchema);

export const useHealthQuoteFind = (payload: HealthQuoteFindPayload) => {
  const dispatch = useDispatch();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  useEffectOnce(() => {
    dispatch(healthQuoteFind(payload));
  });

  return healthQuote;
};

export const useHealthQuoteEdit = () => {
  const healthOrderId = Number(useRouteParam('healthOrderId'));
  const dispatch = useDispatch();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  useEffectOnce(() => {
    if (healthQuote && healthQuote.healthOrderId == healthOrderId) return;
    const payload: HealthQuoteFetchPayload = { healthOrderId };
    dispatch(healthQuoteFetch(payload));
  });

  return healthQuote;
};
