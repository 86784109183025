import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import GeneralQuoteBuyNewPage from './GeneralQuoteBuyNewPage';
import GeneralQuoteBuyReviewPage from './GeneralQuoteBuyReviewPage';
import GeneralQuoteBuyEditPage from './GeneralQuoteBuyEditPage';
import GeneralQuoteBuyLockPage from './GeneralQuoteBuyLockPage';

const GeneralQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<GeneralQuoteBuyNewPage />} />
        <Route path="/:generalOrderId/review" element={<GeneralQuoteBuyReviewPage />} />
        <Route path="/:generalOrderId/edit" element={<GeneralQuoteBuyEditPage />} />
        <Route path="/:generalOrderId/lock" element={<GeneralQuoteBuyLockPage />} />
      </Routes>
    </>
  );
};

export { GeneralQuoteBuyRoute };
