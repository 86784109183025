import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormGroupControl } from '@components/Base';
import { selectCurrentUser } from '@components/Auth';
import i18n from '@components/i18n';

import { cancerQuoteSendEmail, CancerQuoteSendEmailPayload } from '../action';
import { useCancerQuoteDetailsPageParams } from '../hook';
import { routeCancerQuoteDetailsPage } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const validationSchema = Yup.object({
  firstName: Yup.string().required(i18n.t('validation:required')),
  lastName: Yup.string().required(i18n.t('validation:required')),
  email: Yup.string().email().required(i18n.t('validation:required')),
});

type FormValues = CancerQuoteSendEmailPayload;

const CancerQuoteSendEmailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useCancerQuoteDetailsPageParams();
  const currentUser = useSelector(selectCurrentUser);

  const initialValues: FormValues = useMemo(
    () => ({
      ...params,
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      email: currentUser?.email ?? '',
    }),
    [currentUser],
  );

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(cancerQuoteSendEmail(values));
    setSubmitting(false);
    navigate(routeCancerQuoteDetailsPage(params));
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <FormGroupControl name="firstName" label={t('user.firstName')} required groupProps={GROUP_PROPS} />
                  <FormGroupControl name="lastName" label={t('user.lastName')} required groupProps={GROUP_PROPS} />
                  <FormGroupControl name="email" label={t('user.email')} required groupProps={GROUP_PROPS} />

                  <Button type="submit" variant="primary" size="lg">
                    {t('common:sendNow')}
                  </Button>
                </Form>
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { CancerQuoteSendEmailPage };
