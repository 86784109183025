import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Loading, LoadingError } from '@components/Base';

import { OrderableCard } from '../orderable-card';
import { useCancerOrderFetch } from './hook';
import { QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';
import { FeatureCardBody } from '@components/CancerQuoteBuyRoute/CancerQuoteReviewPage/feature-card-body';
import { CancerBenefitCardBody } from '@components/CancerQuoteBuyRoute/CancerQuoteReviewPage/cancer-benefit-card-body';

const CancerPolicyDetails = () => {
  const cancerOrder = useCancerOrderFetch();

  if (isUndefined(cancerOrder)) return <Loading />;
  if (isNull(cancerOrder)) return <LoadingError />;

  return (
    <OrderableCard {...cancerOrder}>
      <FeatureCardBody {...cancerOrder} />
      <CancerBenefitCardBody {...cancerOrder} />
      <QuoteExclusionCardBody {...cancerOrder} />
      <QuoteDocumentCardBody {...cancerOrder} />
    </OrderableCard>
  );
};

export { CancerPolicyDetails };
