import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { FormGroupControl, FormGroupSelect } from '@components/Base';
import { useProvincesFetch } from '@components/Feature/Province';

import { selectCarQuoteBuy } from '../selector';
import { FormRequired } from './type';

const GROUP_PROPS = { className: 'mb-3' };

type Props = {
  required: FormRequired
}

const VehicleCardBody = ({ required }: Props) => {
  const { t } = useTranslation();
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const provinces = useProvincesFetch();

  return (
    <Card.Body>
      <Card.Title>{t('carQuoteBuyPage.carQuoteBuyForm.vehicleTitle')}</Card.Title>
      <Alert variant="secondary">
        <span className="d-block">{carQuote?.car?.name}</span>
        <span className="d-block fw-light">
          {t('common:privateCar')} . {carQuote?.manufacturedYear}
        </span>
      </Alert>

      <FormGroupControl
        name="engineNumber"
        label={t('car.engineNumber')}
        required={required.engineNumber}
        groupProps={GROUP_PROPS} />

      <FormGroupControl
        name="chassisNumber"
        label={t('car.chassisNumber')}
        required={required.chassisNumber}
        groupProps={GROUP_PROPS} />

      <FormGroupSelect
        name="registrationPlace"
        label={t('car.registrationPlace')}
        required={required.registrationPlace}
        groupProps={GROUP_PROPS}
      >
        <option></option>
        {map(provinces, (province) => (
          <option key={province} value={province}>
            {province}
          </option>
        ))}
      </FormGroupSelect>

      <FormGroupControl
        name="registrationNumber"
        label={t('car.registrationNumber')}
        required={required.registrationNumber}
        groupProps={GROUP_PROPS} />
    </Card.Body>
  );
};

export { VehicleCardBody };
