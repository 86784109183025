import { createActions } from 'redux-actions';
import { GetAllOrderParams } from '@apis/OrderApi';
import { Order } from '@models/Order';

export type State = {
  orders?: Order[];
}

export type OrdersFetchPayload = GetAllOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  ordersFetch,
  stateUpdate,
} = createActions(
  'ORDERS_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_DASHBOARD',
  },
);
