import { createContext } from 'react';
import { CarPlanCategories, CarPlanTypes } from '@models/CarPlan';

export type FilterType = {
  category: CarPlanCategories;
  planType: CarPlanTypes;
  idvAmount: number;
  accidentToDriver: boolean;
  passengerLiability: boolean;
};

export type FilterContextType = {
  filters: FilterType;
  updateFilters: (options: Partial<FilterType>) => void;
};

export const FilterContext = createContext<FilterContextType | undefined>(undefined);
