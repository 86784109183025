import { getRoute } from '@utils/route';
import { PersonalAccidentQuoteDetailsPageParams } from '@components/PersonalAccidentQuoteDetailsRoute/route';

export type PersonalAccidentQuoteBuyNewPageParams = PersonalAccidentQuoteDetailsPageParams;

export const PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL = '/personal-accident-insurance-quote-buy/*';
export const PERSONAL_ACCIDENT_QUOTE_BUY_NEW_PAGE_URL = '/personal-accident-insurance-quote-buy';
export const PERSONAL_ACCIDENT_QUOTE_BUY_EDIT_PAGE_URL = '/personal-accident-insurance-quote-buy/:personalAccidentOrderId/edit';
export const PERSONAL_ACCIDENT_QUOTE_BUY_REVIEW_PAGE_URL = '/personal-accident-insurance-quote-buy/:personalAccidentOrderId/review';
export const PERSONAL_ACCIDENT_QUOTE_BUY_CUSTOM_PAGE_URL = '/personal-accident-insurance-quote-buy/:personalAccidentOrderId/custom';

type RouteParams = {
  personalAccidentOrderId: number;
};

export const routePersonalAccidentQuoteBuyNewPage = (searchParams: PersonalAccidentQuoteDetailsPageParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_BUY_NEW_PAGE_URL, { searchParams });
export const routePersonalAccidentQuoteBuyEditPage = ({ personalAccidentOrderId, ...searchParams }: RouteParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_BUY_EDIT_PAGE_URL, { routeParams: { personalAccidentOrderId }, searchParams });
export const routePersonalAccidentQuoteBuyReviewPage = (routeParams: RouteParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_BUY_REVIEW_PAGE_URL, { routeParams });
export const routePersonalAccidentQuoteBuyCustomPage = (routeParams: RouteParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_BUY_CUSTOM_PAGE_URL, { routeParams });
