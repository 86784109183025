import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, BackButton, LoadingError } from '@components/Base';
import SupportSection from '@components/SupportSection';
import { useHealthQuoteResultPageParams, routeHealthQuoteResultPage } from '@components/HealthQuoteResultPage';
import { routeHealthQuoteBuyNewPage } from '@components/HealthQuoteBuyRoute';

import { useHealthQuoteFind } from '../hook';
import { HeaderSection } from './header-section';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';
import { HospitalSection } from './hospital-section';
import { MemberSection } from './member-section';
import { ExclusionSection } from './exclusion-section';
import { DocumentSection } from './document-section';
import { routeHealthQuoteSendEmailPage } from '../route';

const HealthQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const healthQuote = useHealthQuoteFind();

  if (isUndefined(healthQuote)) return <Loading />;
  if (isNull(healthQuote)) return <LoadingError />;

  const { healthPlanId, healthPlanName } = healthQuote;

  const handleBackClick = () => {
    navigate(routeHealthQuoteResultPage(params), { replace: true });
  };

  return (
    <>
      <Container className="mt-4">
        <BackButton onClick={handleBackClick} className="mb-4" />
      </Container>

      <HeaderSection
        {...healthQuote}
        planName={healthPlanName}
        buyLinkTo={routeHealthQuoteBuyNewPage({ ...params, healthPlanId })}
        sendEmailLinkTo={routeHealthQuoteSendEmailPage({ ...params, healthPlanId })}
      />
      <FeatureSection {...healthQuote} />
      <BenefitSection {...healthQuote} />
      <ExclusionSection {...healthQuote} />
      <DocumentSection {...healthQuote} />
      <HospitalSection {...healthQuote} />
      <MemberSection {...params} pathname='/health-insurance-quote/members' />

      <SupportSection />
    </>
  );
};

export { HealthQuoteDetailsPage };
