
import { createActions } from 'redux-actions';

import { GetPersonalAccidentOrderParams } from '@apis/PersonalAccidentOrderApi';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';

export type State = {
  personalAccidentOrder?: PersonalAccidentOrder | null;
};

export type PersonalAccidentOrderFetchPayload = GetPersonalAccidentOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentOrderFetch,
  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_ORDER_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_PERSONAL_ACCIDENT_POLICY_DETAILS',
  },
);
