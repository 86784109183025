import { all, fork } from 'redux-saga/effects';
import { carClaimDetailsSaga } from './CarClaimDetails';
import { personalAccidentClaimDetailsSaga } from './PersonalAccidentClaimDetails';
import { cancerClaimDetailsSaga } from './CancerClaimDetails';

export default function* () {
  yield all([
    fork(carClaimDetailsSaga),
    fork(personalAccidentClaimDetailsSaga),
    fork(cancerClaimDetailsSaga),
  ]);
}
