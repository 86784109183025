import humps from 'lodash-humps-ts';
import { number, object } from 'yup';
import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { Car } from '@models/Car';
import { WithParams } from '@utils/typescript';

export type GetIdvCarWithCarIdParams = WithParams<{
  manufactured_year: number;
  car_id: number;
}>;

export const getIdvCarWithCarIdParamsSchema = object({
  carId: number(),
  manufacturedYear: number(),
});

export type GetIdvCarWithoutCarIdParams = WithParams<{
  car_brand_id: number;
  car_model_id: number;
  car_sub_model_id: number;
  engine_capacity: number;
  manufactured_year: number;
}>;

export type GetIdvCarCarParams = GetIdvCarWithCarIdParams | GetIdvCarWithoutCarIdParams;

export type GetManufacturedYearsCarParams = WithParams<{
  car_sub_model_id: number;
}>;

export class CarApi {
  static getIdv = async (params: GetIdvCarCarParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car`, { params: snakes(params) })
      .then((response) => humps(response.data) as Car);
  };

  static getManufacturedYears = async ({ carSubModelId }: GetManufacturedYearsCarParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_sub_models/${carSubModelId}/cars/manufactured_years`)
      .then((response) => humps(response.data) as Pick<Car, 'id' | 'manufacturedYear'>[]);
  };
}
