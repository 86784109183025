import { createActions } from 'redux-actions';

import { FindCancerQuoteParams, SendEmailCancerQuoteParams } from '@apis/CancerQuoteApi';
import { CancerQuote } from '@models/CancerQuote';

export type State = {
  cancerQuote?: CancerQuote | null;
};

export type CancerQuoteFindPayload = FindCancerQuoteParams;
export type CancerQuoteSendEmailPayload = SendEmailCancerQuoteParams;
export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuoteFind,
  cancerQuoteSendEmail,

  stateUpdate,
} = createActions(
  'CANCER_QUOTE_FIND',
  'CANCER_QUOTE_SEND_EMAIL',

  'STATE_UPDATE',
  {
    prefix: 'CANCER_QUOTE_DETAILS_ROUTE',
  },
);
