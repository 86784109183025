import React from 'react';
import { Card, Col, ColProps } from 'react-bootstrap';

import { GeneralQuotePremiumCardBody } from '@components/Feature/GeneralQuote';
import { GeneralQuote } from '@models/GeneralQuote';

type Props = Pick<ColProps, 'children'> & {
  generalQuote: GeneralQuote
};

const RightCol = ({ children, generalQuote }: Props) => {
  if (!generalQuote) return null;

  return (
    <Col lg>
      <Card className="mb-4">
        <GeneralQuotePremiumCardBody {...generalQuote} />
        {children}
      </Card>
    </Col>
  );
};

export { RightCol };
