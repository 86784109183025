import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { Company } from '@models/Company';
import { API_V1_BASE } from '@config';

export class CompanyApi {
  static getAll = async () => {
    return await authAxios.get(`${API_V1_BASE}/companies`).then((response) => humps(response.data) as Company[]);
  };
}
