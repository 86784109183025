import { createActions } from 'redux-actions';

import { GetCarOrderParams } from '@apis/CarOrderApi';
import { CarOrder } from '@models/CarOrder';

export type State = {
  carOrder?: CarOrder | null;
};

export type CarOrderFetchPayload = GetCarOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carOrderFetch,
  stateUpdate,
} = createActions(
  'CAR_ORDER_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CAR_POLICY_DETAILS',
  },
);
