import React from 'react';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import SupportSection from '@components/SupportSection';
import healthSocialPreviewBanner from '@images/banner-health-insurance.jpg';

import CallToActionSection from './CallToActionSection';
import DefinitionSection from './DefinitionSection';
import { BenefitSection } from './benefit-section';
import { CoverSection } from './cover-section';
import { NoCoverSection } from './no-cover-section';
import { EligibilitySection } from './eligibility-section';
import { BuyEarlySection } from './buy-early-section';
import { FrequentlyAskedQuestionSection } from './frequently-asked-question-section';
import { DisclaimerSection } from './disclaimer-section';

export const HealthInsurancePage = () => {
  return (
    <>
      <MetaTag pageName="healthInsurancePage" image={healthSocialPreviewBanner as string} />
      <HeaderPrimary />
      <CallToActionSection />
      <DefinitionSection />
      <BenefitSection />
      <CoverSection />
      <NoCoverSection />
      <EligibilitySection />
      <BuyEarlySection />
      <FrequentlyAskedQuestionSection />
      <SupportSection />
      <DisclaimerSection />
      <Footer />
    </>
  );
};
