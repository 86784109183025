import { createActions } from 'redux-actions';

import { CancerQuote } from '@models/CancerQuote';
import { CreateCancerQuoteParams, CustomCancerQuoteParams, GetCancerQuoteParams, FindCancerQuoteParams, UpdateCancerQuoteParams } from '@apis/CancerQuoteApi';
import { PayCancerOrderParams } from '@apis/CancerOrderApi';

export type State = {
  cancerQuote?: CancerQuote | null;
};

export type CancerQuoteFindPayload = FindCancerQuoteParams;
export type CancerQuoteCreatePayload = CreateCancerQuoteParams;
export type CancerQuoteFetchPayload = GetCancerQuoteParams;
export type CancerQuoteCustomPayload = CustomCancerQuoteParams;
export type CancerQuoteUpdatePayload = UpdateCancerQuoteParams;
export type CancerOrderPayPayload = PayCancerOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuoteFind,
  cancerQuoteCreate,
  cancerQuoteFetch,
  cancerQuoteUpdate,
  cancerQuoteCustom,
  cancerOrderPay,
  stateUpdate,
} = createActions(
  'CANCER_QUOTE_FIND',
  'CANCER_QUOTE_CREATE',
  'CANCER_QUOTE_FETCH',
  'CANCER_QUOTE_UPDATE',
  'CANCER_QUOTE_CUSTOM',
  'CANCER_ORDER_PAY',
  'STATE_UPDATE',
  {
    prefix: 'CANCER_QUOTE_BUY_ROUTE',
  },
);
