import React from 'react';
import { Row, Col, RowProps, ColProps } from 'react-bootstrap';

export type RowItemProps = RowProps & ColProps & {
  labelProps?: ColProps;
  label?: ColProps['children'];
  valueProps?: ColProps;
  value?: ColProps['children'];
}

const RowItem = ({ label, labelProps, value, valueProps, ...rowProps }: RowItemProps) => {
  return (
    <Row {...rowProps}>
      <Col xs={4} sm={4} md={3} {...labelProps}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col {...valueProps}>{value}</Col>
    </Row>
  );
};

export { RowItem };
