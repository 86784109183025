import { call, put, takeLatest } from 'redux-saga/effects';

import { GeneralOrder } from '@models/GeneralOrder';
import { GeneralOrderApi } from '@apis/GeneralOrderApi';
import { SagaPayload } from '@utils/typescript';

import {
  generalOrderFetch,
  GeneralOrderFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleGeneralOrderFetch({ payload }: SagaPayload<GeneralOrderFetchPayload>) {
  const state: StateUpdatePayload = { generalOrder: null };
  try {
    state.generalOrder = (yield call(GeneralOrderApi.get, payload)) as GeneralOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(generalOrderFetch, handleGeneralOrderFetch);
}
