import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@images/logo.png';
import { NavbarBrand } from 'react-bootstrap';

const HeaderNavbarBrand = () => {
  return (
    <NavbarBrand as={Link} to={'/'}>
      <img
        alt="MGA Cover Offcial Logo"
        src={logo as string}
        width="60"
        height="48"
        className="d-inline-block align-middle"
      />
      <span className="d-inline-block align-middle">MGA Cover</span>
    </NavbarBrand>
  );
}

export { HeaderNavbarBrand };
