import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';

import { provincesFetch } from './action';
import { selectProvince } from './selector';

export const useProvincesFetch = () => {
  const dispatch = useDispatch();
  const { provinces } = useSelector(selectProvince);

  useEffectOnce(() => {
    if (!isEmpty(provinces)) return;
    dispatch(provincesFetch());
  });

  return provinces;
};
