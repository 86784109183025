import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useParseParams } from '@hooks/params';

import { selectCancerQuoteDetailsPage } from './selector';
import { cancerQuoteFind, CancerQuoteFindPayload } from './action';
import { CancerQuoteDetailsPageParams } from './route';
import { cancerQuoteDetailsPageParamsSchema } from './schema';

export const useCancerQuoteDetailsPageParams = () =>
  useParseParams<CancerQuoteDetailsPageParams>(cancerQuoteDetailsPageParamsSchema);

export const useCancerQuoteFind = () => {
  const dispatch = useDispatch();
  const payload: CancerQuoteFindPayload = useCancerQuoteDetailsPageParams();
  const { cancerQuote } = useSelector(selectCancerQuoteDetailsPage);

  useEffectOnce(() => {
    dispatch(cancerQuoteFind(payload));
  });

  return cancerQuote;
};
