import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import InsuredDeclaredValue from './InsuredDeclaredValue';
import PlanType from './PlanType';
import { FilterAddonCover } from './filter-addon-cover';
import { FilterCategory } from './filter-category';

const FilterSection = () => {
  return (
    <Container className="shadow rounded">
      <Row>
        <Col lg={3}>
          <InsuredDeclaredValue />
        </Col>
        <Col lg={3}>
          <FilterCategory />
        </Col>
        <Col lg={3}>
          <PlanType />
        </Col>
        <Col lg={3}>
          <FilterAddonCover />
        </Col>
      </Row>
    </Container>
  );
};

export { FilterSection };
