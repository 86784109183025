import { PersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type PersonalAccidentQuoteComparePageParams = Required<
  Omit<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
  Pick<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    personalAccidentPlanIds: number[];
  };

export const PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL = '/personal-accident-insurance-quote-compare';

export const routePersonalAccidentQuoteComparePage = (searchParams: PersonalAccidentQuoteComparePageParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL, { searchParams })
