import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { compact, flatten } from 'lodash';

import {
  faGripHorizontal,
  faReceipt,
  faFileContract,
  faInfoCircle,
  faCar,
  IconDefinition,
  faPlus,
  faKey,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { selectIsFrontline, selectIsInsurer, selectIsCompany, selectIsCustomer } from '@components/Auth';
import { routeGeneralQuoteBuyNewPage } from '@components/GeneralQuoteBuyRoute';

type DashboardItemKey =
  'dashboard'
  | 'billing'
  | 'policy'
  | 'claim'
  | 'vehicle'
  | 'document'
  | 'password'
  | 'personalInfo'
  | 'generalPolicy';

type DashboardItem = {
  key: DashboardItemKey;
  label: string;
  to: string;
  icon: IconDefinition;
};

export const useUserDashboard = () => {
  const { t } = useTranslation();
  const isCustomer = useSelector(selectIsCustomer);
  const isFrontline = useSelector(selectIsFrontline);
  const isInsurer = useSelector(selectIsInsurer);
  const isCompany = useSelector(selectIsCompany);
  const dashboardHref = '/user-dashboard';
  const billingHref = '/user-dashboard/billing';
  const policyHref = '/user-dashboard/policy';
  const claimHref = '/user-dashboard/claim';
  const vehicleHref = '/user-dashboard/vehicle';
  const documentHref = '/user-dashboard/document';
  const passwordHref = '/user-dashboard/password';
  const personalInfoHref = '/user-dashboard/personal-info';
  const generalQuoteBuyNewHref = routeGeneralQuoteBuyNewPage();

  const items = useMemo(() => {
    return flatten(compact([
      isCustomer && [
        {
          key: 'dashboard',
          label: t('common:dashboard'),
          to: dashboardHref,
          icon: faGripHorizontal,
        },
      ],
      {
        key: 'policy',
        label: t('common:policy'),
        to: policyHref,
        icon: faProductHunt,
      },
      isFrontline && [
        {
          key: 'generalPolicy',
          label: t('common:newGeneralPolicy'),
          to: generalQuoteBuyNewHref,
          icon: faPlus,
        }
      ],
      isCustomer && [
        {
          key: 'billing',
          label: t('common:billing'),
          to: billingHref,
          icon: faReceipt,
        },
        {
          key: 'claim',
          label: t('common:claim'),
          to: claimHref,
          icon: faFileContract,
        },
        {
          key: 'vehicle',
          label: t('common:vehicle'),
          to: vehicleHref,
          icon: faCar,
        },
        {
          key: 'document',
          label: t('common:document'),
          to: documentHref,
          icon: faIdCard,
        },
      ],
      {
        key: 'password',
        label: t('common:password'),
        to: passwordHref,
        icon: faKey,
      },
      {
        key: 'personalInfo',
        label: t('common:personalInfo'),
        to: personalInfoHref,
        icon: faInfoCircle,
      },
    ]) as DashboardItem[]);
  }, [isCustomer, isFrontline, isInsurer, isCompany]);

  return {
    items,
  };
};

export const useActiveKey = (): DashboardItemKey => {
  const { pathname } = useLocation();
  if (matchPath('/user-dashboard/policy/*', pathname)) return 'policy';
  if (matchPath('/user-dashboard/billing/*', pathname)) return 'billing';
  if (matchPath('/user-dashboard/claim/*', pathname)) return 'claim';
  if (matchPath('/user-dashboard/vehicle/*', pathname)) return 'vehicle';
  if (matchPath('/user-dashboard/document/*', pathname)) return 'document';
  if (matchPath('/user-dashboard/password/*', pathname)) return 'password';
  if (matchPath('/user-dashboard/personal-info/*', pathname)) return 'personalInfo';
  return 'dashboard';
};
