import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Loading, LoadingError } from '@components/Base';
import { HealthQuoteBenefitScheduleCardBody } from '@components/Feature/HealthQuote';
import { QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';
import { OrderableCard } from '../orderable-card';
import { useHealthOrderFetch } from './hook';
import { FeatureCardBody } from '@components/HealthQuoteBuyRoute/HealthQuoteReviewPage/feature-card-body';

const HealthPolicyDetails = () => {
  const healthOrder = useHealthOrderFetch();

  if (isUndefined(healthOrder)) return <Loading />;
  if (isNull(healthOrder)) return <LoadingError />;

  return (
    <OrderableCard {...healthOrder}>
      <FeatureCardBody {...healthOrder} />
      <HealthQuoteBenefitScheduleCardBody {...healthOrder} />
      <QuoteExclusionCardBody {...healthOrder} />
      <QuoteDocumentCardBody {...healthOrder} />
    </OrderableCard>
  );
};

export { HealthPolicyDetails };
