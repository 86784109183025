import { PeopleParams } from '@components/HealthQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type CancerQuoteResultPageParams = PeopleParams & {
  activationDate: string;
};

export const CANCER_QUOTE_RESULT_PAGE_URL = '/cancer-insurance-quote-results';

export const routeCancerQuoteResultPage = (searchParams: CancerQuoteResultPageParams) => getRoute(CANCER_QUOTE_RESULT_PAGE_URL, { searchParams });
