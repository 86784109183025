import { call, put, takeLatest } from 'redux-saga/effects';

import { GeneralOrderApi } from '@apis/GeneralOrderApi';
import { BillingApi } from '@apis/BillingApi';
import { PaymentTransactionApi } from '@apis/PaymentTransactionApi';
import { GeneralOrder } from '@models/GeneralOrder';
import { Billing } from '@models/Billing';
import { PaymentTransaction } from '@models/PaymentTransaction';

import { SagaPayload } from '@utils/typescript';
import {
  generalOrderFetch,
  GeneralOrderFetchPayload,
  paymentTransactionCreate,
  PaymentTransactionCreatePayload,
  billingFetch,
  BillingFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleGeneralOrderFetch({ payload }: SagaPayload<GeneralOrderFetchPayload>) {
  const state: StateUpdatePayload = { generalOrder: null };
  try {
    state.generalOrder = (yield call(GeneralOrderApi.get, payload)) as GeneralOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePaymentTransactionCreate({ payload }: SagaPayload<PaymentTransactionCreatePayload>) {
  const state: StateUpdatePayload = { paymentTransaction: null };
  try {
    state.paymentTransaction = (yield call(PaymentTransactionApi.create, payload)) as PaymentTransaction;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(generalOrderFetch, handleGeneralOrderFetch);
  yield takeLatest(paymentTransactionCreate, handlePaymentTransactionCreate);
  yield takeLatest(billingFetch, handleBillingFetch);
}
