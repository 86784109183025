import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { CopyLinkButton, Loading, LoadingError } from '@components/Base';

import CarQuoteDetailsTabs from '../CarQuoteDetailsTabs';
import { useCarQuoteFind } from '../hook';
import { CarQuoteDetailsHeader } from './header';

const CarQuoteDetailsPage = () => {
  const { t } = useTranslation();
  const carQuote = useCarQuoteFind();

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;

  const { car } = carQuote;

  return (
    <>
      <div className="d-flex align-items-center justify-content-sm-end my-3">
        <CopyLinkButton />
      </div>

      <Card>
        <Card.Header>
          <CarQuoteDetailsHeader carQuote={carQuote} />
        </Card.Header>

        <Card.Body>
          <Alert variant="secondary">
            <span className="d-block">{car.name}</span>
            <span className="d-block fw-light">
              {t('common:privateCar')} . {car.manufacturedYear}
            </span>
          </Alert>
        </Card.Body>

        <Card.Body>
          <CarQuoteDetailsTabs carQuote={carQuote} />
        </Card.Body>
      </Card>
    </>
  );
};

export { CarQuoteDetailsPage };
