import { CamelCaseKeys } from '@utils/typescript';
import { tEnum } from '@components/i18n';

export enum HealthPlanIpdBenefitTypes {
  IpdHospitalRoomAndBoard = 'ipd_hospital_room_and_board',
  IpdIcuAndSimilar = 'ipd_icu_and_similar',
  IpdHospitalGeneralExpenses = 'ipd_hospital_general_expenses',
  IpdSurgicalBenefits = 'ipd_surgical_benefits',
  IpdPsychiatricCare = 'ipd_psychiatric_care',
  IpdCashAllowance = 'ipd_cash_allowance',
  IpdEmergencyTransportationFee = 'ipd_emergency_transportation_fee',
}

export enum HealthPlanOpdBenefitTypes {
  OpdBenefits = 'opd_benefits',
  OpdPrescribed = 'opd_prescribed',
}

export enum HealthPlanOthersBenefitTypes {
  OtherPreExistingCondition = 'other_pre_existing_condition',
  OtherChronicCondition = 'other_chronic_condition',
  OtherAidHiv = 'other_aid_hiv',
  OtherCongenitalCondition = 'other_congenital_condition',
  OtherCovid19 = 'other_covid19',
}

export enum HealthPlanBenefitTypes {
  Inpatient = 'inpatient',
  Outpatient = 'outpatient',
  HealthCheckup = 'health_checkup',
  Maternity = 'maternity',
  Vision = 'vision',
  Dental = 'dental',
  Copay = 'copay',
  Others = 'others',
}

export const tHealthPlanBenefitType = (
  type: HealthPlanBenefitTypes | HealthPlanIpdBenefitTypes | HealthPlanOpdBenefitTypes | HealthPlanOthersBenefitTypes,
) => {
  return tEnum('HealthPlanBenefitTypes', type);
};

export type HealthPlanBenefit = CamelCaseKeys<{
  benefit_type: string;
  description: string;
}>;
