import { getRoute } from '@utils/route';
import { CancerQuoteDetailsPageParams } from '@components/CancerQuoteDetailsRoute/route';

export type CancerQuoteBuyNewPageParams = CancerQuoteDetailsPageParams;

export const CANCER_QUOTE_BUY_ROUTE_URL = '/cancer-insurance-quote-buy/*';
export const CANCER_QUOTE_BUY_NEW_PAGE_URL = '/cancer-insurance-quote-buy';
export const CANCER_QUOTE_BUY_EDIT_PAGE_URL = '/cancer-insurance-quote-buy/:cancerOrderId/edit';
export const CANCER_QUOTE_BUY_REVIEW_PAGE_URL = '/cancer-insurance-quote-buy/:cancerOrderId/review';
export const CANCER_QUOTE_BUY_CUSTOM_PAGE_URL = '/cancer-insurance-quote-buy/:cancerOrderId/custom';

type RouteParams = {
  cancerOrderId: number;
}

export const routeCancerQuoteBuyNewPage = (searchParams: CancerQuoteBuyNewPageParams) => getRoute(CANCER_QUOTE_BUY_NEW_PAGE_URL, { searchParams });
export const routeCancerQuoteBuyEditPage = (routeParams: RouteParams) => getRoute(CANCER_QUOTE_BUY_EDIT_PAGE_URL, { routeParams });
export const routeCancerQuoteBuyReviewPage = (routeParams: RouteParams) => getRoute(CANCER_QUOTE_BUY_REVIEW_PAGE_URL, { routeParams });
export const routeCancerQuoteBuyCustomPage = (routeParams: RouteParams) => getRoute(CANCER_QUOTE_BUY_CUSTOM_PAGE_URL, { routeParams });
