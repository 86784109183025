import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { EFFECTIVE_DAY_MIN, EFFECTIVE_DAY_MAX } from '@config';
import { StepFormProps } from '@components/Base';
import { CardHeader } from './card-header';
import { ActivationDateControl } from '@components/Feature/Quote';

const EffectiveDateStepForm = ({ onPrev, onNext, isLastStep }: StepFormProps) => {
  const { t } = useTranslation();

  return (
    <Card className="shadow">
      <CardHeader onClick={() => onPrev?.()} title={t('healthQuoteForm.policyEffectiveDate.title')} />
      <Card.Body>
        <Row>
          <Col>
            <ActivationDateControl
              required
              help={t('form.inputs.date.help')}
              min={moment().add(EFFECTIVE_DAY_MIN, 'day').format('YYYY-MM-DD')}
              max={moment().add(EFFECTIVE_DAY_MAX, 'day').format('YYYY-MM-DD')}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className="d-grid">
          <Button size="lg" onClick={() => onNext()}>
            {isLastStep ? t('common:getQuote') : t('common:next')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export { EffectiveDateStepForm };
