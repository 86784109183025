import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';

import { orderTemplatesFetch } from './action';
import { selectOrderTemplate } from './selector';

export const useOrderTemplatesFetch = () => {
  const dispatch = useDispatch();
  const { orderTemplates } = useSelector(selectOrderTemplate);

  useEffectOnce(() => {
    if (!isEmpty(orderTemplates)) return;
    dispatch(orderTemplatesFetch());
  });

  return orderTemplates;
};
