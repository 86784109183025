import { createActions } from 'redux-actions';

import { CreateGeneralQuoteParams, GetGeneralQuoteParams, UpdateGeneralQuoteParams, LockGeneralQuoteParams } from '@apis/GeneralQuoteApi';
import { GeneralQuote } from '@models/GeneralQuote';
import { PayGeneralOrderParams } from '@apis/GeneralOrderApi';

export type State = {
  generalQuote?: GeneralQuote | null;
};

export type GeneralQuoteCreatePayload = CreateGeneralQuoteParams;
export type GeneralQuoteFetchPayload = GetGeneralQuoteParams;
export type GeneralQuoteUpdatePayload = UpdateGeneralQuoteParams;
export type GeneralQuoteLockPayload = LockGeneralQuoteParams;
export type GeneralOrderPayPayload = PayGeneralOrderParams;
export type StateUpdatePayload = Partial<State>;

export const {
  generalQuoteCreate,
  generalQuoteFetch,
  generalQuoteUpdate,
  generalQuoteLock,
  generalOrderPay,
  stateUpdate,
} = createActions(
  'GENERAL_QUOTE_CREATE',
  'GENERAL_QUOTE_FETCH',
  'GENERAL_QUOTE_UPDATE',
  'GENERAL_QUOTE_LOCK',
  'GENERAL_ORDER_PAY',
  'STATE_UPDATE',
  {
    prefix: 'GENERAL_QUOTE_BUY_ROUTE',
  },
);
