import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { OrderTemplate } from '@models/OrderTemplate';
import { API_V1_BASE } from '@config';

export class OrderTemplateApi {
  static getAll = async () => {
    return await authAxios.get(`${API_V1_BASE}/order_templates`).then((response) => humps(response.data) as OrderTemplate[]);
  };
}
