import React from 'react';
import { map, startCase } from 'lodash';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useHeaderNavLinks } from './hook';

const HeaderNavLink = () => {
  const location = useLocation();
  const navLinks = useHeaderNavLinks();

  return (
    <>
      {map(navLinks, ({ label, to }) => (
        <NavLink as={Link} key={label} eventKey={to} to={to} active={location.pathname === to}>
          {startCase(label)}
        </NavLink>
      ))}
    </>
  );
};

export { HeaderNavLink };
