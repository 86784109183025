import { object, number } from 'yup';
import { healthQuoteResultPageParamsSchema } from '@components/HealthQuoteResultPage/schema';

export const healthQuoteDetailsPageParamsSchema = healthQuoteResultPageParamsSchema.concat(
  object({
    healthPlanId: number(),
  }),
);

export const healthQuoteSendEmailPageParamsSchema = healthQuoteDetailsPageParamsSchema;
