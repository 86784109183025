import React from 'react';
import { Card, Tabs, Tab, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { CarQuote } from '@models/CarQuote';
import {
  CarQuoteCoverCardBody,
  CarQuoteDeductibleCardBody,
  CarQuoteEndorsementCardBody
} from '@components/Feature/CarQuote';
import { QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';
import { CarPlanTypes } from '@models/CarPlan';
import { PremiumBreakdownCard } from './premium-breakdown-card';

interface Props {
  carQuote: CarQuote;
}

const CarQuoteDetailsTabs = ({ carQuote }: Props) => {
  const { t } = useTranslation();
  const { planType, garages } = carQuote;

  return (
    <Tabs defaultActiveKey="premiumBreakdown" variant="pills" className="p-3 d-flex flex-column flex-lg-row">
      <Tab eventKey="premiumBreakdown" title={t('common:premiumBreakdown')}>
        <PremiumBreakdownCard carQuote={carQuote} />
      </Tab>

      <Tab eventKey="coverageSummary" title={t('carInsuranceQuote.planDetails')}>
        <Card className="mb-3">
          <CarQuoteCoverCardBody {...carQuote} />
          <CarQuoteDeductibleCardBody {...carQuote} />
          <QuoteExclusionCardBody {...carQuote} />
          <QuoteDocumentCardBody {...carQuote} />
          <CarQuoteEndorsementCardBody {...carQuote} />
        </Card>
      </Tab>

      {planType === CarPlanTypes.Comprehensive && (
        <Tab eventKey="preferredGarages" title={t('carInsuranceQuote.cashlessGarages')}>
          <Card>
            <Card.Body>
              <Card.Title>{t('carInsuranceQuote.garageNearYou')}</Card.Title>
              <ListGroup as="ol" numbered variant="flush">
                {map(garages, ({ name, fullAddress, telNumber }) => (
                  <ListGroup.Item
                    key={name}
                    as="li"
                    className="d-flex justify-content-between align-items-start border-bottom-2"
                  >
                    <div className="ms-2 me-auto">
                      <h6>{name}</h6>
                      <span>{fullAddress}</span>
                    </div>
                    <p>{telNumber}</p>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Tab>
      )}
    </Tabs>
  );
};

export { CarQuoteDetailsTabs };
