import { useTranslation } from 'react-i18next';
import { date, object, string, number } from 'yup';

import { yupFile } from '@utils/yup';
import { useUserFormSchema, useUserFormValues } from '@components/Feature/User/UserFormControl';

import { FormValues } from './type';

export const useFormValues = () => {
  const userValues = useUserFormValues();
  const values: FormValues = {
    ...userValues,
    insurerId: '',
    orderTemplateId: '',
    activationDate: '',
    adminFeeAmount: '',
    premium: '',
    descriptionHtml: '',
    note: '',
    proof: undefined,
  };
  return values;
};

export const useFormSchema = () => {
  const { t } = useTranslation();
  const useSchema = useUserFormSchema();
  return useSchema.concat(object({
    insurerId: number().required(t('validation:required')),
    orderTemplateId: number().required(t('validation:required')),
    activationDate: date().required(t('validation:required')),
    adminFeeAmount: number().required(t('validation:required')),
    premium: number().required(t('validation:required')),
    descriptionHtml: string().required(t('validation:required')),
    note: string().required(t('validation:required')),
    proof: yupFile(),
  }));
};
