import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';
import { useIsRequired } from '@hooks/yup';

import CarQuoteBuyForm, { FormValues, FormRequired } from '../CarQuoteBuyForm';
import { carQuoteCreate, CarQuoteCreatePayload } from '../action';
import { useCarQuoteFind, useCarQuoteBuyNewPageParams } from '../hook';
import { RightCol } from '../right-col';
import { useInitialValues, useValidationSchema } from './hook';

const CarQuoteBuyNewPage = () => {
  const dispatch = useDispatch();
  useCurrentUser();
  const params = useCarQuoteBuyNewPageParams();
  const carQuote = useCarQuoteFind(params);
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const required = useIsRequired<FormRequired>(validationSchema);

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteCreatePayload = { ...values, ...params };
    dispatch(carQuoteCreate(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <CarQuoteBuyForm required={required} />
          </Formik>
        </Col>
        <RightCol carQuote={carQuote} />
      </Row>
    </Container>
  );
};

export { CarQuoteBuyNewPage };
