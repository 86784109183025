import React from 'react';
import { Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Quotable } from '@models/Quote';

const QuotePremiumChangeAlertCardBody = ({ activationDate }: Pick<Quotable, 'activationDate'>) => {
  const { t } = useTranslation();
  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');

  if (isValidActivationDate) return null;

  return (
    <Card.Body>
      <Alert variant="danger">
        <Alert.Heading className="d-flex align-items-center">
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" className="me-2" />
          {t('alert.premiumChange.title')}
        </Alert.Heading>
        <p>{t('alert.premiumChange.effectiveDate')}</p>
      </Alert>
    </Card.Body>
  );
};

export { QuotePremiumChangeAlertCardBody };
