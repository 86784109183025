import React from 'react';
import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLock, faPencil } from '@fortawesome/free-solid-svg-icons';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';
import { Loading, CopyLinkButton, LoadingError } from '@components/Base';
import { OrderStatuses } from '@models/Order';
import { OrderInvoiceLink } from '@components/Feature/Order';
import { QuoteBaseInfoCardBody } from '@components/Feature/Quote';
import { GeneralQuoteDescriptionCardBody } from '@components/Feature/GeneralQuote';
import { OwnerCardBody } from '@components/CarQuoteBuyRoute/CarQuoteBuyReviewPage/owner-card-body';

import { useGeneralQuoteFetch } from '../hook';
import { routeGeneralQuoteBuyEditPage, routeGeneralQuoteBuyLockPage } from '../route';
import { generalOrderPay, GeneralOrderPayPayload } from '../action';
import { RightCol } from '../right-col';

const GeneralQuoteBuyReviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);
  const generalQuote = useGeneralQuoteFetch();

  if (isUndefined(generalQuote)) return <Loading />;
  if (isNull(generalQuote)) return <LoadingError />;

  const { user, status, generalOrderId, orderEncryptedId } = generalQuote;
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const isActive = status === OrderStatuses.Active;
  const shouldEdit = isFrontline && (isPending || isLocked || isActive);
  const shouldLock = isFrontline && isPending;
  const shouldPay = isCustomer && isLocked;

  const handlePay = () => {
    const payload: GeneralOrderPayPayload = { id: generalOrderId };
    dispatch(generalOrderPay(payload));
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Card>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center">
              {t('common:summary')}
              <CopyLinkButton />
            </Card.Header>
            <QuoteBaseInfoCardBody {...generalQuote} />
            <OwnerCardBody {...user} />
            <GeneralQuoteDescriptionCardBody {...generalQuote} />
          </Card>
        </Col>

        <RightCol generalQuote={generalQuote}>
          <Card.Body>
            {isFrontline && (
              <div className="text-end">
                <OrderInvoiceLink orderEncryptedId={orderEncryptedId} />
              </div>
            )}

            <Stack direction="vertical" gap={2}>
              {shouldEdit && (
                <Button as={Link} to={routeGeneralQuoteBuyEditPage({ generalOrderId })} variant="outline-primary">
                  <FontAwesomeIcon icon={faPencil} className="me-2" />
                  {t('common:edit')}
                </Button>
              )}

              {shouldLock && (
                <Button as={Link} to={routeGeneralQuoteBuyLockPage({ generalOrderId })} variant="warning">
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  {t('common:confirm')}
                </Button>
              )}

              {shouldPay && (
                <Button onClick={handlePay}>
                  <span className="me-2">{startCase(t('common:payNow'))}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}
            </Stack>
          </Card.Body>
        </RightCol>
      </Row>
    </Container >
  );
};

export { GeneralQuoteBuyReviewPage };
