import React from 'react';
import { Pagination as BsPagination } from 'react-bootstrap';
import { map, range } from 'lodash';
import { useSearchParams } from 'react-router-dom';

type Props = {
  page: number;
  totalPages: number;
}

const Pagination = ({ page, totalPages }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (value: number) => {
    searchParams.set('page', value.toString());
    setSearchParams(searchParams);
    window.scrollTo(0, 0);
  }

  return (
    <BsPagination>
      {map(range(1, totalPages + 1), (value) => (
        <BsPagination.Item key={value} active={value === page} onClick={() => handleClick(value)}>
          {value}
        </BsPagination.Item>
      ))}
    </BsPagination>
  );
};

export { Pagination };
