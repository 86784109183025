import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { dOrderPeriod, getOrderStatusVariant, Order } from '@models/Order';
import { InsurerLogo } from '@components/Feature/Insurer';
import { tInsuranceTypeName } from '@models/InsuranceType';
import { getInsurerHotlines } from '@models/Insurer';
import { selectIsFrontline } from '@components/Auth';

import { RowItem } from './row-item';
import { routeUserDashboardPolicyClaim, routeUserDashboardPolicyDetails, routeUserDashboardPolicyRenewal } from '../route';

type Props = {
  order: Order;
}

const OrderItem = ({ order }: Props) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const {
    orderableType,
    orderableId,
    insuranceType,
    policyNumber,
    renewable,
    claimable,
    startedAt,
    endedAt,
    insurer,
    status
  } = order;
  const statusVariant = getOrderStatusVariant(status);
  const hotline = getInsurerHotlines(insurer, insuranceType);

  return (
    <Card border={statusVariant}>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
        <h5 className="mb-0">{tInsuranceTypeName(insuranceType)}</h5>
        <InsurerLogo insurer={insurer} fluid />
      </Card.Header>
      <Card.Body>
        <RowItem label={t('common:policyNumber')} value={policyNumber} />
        <RowItem label={t('common:periodOfInsurance')} value={dOrderPeriod(startedAt, endedAt)} />

        <div className="mb-2">
          <small ><Trans i18nKey="common:emergencyCall" values={{ hotline }} /></small>
        </div>

        <Stack direction='horizontal' gap={2}>
          <Button as={Link} to={routeUserDashboardPolicyDetails({ orderableType, orderableId })} variant="outline-primary">
            {startCase(t('common:viewDetails'))}
          </Button>

          {claimable && (
            <Button as={Link} to={routeUserDashboardPolicyClaim({ orderableType, orderableId })} variant="outline-primary">
              {t('common:claim')}
            </Button>
          )}

          {(renewable && isFrontline) && (
            <Button as={Link} to={routeUserDashboardPolicyRenewal({ orderableType, orderableId })} variant="outline-primary">
              {t('common:renew')}
            </Button>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export { OrderItem };
