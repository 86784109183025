import { Genders } from '@models/User';
import { getRoute } from '@utils/route';

export type PeopleParams = {
  me: {
    gender: Genders;
    dob: string;
  };
  spouse?: {
    dob: string;
  };
  sons?: {
    dob: string;
  }[];
  daughters?: {
    dob: string;
  }[];
};

export type HealthQuotesFilterParams = {
  inpatient: boolean;
  outpatient: boolean;
  maternity: boolean;
  healthCheckup: boolean;
  vision: boolean;
  dental: boolean;
};

export type HealthQuoteResultPageParams = PeopleParams & {
  filters?: HealthQuotesFilterParams;
  activationDate: string;
};

export const HEALTH_QUOTE_RESULT_PAGE_URL = '/health-insurance-quote-results';

export const routeHealthQuoteResultPage = (searchParams: HealthQuoteResultPageParams) => getRoute(HEALTH_QUOTE_RESULT_PAGE_URL, { searchParams });
