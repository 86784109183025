import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map, isUndefined } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useEffectOnce } from 'usehooks-ts';

import { NoData, Loading } from '@components/Base';
import { isEmptyArray } from '@utils/lodash';

import { ordersFetch, OrdersFetchPayload } from './action';
import { selectDashboard } from './selector';
import { OrderItem } from './order-item';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orders } = useSelector(selectDashboard);

  useEffectOnce(() => {
    const payload: OrdersFetchPayload = { status: 'active' };
    dispatch(ordersFetch(payload));
  });

  if (isUndefined(orders)) return <Loading />;
  if (isEmptyArray(orders)) return <NoData />;

  return (
    <section>
      <h5 className="mb-4">{t('common:dashboard')}</h5>
      <Row className="mb-4 gy-5" data-testid="dashboard-insurance-card-list">
        {map(orders, (order) => (
          <Col xl={9} key={order.orderNumber}>
            <OrderItem order={order} />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export { Dashboard };
