import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDate } from '@utils/datetime';
import { selectIsFrontline, selectIsInsurer } from '@components/Auth';
import { OrderBadge } from '@components/Feature/Order';
import { dOrderPeriod } from '@models/Order';
import { Quotable } from '@models/Quote';
import { DownloadLinkButton, RowItem } from '@components/Base';
import { dUserFullname } from '@models/User';

const ROW_ITEM_PROPS = {
  className: 'mb-2',
};

const QuoteBaseInfoCardBody = ({
  policyNumber,
  activationDate,
  startedAt,
  endedAt,
  status,
  user,
  frontlineUser,
  customType,
  note,
  proof,
}: Quotable) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const isInsurer = useSelector(selectIsInsurer);
  const shouldRenderPeriod = !!startedAt && !!endedAt;

  return (
    <Card.Body>
      <Card.Title>{t('common:policyDetails')}</Card.Title>
      <RowItem {...ROW_ITEM_PROPS} label={t('common:policyNumber')} value={policyNumber} />
      <RowItem {...ROW_ITEM_PROPS} label={t('common:theInsuredPolicyholder')} value={dUserFullname(user)} />
      {shouldRenderPeriod ? (
        <RowItem {...ROW_ITEM_PROPS} label={t('common:periodOfInsurance')} value={dOrderPeriod(startedAt, endedAt)} />
      ) : (
        <RowItem {...ROW_ITEM_PROPS} label={t('common:effectiveDate')} value={formatDate(activationDate) || t('common:instance')} />
      )}
      <RowItem {...ROW_ITEM_PROPS} label={t('common:status')} value={<OrderBadge status={status} />} />
      {(isFrontline || isInsurer) && <RowItem {...ROW_ITEM_PROPS} label={t('common:customType')} value={customType} />}
      <RowItem {...ROW_ITEM_PROPS} label={t('common:frontlineUser')} value={dUserFullname(frontlineUser)} />

      {(isFrontline || isInsurer) && (
        <>
          <RowItem {...ROW_ITEM_PROPS} label={t('common:note')} value={note} />
          {proof && <DownloadLinkButton to={proof}>{t('common:proof')}</DownloadLinkButton>}
        </>
      )}
    </Card.Body>
  );
};

export { QuoteBaseInfoCardBody };
