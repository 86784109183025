import { createActions } from 'redux-actions';

import { InsurerList } from '@models/Insurer';

export type State = {
  insurers: InsurerList[];
};

export type StateUpdatePayload = Partial<State>;

export const {
  insurersFetch,
  stateUpdate,
} = createActions(
  'INSURERS_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'FEATURE_INSURER',
  },
);
