import { chain } from 'lodash';
import { HealthQuoteList } from '@models/HealthQuote';

export type HealthQuoteGroup = {
  insurer: HealthQuoteList['insurer'];
  healthQuotes: HealthQuoteList[];
};

export function groupHealthQuotes(healthQuotes: HealthQuoteList[]): HealthQuoteGroup[] {
  return chain(healthQuotes)
    .groupBy('insurer.id')
    .map((mappedHealthQuotes: HealthQuoteList[]): HealthQuoteGroup => ({
      insurer: mappedHealthQuotes[0].insurer,
      healthQuotes: mappedHealthQuotes,
    }))
    .value();
}
