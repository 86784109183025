import { call, put, takeLatest } from 'redux-saga/effects';
import { ArticleApi } from '@apis/ArticleApi';
import { ArticleList } from '@models/Article';
import { Pagination } from '@models/Pagination';
import { SagaPayload } from '@utils/typescript';
import { articlesFetch, ArticlesFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleArticlesFetch({ payload }: SagaPayload<ArticlesFetchPayload>) {
  const state: StateUpdatePayload = { articles: null, pagination: undefined };
  try {
    const [articles, pagination] = (yield call(ArticleApi.getAll, payload)) as [ArticleList[], Pagination];
    state.articles = articles;
    state.pagination = pagination;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(articlesFetch, handleArticlesFetch);
}
