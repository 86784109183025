import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, LoadingError, BackButton } from '@components/Base';
import SupportSection from '@components/SupportSection';

import { usePersonalAccidentQuoteResultPageParams, routePersonalAccidentQuoteResultPage } from '@components/PersonalAccidentQuoteResultPage';
import { routePersonalAccidentQuoteBuyNewPage } from '@components/PersonalAccidentQuoteBuyRoute';

import { usePersonalAccidentQuoteFind } from '../hook';
import { routePersonalAccidentQuoteSendEmailPage } from '../route';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';

import { HeaderSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/header-section';
import { ExclusionSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/exclusion-section';
import { DocumentSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/document-section';
import { HospitalSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/hospital-section';
import { MemberSection } from '@components/HealthQuoteDetailsRoute/HealthQuoteDetailsPage/member-section';

const PersonalAccidentQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = usePersonalAccidentQuoteResultPageParams();
  const personalAccidentQuote = usePersonalAccidentQuoteFind();

  if (isUndefined(personalAccidentQuote)) return <Loading />;
  if (isNull(personalAccidentQuote)) return <LoadingError />;

  const { personalAccidentPlanId, personalAccidentPlanName } = personalAccidentQuote;

  const handleBackClick = () => {
    navigate(routePersonalAccidentQuoteResultPage(params), { replace: true });
  };

  return (
    <>
      <Container className="mt-4">
        <BackButton onClick={handleBackClick} className="mb-4" />
      </Container>

      <HeaderSection
        {...personalAccidentQuote}
        planName={personalAccidentPlanName}
        buyLinkTo={routePersonalAccidentQuoteBuyNewPage({ ...params, personalAccidentPlanId })}
        sendEmailLinkTo={routePersonalAccidentQuoteSendEmailPage({ ...params, personalAccidentPlanId })}
      />
      <FeatureSection {...personalAccidentQuote} />
      <BenefitSection {...personalAccidentQuote} />
      <ExclusionSection {...personalAccidentQuote} />
      <DocumentSection {...personalAccidentQuote} />
      <HospitalSection {...personalAccidentQuote} />
      <MemberSection {...params} pathname='/personal-accident-insurance-quote/members' />

      <SupportSection />
    </>
  );
};

export { PersonalAccidentQuoteDetailsPage };
