import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { CarPlanTypes } from '@models/CarPlan';
import { selectFeatureCar } from '@components/Feature/Car';

import { useFilterContext } from '../../FilterProvider';
import { InsuredDeclaredValueModal } from './insured-declared-value-modal';

const InsuredDeclaredValue = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { car } = useSelector(selectFeatureCar);

  const {
    filters: { planType, idvAmount },
    updateFilters,
  } = useFilterContext();
  const isThirdPartyPlan = planType === CarPlanTypes.ThirdParty;
  const shouldShowCustomIdvModal = (car?.unknownIdv || !car?.carPrices.length) && !isThirdPartyPlan && idvAmount === 0;

  const handleConfirm = (value: number) => {
    setShow(false);
    updateFilters({ idvAmount: value });
  };

  return (
    <>
      <div className="py-2">
        <div className="py-1">
          <span className="me-2 pe-1 fw-bold">{t('carInsuranceQuote.insuredDeclaredValue.title')}</span>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body>{t('carInsuranceQuote.insuredDeclaredValue.description')}</Popover.Body>
              </Popover>
            }
          >
            <FontAwesomeIcon icon={faCircleInfo} size="sm" />
          </OverlayTrigger>
        </div>
        <Button onClick={() => setShow(true)} disabled={isThirdPartyPlan} variant="light" className="w-100 d-flex justify-content-between align-items-center">
          <span>
            {isThirdPartyPlan
              ? t('carInsuranceQuote.insuredDeclaredValue.selectIdvNotApplicable')
              : t('carInsuranceQuote.insuredDeclaredValue.selectIdv')}
          </span>
          &nbsp;
          <FontAwesomeIcon icon={faAngleDown} size="sm" />
        </Button>
      </div>

      {shouldShowCustomIdvModal && (
        <InsuredDeclaredValueModal
          defaultIdvAmount={idvAmount}
          show={true}
          onHide={() => setShow(false)}
          onConfirm={handleConfirm}
        />
      )}

      {car && (
        <InsuredDeclaredValueModal
          defaultIdvAmount={idvAmount}
          show={show}
          onHide={() => setShow(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export { InsuredDeclaredValue };
