import React from 'react';
import { Button } from 'react-bootstrap';
import { useCopyToClipboard } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const CopyLinkButton = () => {
  const { t } = useTranslation();
  const [copiedText, copy] = useCopyToClipboard();

  /* eslint-disable no-console */
  const handleCopy = () => () => {
    copy(window.location.href)
      .then(() => console.log('Copied link!'))
      .catch((error) => console.error('Failed to copy!', error));
  };
  /* eslint-enable no-console */

  return (
    <div className="d-flex align-items-center">
      {copiedText && <small className="d-inline-block me-2">{t('common:copied')}!</small>}
      <Button variant="outline-primary" size="sm" onClick={handleCopy()}>
        <FontAwesomeIcon icon={faCopy} className="me-2" />
        {startCase(t('common:copyLink'))}
      </Button>
    </div>
  );
};

export { CopyLinkButton };
