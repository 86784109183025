import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined, startCase } from 'lodash';
import { Container, Row, Col, Card, Button, Alert, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMoneyCheckDollar, faPencil } from '@fortawesome/free-solid-svg-icons';
import { CopyLinkButton, Loading, LoadingError } from '@components/Base';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';
import { OrderInvoiceLink } from '@components/Feature/Order';
import { OrderStatuses } from '@models/Order';
import { QuoteBaseInfoCardBody, QuoteDocumentCardBody, QuoteExclusionCardBody, QuotePremiumChangeAlertCardBody } from '@components/Feature/Quote';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';
import { MemberCardBody } from '@components/HealthQuoteBuyRoute/HealthQuoteReviewPage/member-card-body';

import { personalAccidentOrderPay, PersonalAccidentOrderPayPayload } from '../action';
import { routePersonalAccidentQuoteBuyCustomPage, routePersonalAccidentQuoteBuyEditPage } from '../route';
import { BenefitCardBody } from './benefit-card-body';
import { usePersonalAccidentQuoteReview } from './hook';
import { FeatureCardBody } from './feature-card-body';


const PersonalAccidentQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { personalAccidentQuote, values } = usePersonalAccidentQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);

  if (isUndefined(personalAccidentQuote)) return <Loading />;
  if (isNull(personalAccidentQuote)) return <LoadingError />;

  const {
    status,
    activationDate,
    personalAccidentOrderId,
    orderEncryptedId,
    isValidMedicalQuestionnaire,
    people
  } = personalAccidentQuote;
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');
  const shouldEdit = isPending && isValidActivationDate;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldCustomPremium = isFrontline && isPending;

  const handlePay = () => {
    const payload: PersonalAccidentOrderPayPayload = { id: personalAccidentOrderId };
    dispatch(personalAccidentOrderPay(payload));
  };

  return (
    <Container className="my-5">
      <Row>
        <Col lg={8}>
          <Card>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center">
              {t('common:summary')}
              <CopyLinkButton />
            </Card.Header>

            <QuoteBaseInfoCardBody {...personalAccidentQuote} />
            <QuotePremiumChangeAlertCardBody {...personalAccidentQuote} />
            <FeatureCardBody {...personalAccidentQuote} />
            <BenefitCardBody {...personalAccidentQuote} />
            <QuoteExclusionCardBody {...personalAccidentQuote} />
            <QuoteDocumentCardBody {...personalAccidentQuote} />
            <MemberCardBody {...people} />
          </Card>
        </Col>
        <RightCol {...personalAccidentQuote} planName={personalAccidentQuote.personalAccidentPlanName}>
          <Card.Body>
            {!isValidMedicalQuestionnaire && (
              <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
            )}

            {isFrontline && (
              <div className="text-end">
                <OrderInvoiceLink orderEncryptedId={orderEncryptedId} />
              </div>
            )}
            <Stack direction="vertical" gap={2}>
              {shouldEdit && (
                <Button as={Link} to={routePersonalAccidentQuoteBuyEditPage({ personalAccidentOrderId, ...values })} variant="outline-primary">
                  <FontAwesomeIcon icon={faPencil} className="me-2" />
                  {t('common:edit')}
                </Button>
              )}

              {shouldCustomPremium && (
                <Button as={Link} to={routePersonalAccidentQuoteBuyCustomPage({ personalAccidentOrderId })} variant="secondary">
                  <FontAwesomeIcon icon={faMoneyCheckDollar} className="me-2" />
                  {t('common:custom')}
                </Button>
              )}

              {shouldPay && (
                <Button disabled={!personalAccidentQuote.isValidMedicalQuestionnaire} onClick={handlePay}>
                  <span className="me-2">{startCase(t('common:payNow'))}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}
            </Stack>
          </Card.Body>
        </RightCol>
      </Row>
    </Container>
  );
};

export { PersonalAccidentQuoteReviewPage };
