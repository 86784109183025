import { call, put, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';
import { PersonalAccidentOrderApi } from '@apis/PersonalAccidentOrderApi';
import { SagaPayload } from '@utils/typescript';

import {
  personalAccidentOrderFetch,
  PersonalAccidentOrderFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handlePersonalAccidentOrderFetch({ payload }: SagaPayload<PersonalAccidentOrderFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentOrder: null };
  try {
    state.personalAccidentOrder = (yield call(PersonalAccidentOrderApi.get, payload)) as PersonalAccidentOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(personalAccidentOrderFetch, handlePersonalAccidentOrderFetch);
}
