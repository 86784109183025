import { createActions } from 'redux-actions';
import { Car } from '@models/Car';
import { GetIdvCarWithCarIdParams } from '@apis/CarApi';

export type State = {
  car?: Car | null;
};

export type CarFetchPayload = GetIdvCarWithCarIdParams;
export type StateUpdatePayload = Partial<State>;

export const {
  carFetch,
  stateUpdate,
} = createActions(
  'CAR_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'FEATURE_CAR',
  },
);
