import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteParam } from '@hooks/params';

import {
  billingFetch,
  BillingFetchPayload,
  carOrderFetch,
  CarOrderFetchPayload,
} from './action';
import { selectCarOrderPayment } from './selector';

export const useCarOrderFetch = () => {
  const dispatch = useDispatch();
  const carOrderId = Number(useRouteParam('carOrderId'));
  const { carOrder } = useSelector(selectCarOrderPayment);

  useEffect(() => {
    const payload: CarOrderFetchPayload = { id: carOrderId };
    dispatch(carOrderFetch(payload));
  }, []);

  return carOrder;
}

export const useBillingFetch = () => {
  const dispatch = useDispatch();
  const billingId = Number(useRouteParam('billingId'));
  const { billing } = useSelector(selectCarOrderPayment);

  useEffect(() => {
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  }, []);

  return billing;
}
