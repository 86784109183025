import { createActions } from 'redux-actions';

import { Company } from '@models/Company';

export type State = {
  companies: Company[];
};

export type StateUpdatePayload = Partial<State>;

export const {
  companiesFetch,
  stateUpdate,
} = createActions(
  'COMPANIES_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'FEATURE_COMPANY',
  },
);
