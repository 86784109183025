import React, { useState, useRef } from 'react';
import { Button, Popover, Alert, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { tCarPlanType, CarPlanTypes } from '@models/CarPlan';

import style from './style.module.scss';
import { PlanTypeModal } from './plan-type-modal';
import { useFilterContext } from '../../FilterProvider';

const PlanTypeValue = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const { filters, updateFilters } = useFilterContext();

  const handleConfirm = (planType: CarPlanTypes) => {
    const idvAmount = planType === CarPlanTypes.ThirdParty ? 0 : filters.idvAmount;
    setShow(false);
    updateFilters({ planType, idvAmount });
  };

  return (
    <>
      <div className="py-2" ref={ref}>
        <div className="py-1">
          <span className="me-2 pe-1 fw-bold">{t('common:planType')}</span>
          <OverlayTrigger
            container={ref}
            placement="bottom"
            overlay={
              <Popover className={classNames(style.popoverLargeContent)}>
                <Popover.Body>
                  <section>
                    <span className="d-block fs-6 fw-bold mb-3">
                      {t('carInsuranceQuote.planType.comprehensive.name')}
                    </span>
                    <p>{t('carInsuranceQuote.planType.comprehensive.description')}</p>
                    <Alert variant="secondary">
                      <ul>
                        <li>{t('carInsuranceQuote.planType.comprehensive.coverage1')}</li>
                        <li>{t('carInsuranceQuote.planType.comprehensive.coverage2')}</li>
                        <li>{t('carInsuranceQuote.planType.comprehensive.coverage3')}</li>
                      </ul>
                    </Alert>
                  </section>
                  <section>
                    <span className="d-block fs-6 fw-bold mb-3">{t('carInsuranceQuote.planType.thirdParty.name')}</span>
                    <p>{t('carInsuranceQuote.planType.thirdParty.description')}</p>
                    <Alert variant="secondary">
                      <ul>
                        <li>{t('carInsuranceQuote.planType.thirdParty.coverage1')}</li>
                        <li>{t('carInsuranceQuote.planType.thirdParty.coverage2')}</li>
                        <li>{t('carInsuranceQuote.planType.thirdParty.coverage3')}</li>
                      </ul>
                    </Alert>
                    <p>
                      <span className="d-block fw-bold">{t('carInsuranceQuote.planType.thirdParty.question')}</span>
                      <span>{t('carInsuranceQuote.planType.thirdParty.answer')}</span>
                    </p>
                  </section>
                </Popover.Body>
              </Popover>
            }
          >
            <FontAwesomeIcon icon={faCircleInfo} size="sm" />
          </OverlayTrigger>
        </div>
        <Button onClick={() => setShow(true)} variant="light" className="w-100 d-flex justify-content-between align-items-center">
          <span className="me-2">{tCarPlanType(filters.planType)}</span>
          <FontAwesomeIcon icon={faAngleDown} size="sm" />
        </Button>
      </div>

      <PlanTypeModal show={show} onHide={() => setShow(false)} onConfirm={handleConfirm} value={filters.planType} />
    </>
  );
};

export { PlanTypeValue };
