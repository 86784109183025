import { useContext } from 'react';
import { isUndefined } from 'lodash';

import { useParseParams } from '@hooks/params';
import { FilterContext } from './context';
import { FilterType } from './type';
import { healthQuotesFilterParamsSchema } from '../schema';

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (isUndefined(context)) throw new Error('useFilterContext must be used within a FilterProvider');
  return context;
};

export const useFilterParams = () => useParseParams<FilterType>(healthQuotesFilterParamsSchema);
