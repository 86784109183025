import { all, fork } from 'redux-saga/effects';
import { insurerSaga } from './Insurer';
import { provinceSaga } from './Province';
import { orderTemplateSaga } from './OrderTemplate';
import { companySaga } from './Company';

export default function* () {
  yield all([
    fork(insurerSaga),
    fork(provinceSaga),
    fork(orderTemplateSaga),
    fork(companySaga),
  ]);
}
