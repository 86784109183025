import { call, put, takeLatest } from 'redux-saga/effects';

import { Company } from '@models/Company';
import { CompanyApi } from '@apis/CompanyApi';

import {
  companiesFetch,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCompaniesFetch() {
  const state: StateUpdatePayload = { companies: [] };
  try {
    state.companies = (yield call(CompanyApi.getAll)) as Company[];
    yield put(stateUpdate(state));
  } catch { }
}

export default function* () {
  yield takeLatest(companiesFetch, handleCompaniesFetch);
}
