import React from 'react';
import { Alert, Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLock, faMoneyCheckDollar, faPencil, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';
import { Loading, CopyLinkButton, LoadingError } from '@components/Base';
import {
  CarQuoteCoverCardBody,
  CarQuoteDeductibleCardBody,
  CarQuoteEndorsementCardBody,
} from '@components/Feature/CarQuote';
import { OrderStatuses } from '@models/Order';
import { OrderInvoiceLink } from '@components/Feature/Order';
import { QuoteBaseInfoCardBody, QuoteDocumentCardBody, QuoteExclusionCardBody } from '@components/Feature/Quote';

import { useCarQuoteFetch } from '../hook';
import { carOrderPay, CarOrderPayPayload } from '../action';
import {
  routeCarQuoteBuyEditPage,
  routeCarQuoteBuyResetPage,
  routeCarQuoteBuyCustomPage,
  routeCarQuoteBuyLockPage,
  routeCarQuoteBuyPayByCashPage,
} from '../route';
import { RightCol } from '../right-col';
import { OwnerCardBody } from './owner-card-body';
import { VehicleCardBody } from './vehicle-card-body';

const CarQuoteBuyReviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carQuote = useCarQuoteFetch();
  const isCustomer = useSelector(selectIsCustomer);
  const isFrontline = useSelector(selectIsFrontline);

  if (isUndefined(carQuote)) return <Loading />;
  if (isNull(carQuote)) return <LoadingError />;

  const { user, userCar, status, policyNumber, car, orderEncryptedId, carOrderId } = carQuote;
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const shouldEdit = isPending && !policyNumber;
  const shouldLock = isFrontline && isPending;
  const shouldReset = isFrontline && isPending;
  const shouldCustomPremium = isFrontline && isPending;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldPayByCash = isLocked && isFrontline;

  const handlePay = () => {
    const payload: CarOrderPayPayload = { id: carOrderId };
    dispatch(carOrderPay(payload));
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Card>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center">
              {t('common:summary')}
              <CopyLinkButton />
            </Card.Header>
            <VehicleCardBody userCar={userCar} />
            <QuoteBaseInfoCardBody {...carQuote} />
            <OwnerCardBody {...user} />
            <CarQuoteCoverCardBody {...carQuote} />
            <CarQuoteDeductibleCardBody {...carQuote} />
            <QuoteExclusionCardBody {...carQuote} />
            <QuoteDocumentCardBody {...carQuote} />
            <CarQuoteEndorsementCardBody {...carQuote} />
          </Card>
        </Col>

        <RightCol carQuote={carQuote}>
          <Card.Body>
            {car?.unknownIdv && (
              <Alert variant="warning" className="mb-3">
                {t('carQuoteBuyPage.carQuoteReview.requiredVerify')}
              </Alert>
            )}

            {isFrontline && (
              <div className="text-end">
                <OrderInvoiceLink orderEncryptedId={orderEncryptedId} />
              </div>
            )}

            <Stack direction="vertical" gap={2}>
              {shouldEdit && (
                <Button as={Link} to={routeCarQuoteBuyEditPage({ carOrderId })} variant="outline-primary">
                  <FontAwesomeIcon icon={faPencil} className="me-2" />
                  {t('common:edit')}
                </Button>
              )}

              {shouldPay && (
                <Button onClick={handlePay} disabled={car?.unknownIdv}>
                  <span className="me-2">{startCase(t('common:payNow'))}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}

              {shouldReset && (
                <Button as={Link} to={routeCarQuoteBuyResetPage({ carOrderId })} variant="danger">
                  <FontAwesomeIcon icon={faRepeat} className="me-2" />
                  {t('common:reset')}
                </Button>
              )}

              {shouldCustomPremium && (
                <Button as={Link} to={routeCarQuoteBuyCustomPage({ carOrderId })} variant="secondary">
                  <FontAwesomeIcon icon={faMoneyCheckDollar} className="me-2" />
                  {t('common:custom')}
                </Button>
              )}

              {shouldLock && (
                <Button as={Link} to={routeCarQuoteBuyLockPage({ carOrderId })} variant="warning">
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  {t('common:confirm')}
                </Button>
              )}

              {shouldPayByCash && (
                <Button as={Link} to={routeCarQuoteBuyPayByCashPage({ carOrderId })} variant="primary">
                  <span className="me-2">{startCase(t('common:payByCash'))}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}
            </Stack>
          </Card.Body>
        </RightCol>
      </Row >
    </Container >
  );
};

export { CarQuoteBuyReviewPage };
