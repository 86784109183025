import { pick } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { useParseParams } from '@hooks/params';
import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useCancerQuoteFind } from '@components/CancerQuoteDetailsRoute';
import { appendCancerPlanQuestionsToFormValues } from '@utils/cancer-quote';

import { formValuesParamsSchema, FormValues, meFormValuesParamsSchema } from '../CancerQuoteBuyForm';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const cancerQuote = useCancerQuoteFind();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!currentUser) return;
    appendCancerPlanQuestionsToFormValues(initialValues, cancerQuote?.cancerPlanQuestions!);
    return mergeInitialValues(initialValues, {
      isConfirmedDeclaration: false,
      me: isCustomer ? pick(currentUser, getFieldKeys(meFormValuesParamsSchema)) : undefined,
    });
  }, [currentUser, cancerQuote]);
};
