import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { BackButton, FormGroupControl } from '@components/Base';
import { formatDate } from '@utils/datetime';

import { Insured, HealthInsurancePlan, ClaimInfo, ClaimPaymentMethod } from './Forms';
import { FormValues } from './type';

const INITIAL_VALUES: FormValues = {
  insuredPolicyHolder: 'Vann Sen',
  policyNumber: 'HFT1010010',
  orderNumber: 'H002APVI001000/23',
  insuredPolicyExpiryDate: formatDate('2024-10-18T14:21:05.176+07:00'),
};

const HealthPolicyClaim = () => {
  const { t } = useTranslation();

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(false);
  };

  return (
    <>
      <BackButton className="mb-4" />
      <Card>
        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          <Form>
            <Card.Header className="fs-5 fw-bold">Health Claim Form</Card.Header>

            <Insured />
            <HealthInsurancePlan />
            <ClaimInfo />
            <ClaimPaymentMethod />

            <Card.Body>
              <FormGroupControl
                type="checkbox"
                name="acceptTermsAndConditions"
                label={t('carInsuranceClaimForm.acceptTermsAndConditions')}
                bsPrefix="form-check-input"
                groupProps={{ className: 'form-check form-check-inline' }}
              />
            </Card.Body>

            <Card.Footer className="bg-white">
              <div className="text-center my-4">
                <Button type="submit" variant="primary" size="lg">
                  {t('common:submit')}
                </Button>
              </div>
            </Card.Footer>
          </Form>
        </Formik>
      </Card>
    </>
  );
};

export { HealthPolicyClaim };
