import React, { useMemo } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import kiripost from './kiripost.png';
import { Title } from '../title';

const FeaturedInSection = () => {
  const { t } = useTranslation();
  const publishers = useMemo(
    () => [
      {
        name: 'Kiripost',
        logo: kiripost as string,
        articleUrl: 'https://kiripost.com/stories/cambodia-mga-asia-insurance-broker',
      },
    ],
    [],
  );

  return (
    <section className="py-5">
      <Title title={startCase(t('featuredIn', { ns: 'common' }))} />
      <Container>
        <Row className="gy-4 justify-content-center align-items-center">
          {map(publishers, ({ name, logo, articleUrl }) => (
            <Col key={name} sm={6} md={4} lg={3} className="text-center">
              <Link to={articleUrl} target="_blank" rel="nofollow noreferrer">
                <Image src={logo} alt={`${name} official logo`} fluid />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { FeaturedInSection };
