import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CarQuote } from '@models/CarQuote';
import { RowItem, RowItemProps } from '@components/Base';

const ROW_ITEM_PROPS: RowItemProps = {
  className: 'mb-2',
};

type Props = Pick<CarQuote, 'userCar'>;

const VehicleCardBody = ({ userCar }: Props) => {
  const { t } = useTranslation();
  const { manufacturedYear, engineNumber, chassisNumber, registrationNumber, registrationPlace, name } = userCar;

  return (
    <Card.Body>
      <Card.Title>{t('carQuoteBuyPage.carQuoteReview.vehicleTitle')}</Card.Title>
      <div className="mb-2"><b>{name}</b></div>
      <div className="mb-2">{t('common:privateCar')} . {manufacturedYear}</div>
      <RowItem {...ROW_ITEM_PROPS} label={t('car.engineNumber')} value={engineNumber} />
      <RowItem {...ROW_ITEM_PROPS} label={t('car.chassisNumber')} value={chassisNumber} />
      <RowItem {...ROW_ITEM_PROPS} label={t('car.registrationPlace')} value={registrationPlace} />
      <RowItem {...ROW_ITEM_PROPS} label={t('car.registrationNumber')} value={registrationNumber} />
    </Card.Body>
  );
};

export { VehicleCardBody };
