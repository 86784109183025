import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined, map } from 'lodash';

import { FormGroupControl, Loading, LoadingError } from '@components/Base';
import { tOrdinalItem } from '@components/i18n';

import { useCancerQuoteFetch } from '../hook';
import { cancerQuoteCustom, CancerQuoteCustomPayload } from '../action';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';
import { RightCol } from '@components/HealthQuoteBuyRoute/right-col';

const GROUP_PROPS = { className: 'mb-3' };

const CancerQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancerQuote = useCancerQuoteFetch();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (isUndefined(cancerQuote)) return <Loading />;
  if (isNull(cancerQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { cancerOrderId } = cancerQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CancerQuoteCustomPayload = { ...values, cancerOrderId, };
    dispatch(cancerQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values }) => (
              <Form>
                <Card>
                  <Card.Body>
                    <FormGroupControl
                      required
                      name="me.chargeAmount"
                      label={t('common:myself')}
                      type="number"
                      groupProps={GROUP_PROPS}
                    />

                    {!!values.spouse && (
                      <FormGroupControl
                        required
                        name="spouse.chargeAmount"
                        label={t('common:spouse')}
                        type="number"
                        groupProps={GROUP_PROPS}
                      />
                    )}

                    {!!values.sons &&
                      map(values.sons, (_, idx: number) => (
                        <FormGroupControl
                          required
                          key={idx}
                          name={`sons.${idx}.chargeAmount`}
                          label={tOrdinalItem(idx + 1, 'common:son')}
                          type="number"
                          groupProps={GROUP_PROPS}
                        />
                      ))}

                    {!!values.daughters &&
                      map(values.daughters, (_, idx: number) => (
                        <FormGroupControl
                          required
                          key={idx}
                          name={`daughters.${idx}.chargeAmount`}
                          label={tOrdinalItem(idx + 1, 'common:daughter')}
                          type="number"
                          groupProps={GROUP_PROPS}
                        />
                      ))}

                    <FormGroupControl name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />
                    <FormGroupControl required name="note" label="Note" groupProps={GROUP_PROPS} as="textarea" rows={4} />
                  </Card.Body>

                  <Card.Footer className="bg-white border-0 mx-auto my-4">
                    <Stack direction="horizontal" gap={3}>
                      <Button onClick={() => navigate(-1)} variant="outline-primary" size="lg">
                        {t('common:cancel')}
                      </Button>

                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        {t('common:update')}
                      </Button>
                    </Stack>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Col>

        <RightCol {...cancerQuote} planName={cancerQuote.cancerPlanName} />
      </Row>
    </Container>
  );
};

export { CancerQuoteBuyCustomPage };
