import humps from 'lodash-humps-ts';
import { omitBy, isArray } from 'lodash';
import { stringify } from 'qs';

import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { User } from '@models/User';
import { PersonalAccidentQuote, PersonalAccidentQuoteMember } from '@models/PersonalAccidentQuote';
import { CustomQuoteParams, serializeCustomFormData } from '@utils/serialize-custom-form-data';

export type Questionnaire = {
  question: string;
  answer: 'yes' | 'no';
  remark: string;
};

type MePersonalDetails = Omit<User, 'id' | 'fullName' | 'fullNameKm'> & {
  dob: string;
  medicalQuestionnaire: Questionnaire[];
};

type MemberPersonalDetails = Omit<MePersonalDetails, 'gender' | 'email' | 'mobileNumber' | 'address'>;

export type PersonalAccidentQuotePeopleParams = {
  me: MePersonalDetails;
  spouse?: MemberPersonalDetails;
  sons?: MemberPersonalDetails[];
  daughters?: MemberPersonalDetails[];
};

export type GetAllPersonalAccidentQuoteParams = PersonalAccidentQuoteMember & {
  PersonalAccidentPlanIds?: number[];
};

export type FindPersonalAccidentQuoteParams = PersonalAccidentQuoteMember & {
  personalAccidentPlanId: number;
};

export type CreatePersonalAccidentQuoteParams = Pick<FindPersonalAccidentQuoteParams, 'personalAccidentPlanId'> &
  PersonalAccidentQuotePeopleParams;

export type GetPersonalAccidentQuoteParams = {
  personalAccidentOrderId: number;
};

export type UpdatePersonalAccidentQuoteParams = GetPersonalAccidentQuoteParams & PersonalAccidentQuotePeopleParams;

export type CustomPersonalAccidentQuoteParams = GetPersonalAccidentQuoteParams & CustomQuoteParams;

export type SendEmailPersonalAccidentQuoteParams = FindPersonalAccidentQuoteParams & {
  firstName: string;
  lastName: string;
  email: string;
};

export class PersonalAccidentQuoteApi {
  static getAll = async (params: GetAllPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/personal_accident_quotes`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote[]);
  };

  static find = async (params: FindPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/personal_accident_quotes/find`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static create = async (params: CreatePersonalAccidentQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/personal_accident_quotes`, {
        ...omitBy(snakes(params), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static get = async ({ personalAccidentOrderId }: GetPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/personal_accident_quotes/${personalAccidentOrderId}`)
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static update = async ({ personalAccidentOrderId, ...rest }: UpdatePersonalAccidentQuoteParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/user/personal_accident_quotes/${personalAccidentOrderId}`, {
        ...omitBy(snakes(rest), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static sendEmail = async (params: SendEmailPersonalAccidentQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/personal_accident_quotes/send_email`, snakes(params));
  };

  static custom = async ({ personalAccidentOrderId, ...rest }: CustomPersonalAccidentQuoteParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/personal_accident_quotes/${personalAccidentOrderId}/custom`, serializeCustomFormData(rest))
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };
}
