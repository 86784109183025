import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { CarOrder } from '@models/CarOrder';
import { API_V1_BASE } from '@config';

export type GetCarOrderParams = {
  id: number;
};

export type PayCarOrderParams = GetCarOrderParams;
export type RenewalPreviewCarOrderParams = GetCarOrderParams;
export type RenewCarOrderParams = GetCarOrderParams;

export class CarOrderApi {
  static get = async ({ id }: GetCarOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_orders/${id}`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static pay = async ({ id }: PayCarOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_orders/${id}/pay`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static renewalPreview = async ({ id }: RenewalPreviewCarOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_orders/${id}/renewal_preview`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static renew = async ({ id }: RenewCarOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_orders/${id}/renew`)
      .then((response) => humps(response.data) as CarOrder);
  };
}
