import { call, put, takeLatest } from 'redux-saga/effects';
import { find } from 'lodash';

import { CarBrand } from '@models/CarBrand';
import { CarModel } from '@models/CarModel';
import { CarSubModel } from '@models/CarSubModel';
import { composeCarVariants } from '@models/CarVariant';
import { CarBrandApi } from '@apis/CarBrandApi';
import { CarModelApi } from '@apis/CarModelApi';
import { CarSubModelApi } from '@apis/CarSubModelApi';
import { CarApi, GetIdvCarWithoutCarIdParams } from '@apis/CarApi';
import { Car } from '@models/Car';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { routeCarQuoteResultPage } from '@components/CarQuoteResultPage';
import { userLeadCreate, UserLeadCreatePayload } from '@components/UserLead';

import {
  carBrandFetch,
  carBrandsFetch,
  carFetch,
  carModelFetch,
  carModelsFetch,
  carModelsSet,
  carVariantFetch,
  carVariantsFetch,
  carVariantsSet,
  stateUpdate,
} from './action';
import {
  CarBrandFetchPayload,
  CarFetchPayload,
  CarModelFetchPayload,
  CarModelsFetchPayload,
  CarModelsSetPayload,
  CarVariantFetchPayload,
  CarVariantsFetchPayload,
  CarVariantsSetPayload,
  StateUpdatePayload,
} from './type';

function* handleCarFetch({ payload }: SagaPayload<CarFetchPayload>) {
  const { mobileNumber, fullName, ...restParams } = payload;
  const getIdvParams: GetIdvCarWithoutCarIdParams = restParams;
  try {
    const car = (yield call(CarApi.getIdv, getIdvParams)) as Car;

    if (mobileNumber && fullName) {
      const userLeadCreatePayload: UserLeadCreatePayload = {
        mobileNumber,
        fullName,
        message: `Car: ${car.name}, ${car.manufacturedYear}`,
      };

      yield put(userLeadCreate(userLeadCreatePayload));
    }

    history.navigate(routeCarQuoteResultPage({ carId: car.id, manufacturedYear: car.manufacturedYear }));
  } catch {
    history.navigate('/car-insurance-quote/car-brand', { replace: true });
  }
}

function* handleCarBrandFetch({ payload }: SagaPayload<CarBrandFetchPayload>) {
  const state: StateUpdatePayload = { carBrand: null };
  try {
    state.carBrand = (yield call(CarBrandApi.get, payload)) as CarBrand;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarBrandsFetch() {
  const state: StateUpdatePayload = { carBrands: null };
  try {
    state.carBrands = (yield call(CarBrandApi.getAll)) as CarBrand[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarModelFetch({ payload }: SagaPayload<CarModelFetchPayload>) {
  const state: StateUpdatePayload = { carModel: null };
  try {
    state.carModel = (yield call(CarModelApi.get, payload)) as CarModel;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarModelsFetch({ payload }: SagaPayload<CarModelsFetchPayload>) {
  const carModelsSetPayload: CarModelsSetPayload = { [payload.carBrandId]: null };
  try {
    const carModels = (yield call(CarModelApi.getAll, payload)) as CarModel[];
    carModelsSetPayload[payload.carBrandId] = carModels;
    yield put(carModelsSet(carModelsSetPayload));
  } catch {
    yield put(carModelsSet(carModelsSetPayload));
  }
}

function* handleCarVariantFetch({ payload }: SagaPayload<CarVariantFetchPayload>) {
  const state: StateUpdatePayload = { carVariant: null };
  try {
    const { carSubModelId, engineCapacity } = payload;
    const carSubModel = (yield call(CarSubModelApi.get, { id: carSubModelId })) as CarSubModel;
    const carVariants = composeCarVariants([carSubModel]);
    state.carVariant = find(carVariants, { carSubModelId, engineCapacity }) || null;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarVariantsFetch({ payload }: SagaPayload<CarVariantsFetchPayload>) {
  const carVariantsSetPayload: CarVariantsSetPayload = { [payload.carModelId]: null };
  try {
    const carSubModels = (yield call(CarSubModelApi.getAll, payload)) as CarSubModel[];
    carVariantsSetPayload[payload.carModelId] = composeCarVariants(carSubModels);
    yield put(carVariantsSet(carVariantsSetPayload));
  } catch {
    yield put(carVariantsSet(carVariantsSetPayload));
  }
}

export default function* () {
  yield takeLatest(carFetch, handleCarFetch);
  yield takeLatest(carBrandFetch, handleCarBrandFetch);
  yield takeLatest(carBrandsFetch, handleCarBrandsFetch);
  yield takeLatest(carModelFetch, handleCarModelFetch);
  yield takeLatest(carModelsFetch, handleCarModelsFetch);
  yield takeLatest(carVariantFetch, handleCarVariantFetch);
  yield takeLatest(carVariantsFetch, handleCarVariantsFetch);
}
