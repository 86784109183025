import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { authAxios } from '@components/Auth';
import { GeneralOrder } from '@models/GeneralOrder';

export type GetGeneralOrderParams = {
  id: number;
};

export type PayGeneralOrderParams = GetGeneralOrderParams;

export class GeneralOrderApi {
  static get = async ({ id }: GetGeneralOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/general_orders/${id}`)
      .then((response) => humps(response.data) as GeneralOrder);
  };

  static pay = async ({ id }: PayGeneralOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/general_orders/${id}/pay`)
      .then((response) => humps(response.data) as GeneralOrder);
  };
}
