import React, { Fragment } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Link } from 'react-router-dom';

import { Orderable } from '@models/Order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

type Props = Pick<Orderable,
  | 'policySchedule'
  | 'coverageSummary'
  | 'coverageSummaryKm'
  | 'insuranceCertificate'
  | 'insuranceCertificateKm'
  | 'invoice'
  | 'invoiceKm'
  | 'policyWordingFile'
  | 'policyWordingFileKm'
  | 'claimProcedureFile'
  | 'claimProcedureFileKm'
>;


const OrderDocumentCardBody = ({
  policySchedule,
  coverageSummary,
  coverageSummaryKm,
  insuranceCertificate,
  insuranceCertificateKm,
  invoice,
  invoiceKm,
  claimProcedureFile,
  claimProcedureFileKm,
}: Props) => {
  const { t } = useTranslation();
  const items: [string | undefined, string | undefined, string][] = [
    [policySchedule, undefined, 'policySchedule'],
    [coverageSummary, coverageSummaryKm, 'coverageSummary'],
    [insuranceCertificate, insuranceCertificateKm, 'certificateOfInsurance'],
    [invoice, invoiceKm, 'invoice'],
    [claimProcedureFile, claimProcedureFileKm, 'claimProcedure'],
  ];

  return (
    <Card.Body>
      <ListGroup variant="flush">
        {map(items, ([en, km, label], idx) => (
          <Fragment key={idx}>
            {(en || km) ? (
              <ListGroup.Item>
                {en && (
                  <Link to={en} target="_blank" rel="noreferrer" className="me-5">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    {t(`policyDetails.${label}InEn`)}
                  </Link>
                )}

                {km && (
                  <Link to={km} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    {t(`policyDetails.${label}InKm`)}
                  </Link>
                )}
              </ListGroup.Item>
            ) : null}
          </Fragment>
        ))}
      </ListGroup>
    </Card.Body>
  );
};

export { OrderDocumentCardBody };
