import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull, startCase } from 'lodash';
import { Container, Row, Col, Card, Button, Alert, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMoneyCheckDollar, faPencil } from '@fortawesome/free-solid-svg-icons';
import { CopyLinkButton, Loading, LoadingError } from '@components/Base';
import { selectIsFrontline, selectIsCustomer } from '@components/Auth';
import { OrderStatuses } from '@models/Order';
import { OrderInvoiceLink } from '@components/Feature/Order';
import { QuoteBaseInfoCardBody, QuotePremiumChangeAlertCardBody, QuoteExclusionCardBody, QuoteDocumentCardBody } from '@components/Feature/Quote';
import { HealthQuoteBenefitScheduleCardBody } from '@components/Feature/HealthQuote';

import { healthOrderPay, HealthOrderPayPayload } from '../action';
import { routeHealthQuoteBuyCustomPage, routeHealthQuoteBuyEditPage } from '../route';
import { RightCol } from '../right-col';
import { MemberCardBody } from './member-card-body';
import { useHealthQuoteReview } from './hook';
import { FeatureCardBody } from './feature-card-body';

const HealthQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { healthQuote, values } = useHealthQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);

  if (isUndefined(healthQuote)) return <Loading />;
  if (isNull(healthQuote)) return <LoadingError />;

  const {
    healthOrderId,
    activationDate,
    status,
    isValidMedicalQuestionnaire,
    orderEncryptedId,
    people,
    healthPlanName,
  } = healthQuote;
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');
  const shouldEdit = isPending && isValidActivationDate;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldCustomPremium = isFrontline && isPending;

  const handlePay = () => {
    const payload: HealthOrderPayPayload = { id: healthOrderId };
    dispatch(healthOrderPay(payload));
  };

  return (
    <Container className="my-5">
      <Row>
        <Col lg={8}>
          <Card>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center">
              {t('common:summary')}
              <CopyLinkButton />
            </Card.Header>
            <QuoteBaseInfoCardBody {...healthQuote} />
            <QuotePremiumChangeAlertCardBody {...healthQuote} />
            <FeatureCardBody {...healthQuote} />
            <HealthQuoteBenefitScheduleCardBody {...healthQuote} />
            <QuoteExclusionCardBody {...healthQuote} />
            <QuoteDocumentCardBody {...healthQuote} />
            <MemberCardBody {...people} />
          </Card>
        </Col>
        <RightCol {...healthQuote} planName={healthPlanName}>
          <Card.Body>
            {!isValidMedicalQuestionnaire && (
              <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
            )}

            {isFrontline && (
              <div className="text-end">
                <OrderInvoiceLink orderEncryptedId={orderEncryptedId} />
              </div>
            )}

            <Stack direction="vertical" gap={2}>
              {shouldEdit && (
                <Button as={Link} to={routeHealthQuoteBuyEditPage({ healthOrderId, ...values })} variant="outline-primary">
                  <FontAwesomeIcon icon={faPencil} className="me-2" />
                  {t('common:edit')}
                </Button>
              )}

              {shouldCustomPremium && (
                <Button as={Link} to={routeHealthQuoteBuyCustomPage({ healthOrderId })} variant="secondary">
                  <FontAwesomeIcon icon={faMoneyCheckDollar} className="me-2" />
                  {t('common:custom')}
                </Button>
              )}

              {shouldPay && (
                <Button onClick={handlePay} disabled={!isValidMedicalQuestionnaire}>
                  <span className="me-2">{startCase(t('common:payNow'))}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}
            </Stack>
          </Card.Body>
        </RightCol>
      </Row>
    </Container >
  );
};

export { HealthQuoteReviewPage };
