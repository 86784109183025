import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { isUndefined, isNull } from 'lodash';

import { Loading, LoadingError } from '@components/Base';

import { healthQuoteUpdate, HealthQuoteUpdatePayload } from '../action';
import { useHealthQuoteEdit } from '../hook';
import HealthQuoteBuyForm from '../HealthQuoteBuyForm';
import { FormValues } from '../HealthQuoteBuyForm/schema';
import { RightCol } from '../right-col';
import { useInitialValues } from './hook';

const HealthQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const healthQuote = useHealthQuoteEdit();
  const initialValues = useInitialValues();

  if (isUndefined(healthQuote)) return <Loading />;
  if (isNull(healthQuote)) return <LoadingError />;
  if (!initialValues) return null;

  const { healthOrderId } = healthQuote;

  const handleSubmit = (values: FormValues) => {
    // FIXME: type mismatch, need to filter out useless params
    const payload: HealthQuoteUpdatePayload = { healthOrderId, ...values };
    dispatch(healthQuoteUpdate(payload));
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          <HealthQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Col>
        <RightCol {...healthQuote} planName={healthQuote.healthPlanName} />
      </Row>
    </Container>
  );
};

export { HealthQuoteBuyEditPage };
