import { createActions } from 'redux-actions';
import { UpdateUserProfileAuthParams } from "@apis/AuthApi";
import { FormValues } from './type';
import { FormikErrors } from 'formik';

export type State = {
  errors: FormikErrors<FormValues>;
}

export type UserUpdatePayload = UpdateUserProfileAuthParams;
export type StateUpdatePayload = Partial<State>;

export const {
  userUpdate,
  stateUpdate,
} = createActions(
  'USER_UPDATE',
  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_INFO_EDIT',
  },
);
