import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase, find, map } from 'lodash';

import {
  CarPlanAddonCoverageTypes,
  CarPlanComprehensiveCoverageTypes,
  CarPlanCover,
  CarPlanThirdPartyCoverageTypes,
} from '@models/CarPlan';
import { CarPlanTypes, tCarPlanType } from '@models/CarPlan';

import { selectCarQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';
import { useCarQuotesComparePageParams } from './hook';

const TableCover = () => {
  const { t } = useTranslation();
  const { planType } = useCarQuotesComparePageParams();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  const selectCoverage = useMemo(
    () => (covers: CarPlanCover[], type: string) =>
      find(covers, ({ coverageType }) => coverageType === type),
    [carQuotes],
  );

  if (!carQuotes) return null;

  return (
    <>
      {planType === CarPlanTypes.Comprehensive && (
        <>
          <TableRowHeader
            header={t('carInsuranceQuote.coversOn', { planTypeName: tCarPlanType(CarPlanTypes.Comprehensive) })}
          />

          {map(CarPlanComprehensiveCoverageTypes, (type) => (
            <TableRowItem key={type} title={t(`carInsuranceQuote.${camelCase(type)}`)}>
              {map(carQuotes, ({ carPlanId, comprehensiveCovers }) => (
                <td key={carPlanId}>{selectCoverage(comprehensiveCovers, type)?.description}</td>
              ))}
            </TableRowItem>
          ))}
        </>
      )}

      <TableRowHeader
        header={t('carInsuranceQuote.coversOn', { planTypeName: tCarPlanType(CarPlanTypes.ThirdParty) })}
      />

      {map(CarPlanThirdPartyCoverageTypes, (type) => (
        <TableRowItem key={type} title={t(`carInsuranceQuote.${camelCase(type)}`)}>
          {map(carQuotes, ({ carPlanId, thirdPartyCovers }) => (
            <td key={carPlanId}>{selectCoverage(thirdPartyCovers, type)?.description}</td>
          ))}
        </TableRowItem>
      ))}

      <TableRowHeader header={t('carInsuranceQuote.addons')} />

      {map(CarPlanAddonCoverageTypes, (type) => (
        <TableRowItem key={type} title={t(`carInsuranceQuote.${camelCase(type)}.name`)}>
          {map(carQuotes, ({ carPlanId, addonCovers }) => (
            <td key={carPlanId}>{selectCoverage(addonCovers, type)?.description}</td>
          ))}
        </TableRowItem>
      ))}
    </>
  );
};

export { TableCover };
