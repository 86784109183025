import { useMemo } from 'react';
import { isUndefined, merge } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import { parse } from 'qs';
import { ValidationSchema } from '@utils/yup';

export const useParamsPage = () => {
  const [searchParams] = useSearchParams();
  return Number(searchParams.get('page') || 1);
};

export const useRouteParam = <T extends string = string>(key: string, isOptional = false) => {
  const params = useParams();
  const value = params[key as keyof typeof params];
  if (isOptional) return value as T | undefined;
  if (isUndefined(value)) throw new Error(`routeParam :${key} is not found`);
  return value! as T;
}

export const useParseSearchParams = <T>(defaultValues?: T): T => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    return merge(defaultValues, parse(searchParams.toString()));
  }, [searchParams, defaultValues]);
};

export const useParseParams = <T>(schema: ValidationSchema) => {
  const [searchParams] = useSearchParams();
  return useMemo<T>(() => {
    return schema.cast(parse(searchParams.toString()), { stripUnknown: true, assert: false }) as T;
  }, [searchParams, schema]);
};
