import { useMemo } from 'react';
import { object, date } from 'yup';
import { useTranslation } from 'react-i18next';

import { mergeInitialValues } from '@utils/formik';
import { GeneralQuote } from '@models/GeneralQuote';

import { FormValues } from './type';

export const useInitialValues = (generalQuote?: GeneralQuote | null) => {
  const initialValues: FormValues = {
    activationDate: '',
  };

  return useMemo(() => {
    if (!generalQuote) return;
    return mergeInitialValues(initialValues, generalQuote);
  }, [generalQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    activationDate: date().required(t('validation:required')),
  });
};
