import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/params';
import { findCarQuoteParamsSchema } from '@apis/CarQuoteApi';

import { selectCarQuoteDetailsRoute } from './selector';
import { carQuoteFind, CarQuoteFindPayload } from './action';
import { CarQuoteDetailsPageParams } from './route';

export const useCarQuoteDetailsPageParams = () =>
  useParseParams<CarQuoteDetailsPageParams>(findCarQuoteParamsSchema);

export const useCarQuoteFind = () => {
  const dispatch = useDispatch();
  const params = useCarQuoteDetailsPageParams();
  const { carQuote } = useSelector(selectCarQuoteDetailsRoute);

  useEffect(() => {
    const payload: CarQuoteFindPayload = params;
    dispatch(carQuoteFind(payload));
  }, []);

  return carQuote;
};
