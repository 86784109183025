import { createActions } from 'redux-actions';

export type State = {
  provinces: string[];
};

export type StateUpdatePayload = Partial<State>;

export const {
  provincesFetch,
  stateUpdate,
} = createActions(
  'PROVINCES_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'FEATURE_PROVINCE',
  },
);
