import { PeopleParams } from '@components/HealthQuoteResultPage/route';
import { getRoute } from '@utils/route';

export type PersonalAccidentQuoteResultPageParams = PeopleParams & {
  activationDate: string;
};

export const PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL = '/personal-accident-insurance-quote-results';

export const routePersonalAccidentQuoteResultPage = (searchParams: PersonalAccidentQuoteResultPageParams) => getRoute(PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL, { searchParams });
