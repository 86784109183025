import { mergeInitialValues } from '@utils/formik';
import { useMemo } from 'react';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Genders, UserProfile } from '@models/User';
import { FormValues } from './type';

export const useInitialValues = (user: UserProfile | null) => {
  const initialValues: FormValues = {
    firstName: '',
    firstNameKm: '',
    lastName: '',
    lastNameKm: '',
    address: '',
    dob: '',
    email: '',
    gender: Genders.Male,
    occupation: '',
    personalCode: '',
    height: '',
    weight: '',
    bmi: '',
  };

  return useMemo(() => {
    if (user) return mergeInitialValues(initialValues, user);
    return initialValues;
  }, [user]);
}

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    firstName: string().required(t('validation:required')),
    lastName: string().required(t('validation:required')),
    firstNameKm: string().optional(),
    lastNameKm: string().optional(),
    gender: string().required(t('validation:required')),
    dob: string().required(t('validation:required')),
    personalCode: string().optional(),
    occupation: string().optional(),
    email: string().email().optional(),
    address: string().optional(),
  })
}
