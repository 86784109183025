import { handleActions } from 'redux-actions';
import { State, stateUpdate } from './action';

const initialState: State = {
  companies: [],
};

export default handleActions(
  {
    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);
