import React from 'react';
import { map } from 'lodash';
import { OverlayTrigger, Popover, FormSelect } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { CarPlanCategories, tCarPlanCategory } from '@models/CarPlan';

import { useFilterContext } from '../FilterProvider';

const FilterCategory = () => {
  const { filters: { category }, updateFilters } = useFilterContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateFilters({ category: e.target.value as CarPlanCategories });
  };

  return (
    <div className="py-2">
      <div className="py-1">
        <span className="pe-1 fw-bold">Category</span>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>Category description</Popover.Body>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faCircleInfo} size="sm" />
        </OverlayTrigger>
      </div>

      <FormSelect name="category" onChange={handleChange} value={category}>
        {map(CarPlanCategories, (k,) => (
          <option key={k} value={k}>{tCarPlanCategory(k)}</option>
        ))}
      </FormSelect>
    </div >
  );
};

export { FilterCategory };
