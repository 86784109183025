import React from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { omit, forEach } from 'lodash';

import { StepperFormBodyProps, useStepperForm, useStepperFormBody } from '@components/Base';
import { routeHealthQuoteResultPage, HealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';
import { routePersonalAccidentQuoteResultPage } from '@components/PersonalAccidentQuoteResultPage';
import { routeCancerQuoteResultPage } from '@components/CancerQuoteResultPage';

import { MembersStepForm } from './members-step-form';
import { BirthDatesStepForm } from './birthdates-step-form';
import { EffectiveDateStepForm } from './effective-date-step-form';
import { UserStepForm } from './user-step-form';
import { FormValues } from './type';
import { useInitialValues, useSteps } from './hook';
import { userLeadCreate, UserLeadCreatePayload } from '@components/UserLead';

const FormBody = ({ steps }: StepperFormBodyProps) => {
  const { handleNext, handlePrev, isLastStep } = useStepperFormBody(steps);

  return (
    <Routes>
      <Route index element={<MembersStepForm onNext={handleNext} />} />
      <Route path="members" element={<MembersStepForm onNext={handleNext} />} />
      <Route path="birthdates" element={<BirthDatesStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route
        path="policy-effective-date"
        element={<EffectiveDateStepForm onPrev={handlePrev} onNext={handleNext} isLastStep={isLastStep} />}
      />
      <Route path="user" element={<UserStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const cleanupValues = ({ me, spouse, sons, daughters, activationDate }: FormValues) => {
  return {
    me: omit(me, 'checked'),
    spouse: spouse?.checked ? omit(spouse, 'checked') : undefined,
    sons,
    daughters,
    activationDate,
  } as HealthQuoteResultPageParams;
};

const convertToMessage = ({ me, spouse, sons, daughters }: HealthQuoteResultPageParams) => {
  let str = `[me, ${me?.gender}, ${me?.dob}]`;

  if (spouse) {
    str += ` [spouse, ${spouse.dob}]`;
  }

  if (sons) {
    forEach(sons, (son, idx) => {
      str += ` [son${idx + 1}, ${son.dob}]`;
    });
  }

  if (daughters) {
    forEach(daughters, (daughter, idx) => {
      str += ` [daughter${idx + 1}, ${daughter.dob}]`;
    });
  }

  return str;
};

const HealthQuoteForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const steps = useSteps();
  const { next, activeStepIdx, isLastStep } = useStepperForm(steps);
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (!isLastStep) {
      next(values);
      return;
    }

    const updatedValues = cleanupValues(values);
    const { mobileNumber, fullName } = values;
    let route;
    let quoteType;

    if (matchPath('/health-insurance-quote/*', pathname)) {
      route = routeHealthQuoteResultPage(updatedValues);
      quoteType = 'Health';
    } else if (matchPath('/personal-accident-insurance-quote/*', pathname)) {
      route = routePersonalAccidentQuoteResultPage(updatedValues);
      quoteType = 'Personal Accident';
    } else {
      route = routeCancerQuoteResultPage(updatedValues);
      quoteType = 'Cancer';
    }

    if (mobileNumber && fullName) {
      const payload: UserLeadCreatePayload = {
        mobileNumber,
        fullName,
        message: `${quoteType}: ${convertToMessage(updatedValues)}`,
      };
      dispatch(userLeadCreate(payload));
    }

    navigate(route);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={steps[activeStepIdx].validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormBody steps={steps} />
      </Form>
    </Formik>
  );
};

export { HealthQuoteForm };
