import { mergeInitialValues } from '@utils/formik';
import { useParseParams } from '@hooks/params';

import { formValuesParamsSchema, FormValues } from '../PersonalAccidentQuoteBuyForm';
import { usePersonalAccidentQuoteFetch } from '../hook';

export const usePersonalAccidentQuoteReview = () => {
  const personalAccidentQuote = usePersonalAccidentQuoteFetch();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);
  const values = mergeInitialValues(
    initialValues,
    {
      ...personalAccidentQuote?.people,
      activationDate: personalAccidentQuote?.activationDate,
      personalAccidentPlanId: personalAccidentQuote?.personalAccidentPlanId
    }
  );

  return { personalAccidentQuote, values };
};
