import { all, fork } from 'redux-saga/effects';
import { CarPolicyClaimSaga } from './CarPolicyClaim';
import { PersonalAccidentPolicyClaimSaga } from './PersonalAccidentPolicyClaim';
import { CancerPolicyClaimSaga } from './CancerPolicyClaim';

export default function* () {
  yield all([
    fork(CarPolicyClaimSaga),
    fork(PersonalAccidentPolicyClaimSaga),
    fork(CancerPolicyClaimSaga)
  ]);
}
