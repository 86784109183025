import React, { useMemo } from 'react';
import { map, times, startCase } from 'lodash';
import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { routeCarQuotePage } from '@components/CarQuotePage';

const ThirdPartyAndComprehensiveSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(8, (n) => ({
      label: t(`carInsurancePage.thirdPartyAndComprehensiveSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.thirdPartyAndComprehensiveSection.item${n + 1}.desc`),
      thirdParty: t(`carInsurancePage.thirdPartyAndComprehensiveSection.item${n + 1}.thirdParty`) == 'yes',
      comprehensive: t(`carInsurancePage.thirdPartyAndComprehensiveSection.item${n + 1}.comprehensive`) == 'yes',
    }));
  }, []);

  return (
    <section className="mt-5">
      <h3 className="text-center mb-3">{t('carInsurancePage.thirdPartyAndComprehensiveSection.title')}</h3>
      <Table responsive bordered className="bg-white">
        <thead>
          <tr>
            <th>#</th>
            <th className="w-25 text-center">{t('carInsurancePage.whichPlanSection.item1.label')}</th>
            <th className="w-25 text-center">{t('carInsurancePage.whichPlanSection.item2.label')}</th>
          </tr>
        </thead>
        <tbody>
          {map(items, ({ label, desc, thirdParty, comprehensive }) => (
            <tr key={label}>
              <td>
                <h5 className="text-nowrap">{label}</h5>
                <p>{desc}</p>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon
                  icon={thirdParty ? faCheck : faXmark}
                  size="lg"
                  className={thirdParty ? 'text-success' : 'text-danger'}
                />
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon
                  icon={comprehensive ? faCheck : faXmark}
                  size="lg"
                  className={comprehensive ? 'text-success' : 'text-danger'}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>
              <Button
                to={routeCarQuotePage()}
                variant="primary"
                className="fw-bold p-3 w-100 text-nowrap"
              >
                {startCase(t('common:getQuote'))}
              </Button>
            </td>
            <td>
              <Button
                to={routeCarQuotePage()}
                variant="primary"
                className="fw-bold p-3 w-100 text-nowrap"
              >
                {startCase(t('common:getQuote'))}
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </section>
  );
};

export { ThirdPartyAndComprehensiveSection };
